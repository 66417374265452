import { logEventWithKeys } from "../medusaEventsFunctions";
import { ANALYTICS_ATTRIBUTES } from "../medusaHelper";

const {
  BILLING_CYCLE,
  DOMAIN_PRICE_MONTHLY,
  DOMAIN_PLAN,
  EMAIL,
  FIRST_ORDER,
  FREE_TRIAL_DAYS,
  TOTAL_MAILBOX_COUNT,
  SITE_PRICE_MONTHLY,
  SITE_FIRST_BILLING_DISCOUNT,
  MAIL_FIRST_BILLING_DISCOUNT,
  SITE_PLAN,
  MAIL_PLAN,
  PAYMENT_AMOUNT,
  PAYMENT_FOR,
  TOTAL_MAIL_PRICE,
  TOTAL_NEO_DOMAIN_PRICE,
  TOTAL_SITE_PRICE,
  MAIL_PRICE_MONTHLY,
  BILLING_CYCLE_UPDATED,
  DOMAIN_FIRST_BILLING_DISCOUNT,
  SOURCE_HOOK,
} = ANALYTICS_ATTRIBUTES;

export const logSitePlanSelectedEvent = (eventName: string, payload = {}) => {
  const extraKeys = [
    BILLING_CYCLE,
    DOMAIN_PRICE_MONTHLY,
    DOMAIN_PLAN,
    MAIL_PRICE_MONTHLY,
    SITE_PRICE_MONTHLY,
    SITE_FIRST_BILLING_DISCOUNT,
    MAIL_FIRST_BILLING_DISCOUNT,
    SITE_PLAN,
    EMAIL,
    FIRST_ORDER,
    FREE_TRIAL_DAYS,
  ];
  return logEventWithKeys(eventName, extraKeys, payload);
};

export const logSiteBillingPageEvent = (eventName: string, payload = {}) => {
  const extraKeys = [
    BILLING_CYCLE,
    DOMAIN_PRICE_MONTHLY,
    DOMAIN_PLAN,
    DOMAIN_FIRST_BILLING_DISCOUNT,
    EMAIL,
    FIRST_ORDER,
    FREE_TRIAL_DAYS,
    MAIL_PLAN,
    SITE_PLAN,
    PAYMENT_AMOUNT,
    PAYMENT_FOR,
    MAIL_PRICE_MONTHLY,
    SITE_PRICE_MONTHLY,
    SITE_FIRST_BILLING_DISCOUNT,
    MAIL_FIRST_BILLING_DISCOUNT,
    TOTAL_MAILBOX_COUNT,
    TOTAL_MAIL_PRICE,
    TOTAL_SITE_PRICE,
    TOTAL_NEO_DOMAIN_PRICE,
    BILLING_CYCLE_UPDATED,
    SOURCE_HOOK,
  ];

  return logEventWithKeys(eventName, extraKeys, payload);
};

export const logSiteMailboxEvent = (eventName: string, payload = {}) => {
  const extraKeys = [
    BILLING_CYCLE,
    DOMAIN_PRICE_MONTHLY,
    DOMAIN_PLAN,
    EMAIL,
    FIRST_ORDER,
    FREE_TRIAL_DAYS,
    MAIL_PLAN,
    MAIL_PRICE_MONTHLY,
    SITE_PRICE_MONTHLY,
    SITE_FIRST_BILLING_DISCOUNT,
    MAIL_FIRST_BILLING_DISCOUNT,
    TOTAL_MAILBOX_COUNT,
    TOTAL_MAIL_PRICE,
    TOTAL_SITE_PRICE,
    TOTAL_NEO_DOMAIN_PRICE,
  ];

  return logEventWithKeys(eventName, extraKeys, payload);
};
