import React from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { appSelector } from "../../../store/selectors";
import { ANALYTICS_EVENTS, PAGE_PATHS } from "../../../helpers/constants";
import HaveDomain from "../index";
import { TDomainAvailabilityPayload, TRedirectState } from "../Common/types";
import EmailBenefits from "./EmailBenefits";
import {
  logCustomerEvent,
  logDomainEvent,
} from "../../../telemetry/medusaEventsFunctions";
import { TLogEventProps } from "../../../types/global";
import {
  resetMailMailboxState,
  selectMailDomain,
  setMailDomain,
} from "../../../store/slices/mail.slice";
import useIsLoggedIn from "../../../hooks/useIsLoggedIn";

const {
  CUSTOMER_ACCOUNT_LINKED,
  DOMAIN_AVAILABILITY_CHECKED,
  DOMAIN_SELECTED,
  HAS_DOMAIN_CHECKED,
} = ANALYTICS_EVENTS;

const MailHaveDomain: React.FC = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { search_params } = useSelector(appSelector);
  const { offering, customDomain } = useSelector(selectMailDomain);
  const isLoggedIn = useIsLoggedIn();
  const title = "Enter your domain name";
  const subTitle = "You need to own a domain name to set up email on it.";

  const domainOwnershipConfirmation = (domain: string): React.ReactNode => (
    <>
      I own <strong>{domain}</strong> domain, and can update its settings to set
      up email on it
    </>
  );

  const navigateToGetDomain = (state?: TRedirectState) => {
    const navigationOptions = {
      pathname: PAGE_PATHS.GET_DOMAIN,
      search: search_params,
    };
    if (state) {
      navigateTo(navigationOptions, { state });
    } else {
      navigateTo(navigationOptions);
    }
  };

  const onDomainAvailable = () => {
    if (isLoggedIn) {
      navigateTo({
        pathname: PAGE_PATHS.ADD_MAILBOX,
        search: search_params,
      });
      return;
    }
    navigateTo({
      pathname: PAGE_PATHS.SIGN_UP,
      search: search_params,
    });
  };

  const setCustomDomain = (domainForPurchase: string) => {
    dispatch(setMailDomain({ customDomain: domainForPurchase }));
  };

  const setCoSiteDomain = (domainForPurchase: string) => {
    dispatch(setMailDomain({ coSiteDomain: domainForPurchase }));
  };

  const onContinueOfDomainAvailability = (
    payload: TDomainAvailabilityPayload
  ) => {
    const { customDomain, offering } = payload;
    dispatch(setMailDomain({ customDomain, offering }));
    dispatch(resetMailMailboxState());
  };

  const logEvent = ({ eventName, data, flag }: TLogEventProps) => {
    switch (eventName) {
      case CUSTOMER_ACCOUNT_LINKED:
        logCustomerEvent(eventName, data, flag);
        break;

      case DOMAIN_AVAILABILITY_CHECKED:
      case DOMAIN_SELECTED:
        logDomainEvent(eventName, data);
        break;

      case HAS_DOMAIN_CHECKED:
        logDomainEvent(eventName, data);
        break;

      default:
        console.log("Invalid event name");
    }
  };

  return (
    <HaveDomain
      EmailBenefits={<EmailBenefits />}
      title={title}
      subTitle={subTitle}
      customDomain={customDomain}
      offering={offering}
      continueToNextIfDomainAvailable={location.state?.continueToNext}
      domainOwnershipConfirmation={domainOwnershipConfirmation}
      navigateToGetDomain={navigateToGetDomain}
      onDomainAvailable={onDomainAvailable}
      setCustomDomain={setCustomDomain}
      setCoSiteDomain={setCoSiteDomain}
      onContinueOfDomainAvailability={onContinueOfDomainAvailability}
      logEvent={logEvent}
      continueCTALabel={
        <>
          Continue
          <br />
          15 days free
        </>
      }
    />
  );
};

export default MailHaveDomain;
