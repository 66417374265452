import React, { useEffect } from "react";
import { logMedusaEvent } from "../../telemetry/medusaHelper";
import { ANALYTICS_EVENTS } from "../../helpers/constants";
import { ReactComponent as CountryNotSupportedIcon } from "../../assets/browser-window-with-warning.svg";
import styles from "./CountryNotSupported.module.scss";

type Props = {
  className?: string;
  ipCountry: string;
  subtext: string;
  billingCountry?: string;
};

const KB_ARTICLE_URL =
  "https://support.neo.space/hc/en-us/articles/14463849280281-Countries-or-regions-where-Neo-is-not-supported";

function CountryNotSupported({
  className = "",
  subtext,
  ipCountry,
  billingCountry,
}: Props) {
  useEffect(() => {
    logMedusaEvent(ANALYTICS_EVENTS.RESTRICTED_REGION_SHOWN, [], {
      ip_country: ipCountry,
      ...(billingCountry && { billing_country: billingCountry }),
    });
  }, []);

  const handleKnowMoreClick = () => {
    window.open(KB_ARTICLE_URL, "KBArticle");
  };

  return (
    <div className={`${className || ""} ${styles.countryNotSupported}`}>
      <CountryNotSupportedIcon />
      <div>
        <div className={styles.title}>Country not supported</div>

        <div>{subtext}</div>
        <span className={styles.knowMore} onClick={handleKnowMoreClick}>
          Know more
        </span>
      </div>
    </div>
  );
}

export default CountryNotSupported;
