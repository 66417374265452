import React from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { appSelector } from "../../../store/selectors";
import { ANALYTICS_EVENTS, PAGE_PATHS } from "../../../helpers/constants";
import HaveDomain from "../index";
import { TDomainAvailabilityPayload, TRedirectState } from "../Common/types";
import {
  logCustomerEvent,
  logDomainEvent,
} from "../../../telemetry/medusaEventsFunctions";
import { TLogEventProps } from "../../../types/global";
import {
  resetSiteMailboxState,
  selectSiteDomain,
  setSiteDomain,
} from "../../../store/slices/site.slice";
import useIsLoggedIn from "../../../hooks/useIsLoggedIn";
import {
  getSiteBuilderEventData,
  getVisitorProfileForMedusa,
} from "../../../telemetry/site/siteMedusaData";

const {
  CUSTOMER_ACCOUNT_LINKED,
  DOMAIN_AVAILABILITY_CHECKED,
  DOMAIN_SELECTED,
  HAS_DOMAIN_CHECKED,
} = ANALYTICS_EVENTS;

const SiteHaveDomain: React.FC = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { search_params } = useSelector(appSelector);
  const { offering, customDomain } = useSelector(selectSiteDomain);
  const isLoggedIn = useIsLoggedIn();
  const title = "Enter your domain name so we can connect your site";

  const domainOwnershipConfirmation = (domain: string): React.ReactNode => (
    <>
      I own <strong>{domain}</strong> domain, and can update its setting
    </>
  );

  const navigateToGetDomain = (state?: TRedirectState) => {
    const navigationOptions = {
      pathname: PAGE_PATHS.SITE_GET_DOMAIN,
      search: search_params,
    };
    if (state) {
      navigateTo(navigationOptions, { state });
    } else {
      navigateTo(navigationOptions);
    }
  };

  const onDomainAvailable = () => {
    if (isLoggedIn) {
      navigateTo({
        pathname: PAGE_PATHS.SITE_PLANS,
        search: search_params,
      });
      return;
    }
    navigateTo({
      pathname: PAGE_PATHS.SITE_SIGN_UP,
      search: search_params,
    });
  };

  const setCustomDomain = (domainForPurchase: string) => {
    dispatch(setSiteDomain({ customDomain: domainForPurchase }));
  };

  const setCoSiteDomain = (domainForPurchase: string) => {
    dispatch(setSiteDomain({ coSiteDomain: domainForPurchase }));
  };

  const onContinueOfDomainAvailability = (
    payload: TDomainAvailabilityPayload
  ) => {
    const { customDomain, offering } = payload;
    dispatch(setSiteDomain({ customDomain, offering }));
    dispatch(resetSiteMailboxState());
  };

  const logEvent = ({ eventName, data, flag }: TLogEventProps) => {
    switch (eventName) {
      case CUSTOMER_ACCOUNT_LINKED:
        logCustomerEvent(
          eventName,
          {
            ...data,
            ...getSiteBuilderEventData(),
            ...getVisitorProfileForMedusa(),
          },
          flag
        );
        break;

      case DOMAIN_AVAILABILITY_CHECKED:
      case DOMAIN_SELECTED:
      case HAS_DOMAIN_CHECKED:
        logDomainEvent(eventName, {
          ...data,
          ...getSiteBuilderEventData(),
          ...getVisitorProfileForMedusa(),
        });
        break;

      default:
        console.log("Invalid event name");
    }
  };

  return (
    <HaveDomain
      title={title}
      customDomain={customDomain}
      offering={offering}
      continueToNextIfDomainAvailable={location.state?.continueToNext}
      domainOwnershipConfirmation={domainOwnershipConfirmation}
      navigateToGetDomain={navigateToGetDomain}
      onDomainAvailable={onDomainAvailable}
      setCustomDomain={setCustomDomain}
      setCoSiteDomain={setCoSiteDomain}
      onContinueOfDomainAvailability={onContinueOfDomainAvailability}
      logEvent={logEvent}
      continueCTALabel="Continue"
    />
  );
};

export default SiteHaveDomain;
