import { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store/store";
import useIsLoggedIn from "../useIsLoggedIn";
import {
  emailAccountDiscountSelector,
  promoSelector,
} from "../../store/selectors/promoSelector";
import { getPromoCodeIdFromURL, getUrlParams } from "../../helpers/utils";
import { fetchPromoDetails } from "../../store/slices/promo.slice";
import {
  getInfluencerPromoCodeFromUrl,
  removeInfluencerPromoCodeFromUrl,
  removePromoFromUrl,
} from "../../helpers/promo.helper";
import { addQueryParamsInCurrentUrl } from "../../helpers/urlHelpers";

type Props =
  | {
      fetchIfNotPresent?: boolean;
    }
  | undefined;

const usePromo = ({ fetchIfNotPresent }: Props = {}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const isLoggedIn = useIsLoggedIn();
  const promo = useSelector(promoSelector);
  const emailAccountDiscount = useSelector(emailAccountDiscountSelector);
  const isInfluencerPromoCode = useMemo(() => !!getInfluencerPromoCodeFromUrl(), []);

  useEffect(() => {
    if (emailAccountDiscount) return;
    const promoCodeId = getPromoCodeIdFromURL();
    const promoCode = getInfluencerPromoCodeFromUrl();
    if (promoCode && fetchIfNotPresent) {
      fetchInfluencerPromoUsingPromoCode(promoCode);
      if(getUrlParams().utm_source === 'forneo') // Update in query param based on utm_source only
      addQueryParamsInCurrentUrl({ promo_code: promoCode });

    } else if (fetchIfNotPresent && isLoggedIn && promoCodeId) {
      fetchPromoUsingId(promoCodeId);
    }
  }, [isLoggedIn, fetchIfNotPresent, emailAccountDiscount]);

  const fetchPromoUsingId = async (promoCodeId: string) => {
    setIsLoading(true);
    try {
      await dispatch(fetchPromoDetails({ promoCodeId }));
    } catch (error) {
      removePromoFromUrl();
    }
    setIsLoading(false);
  };

  const fetchInfluencerPromoUsingPromoCode = async (promoCode: string) => {
    setIsLoading(true);
    try {
      await dispatch(fetchPromoDetails({ promoCode }));
    } catch (error) {
      removeInfluencerPromoCodeFromUrl();
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    promoCodeDetails: promo?.promoCodeDetails,
    emailAccountDiscount,
    promoCodeErrorDetails: promo?.promoCodeErrorDetails,
    isInfluencerPromoCode
  };
};

export default usePromo;
