import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import { bindActionCreators } from "redux";
import SetCJCookie from "../../3rdPartyIntegrations/CJCookie/CJCookie";
import VWOIntegration from "../../3rdPartyIntegrations/VWOIntegration/VWOIntegration";
import Loader from "../../components/Common/Loader/Loader";
import Layout from "../../components/Layout/Layout";
import {
  ANALYTICS_EVENTS,
  COOKIE_KEYS,
  PLAN_SETS,
  sourceHookMapping,
} from "../../helpers/constants";
import { isCoSite15DaysTrial } from "../../helpers/featureVWOExperimentsHelper";
import { onVWOLoaded } from "../../helpers/pageLoadedHelper";
import { formatAndSortMailPlans } from "../../helpers/mail/plans.helper";
import {
  cleanUrl,
  convertStringToBoolean,
  getAppParamsFromURL,
  getConfig,
  getUserParamsFromURL,
  getUTMParamsFromURL,
  isMobile,
} from "../../helpers/utils";
import { getCookie, setCookie } from "../../helpers/cookie.helper";
import withAutoLogin from "../../HOC/AutoLogin";
import usePromo from "../../hooks/mail/usePromo";
import { getAllSetPlans } from "../../services/apiFunctions";
import {
  appActions,
  configActions,
  domainActions,
  userActions,
} from "../../store/actions";
import { planInitialState } from "../../store/reducers/userReducer";
import { userSelector } from "../../store/selectors";
import { logCustomerEvent } from "../../telemetry/medusaEventsFunctions";
import "./PurchaseFlow.scss";
import "./Global.scss";
import {
  getFeaturePropsAction,
  resetMailDomainState,
  setMailDomain,
  setMailDomainAttrs,
  setMailPlans,
} from "../../store/slices/mail.slice";
import { useProduct } from "../../hooks/useProduct";
import ExitIntent from "../../components/ExitIntent";
import { updateUTMParamsInCookie } from "../../helpers/utm.helper";
import { AppDispatch } from "../../store/store";
import { getPartnerId } from "../../helpers/partner.helper";
import { TPlanFromAPIResponse } from "../../types/plan.type";
import { formatAndSortSitePlans } from "../../helpers/site/plans.helper";
import {
  setSiteDomainAttrs,
  setSitePlans,
} from "../../store/slices/site.slice";
import { ORDER_TYPES, PRODUCT_TYPES } from "../../constants/products.constants";
import { getCommonSiteProductData } from "../../telemetry/site/siteMedusaData";
import { setNeoDomainPlans } from "../../store/slices/neo-domain-plans";
import { fetchUserIPDetails } from "../../store/slices/ipDetails.slice";

// Keys To be cleared from search params if redirected to signup app from Landing page or NAP
const keysToBeCleared = [
  "clearDomain",
  "name",
  "auth_token",
  "token_expiry",
  "alternateEmail",
  "hasActiveOrders",
  "customer_id",
  "planName",
  "neo_offering",
  "isCustomerAccount",
];

const { CUSTOMER_ACCOUNT_SIGNED_IN, CUSTOMER_ACCOUNT_LINKED } =
  ANALYTICS_EVENTS;
const { CUSTOMER_ID_COOKIE } = COOKIE_KEYS;

function PurchaseFlow() {
  const dispatch = useDispatch<AppDispatch>();
  const { updateUser } = bindActionCreators(userActions, useDispatch());
  const { fetchDomainPricing } = bindActionCreators(
    domainActions,
    useDispatch()
  );
  const { updateAppParams } = bindActionCreators(appActions, useDispatch());
  const { updateConfig } = bindActionCreators(configActions, useDispatch());

  const { isLoading: isPromoLoading } = usePromo({ fetchIfNotPresent: true });
  const navigateTo = useNavigate();
  const { token, customer_id } = useSelector(userSelector);
  const [loading, setLoading] = useState(true);
  const productHandler = useProduct();

  useEffect(() => {
    const { utm_term, utm_medium, utm_campaign, utm_source, utm_content } =
      getUTMParamsFromURL();
    updateUTMParamsInCookie({
      utm_campaign,
      utm_content,
      utm_medium,
      utm_source,
      utm_term,
    });
  }, []);

  useEffect(() => {
    dispatch(setSiteDomainAttrs({ isCoSiteTrial: true }));
    setParamsInStore();
    onVWOLoaded().then(async () => {
      const { isCSTrial, isAllLitePlan } = await isCoSite15DaysTrial();
      // TODO: for site
      dispatch(setMailDomainAttrs({ isCoSiteTrial: isCSTrial }));
      updateUser({ haveLitePlanSupport: isAllLitePlan });
    });
  }, []);

  useEffect(() => {
    getUserIPDetails();
    getPlansDetails();
    getDomainPricing();
  }, [customer_id]);

  const getUserIPDetails = async () => {
    await dispatch(fetchUserIPDetails());
  };

  const getPlansDetails = async () => {
    try {
      setLoading(true);
      const set = PLAN_SETS.CHANGE_BILLING_CYCLE_DISCOUNT;
      const planFeaturePropsPromise = dispatch(getFeaturePropsAction()); // making req first then awaiting for response
      const response = await getAllSetPlans({
        partnerId: getPartnerId(),
        set,
        userId: customer_id,
        orderTypes: [
          ORDER_TYPES.SITE,
          ORDER_TYPES.NEO_DOMAIN,
          ORDER_TYPES.MAIL_SUITE,
        ],
        // orderTypes: [ORDER_TYPES.SITE],
      });
      const featureProps = await (planFeaturePropsPromise as any).unwrap();
      const mailPlans = formatAndSortMailPlans(
        response.data.plans.filter(
          (plan: any) => plan.orderType === ORDER_TYPES.MAIL_SUITE
        ),
        featureProps
      );
      const sitePlans = formatAndSortSitePlans(
        response.data.plans.filter(
          (plan: any) => plan.orderType === ORDER_TYPES.SITE
        )
      );

      const neoDomainPlans = response.data.plans.filter(
        (plan: any) => plan.orderType === ORDER_TYPES.NEO_DOMAIN
      );
      dispatch(setMailPlans(mailPlans));
      dispatch(setSitePlans(sitePlans));
      dispatch(setNeoDomainPlans(neoDomainPlans));
      setCurrencyInStore(mailPlans);
    } catch (error) {
      console.error("Error fetching plans:", error);
    } finally {
      setLoading(false);
    }
  };

  const getDomainPricing = () => {
    fetchDomainPricing();
  };

  const setCurrencyInStore = (plans: TPlanFromAPIResponse[]) => {
    const currency = plans[0]?.currency;
    if (currency) updateUser({ currency });
  };

  const setParamsInStore = () => {
    const {
      clearDomain,
      name,
      auth_token,
      token_expiry,
      alternateEmail,
      hasActiveOrders,
      customer_id: customerIdFromParams,
      source_hook,
      isCustomerAccount,
    } = getUserParamsFromURL();

    if (
      sourceHookMapping[source_hook] === sourceHookMapping["neoAdminPanel"] &&
      clearDomain &&
      name &&
      auth_token &&
      token_expiry &&
      alternateEmail &&
      hasActiveOrders &&
      customerIdFromParams &&
      source_hook
    ) {
      // handle redirection from NAP here
      updateUser({
        name,
        token: auth_token,
        alternateEmail,
        tokenExpiry: token_expiry,
        hasActiveOrders: Boolean(hasActiveOrders),
        firstOrder: false,
        customer_id: parseInt(customerIdFromParams),
        source_hook: sourceHookMapping[source_hook],
        plan: planInitialState,
        persona: {},
      });

      // TODO: for site
      dispatch(setMailDomain({ coSiteDomain: "", customDomain: "" }));

      // this wrapper is needed only as long as we have the VWO integration being used in the app.
      // this is done to delay logging of this Event so that we have VWO data before logging
      onVWOLoaded().then(() => {
        // CUSTOMER ACCOUNT SIGNED IN event fired when logged in from Neo Admin Panel
        logCustomerEvent(
          CUSTOMER_ACCOUNT_SIGNED_IN,
          {
            source_hook: sourceHookMapping[source_hook],
            ...getCommonSiteProductData(), // TODO: Temporary solution – refactor to properly sort and structure event data by product type.
          },
          true
        );
      });
    } else if (clearDomain) {
      // handle redirection from Landing Page here
      updateUser({
        source_hook: sourceHookMapping[source_hook],
        plan: planInitialState,
        persona: {},
      });

      // TODO: for site
      dispatch(resetMailDomainState());
    }

    updateUser({ source_hook: sourceHookMapping[source_hook] || source_hook });

    const appParams = getAppParamsFromURL();
    updateConfig(getConfig());
    /**
     * Clean url will remove the given keys from url
     * Before cleaning url, user data already added to store
     */
    const updatedSearchParam = cleanUrl(keysToBeCleared);
    // update search_params in store
    updateAppParams({ ...appParams, search_params: updatedSearchParam });

    // The reason behind doing CUSTOMER ACCOUNT LINKED event here is because
    // above we are updating the store with token if user is signed in
    if (token) {
      onVWOLoaded().then(() => {
        logCustomerEvent(
          CUSTOMER_ACCOUNT_LINKED,
          {
            user_type: "Customer",
            customer_account_linked_via: "Already signed in",
            dummy_entity_Id: true,
            ...getCommonSiteProductData(), // TODO: Temporary solution – refactor to properly sort and structure event data by product type.
          },
          true
        );
      });
      if (!getCookie(CUSTOMER_ID_COOKIE)) {
        setCookie(CUSTOMER_ID_COOKIE, parseInt(customer_id));
      }
    }
    const isCustomer =
      isCustomerAccount && convertStringToBoolean(isCustomerAccount);
    if (
      !isCustomer &&
      sourceHookMapping[source_hook] === sourceHookMapping["neoAdminPanel"] &&
      productHandler.productType === PRODUCT_TYPES.MAIL
    ) {
      navigateTo({
        pathname: productHandler.getStartedPath,
        search: updatedSearchParam,
      });
    }
  };

  return (
    <>
      <VWOIntegration />
      <SetCJCookie />
      {loading || isPromoLoading ? <Loader /> : <Layout />}
      {!isMobile() && !loading && <ExitIntent />}
    </>
  );
}

export default withAutoLogin(PurchaseFlow);
