import React from "react";
import iosAppStore from "../../assets/appstore.png";
import playStore from "../../assets/playstore.png";
import { APP_STORE_CONSTANTS } from "../../helpers/constants";
import styles from "./MobileStoreIcons.module.scss";

const { ANDROID, IOS } = APP_STORE_CONSTANTS;

function MobileStoreIconsRenderer({ email, account_id, token, onLinkClick }) {
  const getPlaystoreUrl = () => {
    const playstoreParams = encodeURIComponent(
      `payload={${account_id}:{"email":"${email}","sessionToken":"${token}"}}&utm={source:"mobile_website"}`
    );
    return `${ANDROID.URL}${playstoreParams}`;
  };

  const handleStoreIconClick = (storeName) => {
    onLinkClick(storeName).finally(() => {
      switch (storeName) {
        case ANDROID.NAME:
          window.location.href = getPlaystoreUrl();
          break;

        case IOS.NAME:
          window.location.href = IOS.URL;
          break;

        default:
          return null;
      }
    });
  };

  return (
    <>
      <p>
        <strong className={styles.strong}>
          Access your Neo Inbox on the go
        </strong>{" "}
        by downloading the Neo iOS or Android app
      </p>
      <div className={styles.buttonsWrapper}>
        <img
          src={iosAppStore}
          onClick={() => handleStoreIconClick(IOS.NAME)}
          alt={IOS.NAME}
        />
        <img
          src={playStore}
          onClick={() => handleStoreIconClick(ANDROID.NAME)}
          alt={ANDROID.NAME}
        />
      </div>
    </>
  );
}

export default MobileStoreIconsRenderer;
