import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import AddMailbox from "../index";
import { appSelector } from "../../../store/selectors";
import styles from "../styles.module.scss";
import { TOnOkButtonClickArgs } from "../Common/types";
import {
  ANALYTICS_EVENTS,
  NEO_E5_DOMAINS,
  OFFERING,
  PAGE_PATHS,
} from "../../../helpers/constants";
import { TLogEventProps } from "../../../types/global";
import { selectMailPlanToUpSell } from "../../../store/slices/mail.slice";
import {
  resetSiteMailboxState,
  selectSiteAdminMailbox,
  selectSitePlan,
  selectSitePurchaseDomain,
  setSiteAdminMailbox,
  setSiteDomain,
} from "../../../store/slices/site.slice";
import { logSiteMailboxEvent } from "../../../telemetry/site/medusaEventsFunctions";
import Footer from "./Footer";
import EmailBenefits from "./EmailBenefits";

const { MAILBOX_ADD_VIEWED, MAILBOX_ADDED, MAILBOX_ADD_FAILED } =
  ANALYTICS_EVENTS;
const { STAGING, PREPROD, PROD } = NEO_E5_DOMAINS;

const SiteAddMailbox: React.FC = () => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const domain = useSelector(selectSitePurchaseDomain);
  const { duration } = useSelector(selectSitePlan);
  const adminMailbox = useSelector(selectSiteAdminMailbox);
  const mailPlan = useSelector(selectMailPlanToUpSell);
  const { search_params } = useSelector(appSelector);
  const title = (
    <>
      Add professional email{" "}
      <span className={styles.greenDomainName}>@{domain}</span>
    </>
  );
  const subTitle =
    "Try free for 15 days - Build your brand with every email you send";

  const onOkButtonClick = async ({
    mailboxCreds,
    email,
  }: TOnOkButtonClickArgs) => {
    dispatch(setSiteAdminMailbox({ ...mailboxCreds, email }));

    await redirectUserBasedOnCampaign();
  };

  const redirectUserBasedOnCampaign = async () => {
    navigateTo({
      pathname: PAGE_PATHS.SITE_BILLING,
      search: search_params,
    });
  };

  const onCancelButtonClick = () => {
    dispatch(resetSiteMailboxState());
    navigateTo({
      pathname: PAGE_PATHS.SITE_BILLING,
      search: search_params,
    });
  };

  const logEvent = ({ eventName, data = {} }: TLogEventProps) => {
    switch (eventName) {
      case MAILBOX_ADD_VIEWED:
      case MAILBOX_ADDED:
      case MAILBOX_ADD_FAILED:
        logSiteMailboxEvent(eventName, data);
        break;

      default:
        console.log("Invalid event name");
    }
  };

  const setOffering = () => {
    if (
      !domain?.includes(PROD) &&
      !domain?.includes(STAGING) &&
      !domain?.includes(PREPROD)
    ) {
      dispatch(setSiteDomain({ offering: OFFERING.CUSTOM_DOMAIN }));
    } else {
      dispatch(setSiteDomain({ offering: OFFERING.COSITE }));
    }
  };

  return (
    <AddMailbox
      domain={domain}
      adminMailbox={adminMailbox}
      title={title}
      subTitle={subTitle}
      okButtonProps={{
        label: "Create mailbox",
        // sublabel: "15 days FREE",
        handler: onOkButtonClick,
      }}
      cancelButtonProps={{
        label: "Skip for now",
        handler: onCancelButtonClick,
      }}
      EmailBenefits={<EmailBenefits />}
      logEvent={logEvent}
      setOffering={setOffering}
      footer={<Footer mailPlan={mailPlan} duration={duration} />}
      mailboxInputLabel="Email address"
      mailboxPasswordLabel="Set password for this email"
    />
  );
};

export default SiteAddMailbox;
