import { createSelector } from "reselect";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TPlan } from "../../types/plan.type";
import { RootState } from "../../types/store.type";
import { isPaidPlan, isTrialPlan } from "../../helpers/plans.helper";
import { LOGOUT } from "../actions/actionsConstants";

type Plans = TPlan[];

const initialState: Plans = [];

const neoDomainPlansSlice = createSlice({
  name: "visitor",
  initialState,
  reducers: {
    setNeoDomainPlans: (_, action: PayloadAction<TPlan[]>) => {
      return action.payload;
    },
    resetNeoDomainPlans: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LOGOUT, () => {
      // Reset neo domain plan state to initial state on logout
      return initialState;
    });
  },
});

export const selectNeoDomainPlans = (state: RootState) => state.neoDomainPlans;

export const selectNeoDomainTrialPlan = createSelector(
  selectNeoDomainPlans,
  (plans) => {
    return plans.find(isTrialPlan) || plans[0];
  }
);

export const selectNeoDomainPaidPlan = createSelector(
  selectNeoDomainPlans,
  (plans) => plans.find(isPaidPlan) || plans[0]
);

export const { setNeoDomainPlans } = neoDomainPlansSlice.actions;

export default neoDomainPlansSlice.reducer;
