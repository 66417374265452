import { createAsyncThunk } from "@reduxjs/toolkit";
import { getWebflowUserDetails } from "../../services/apiFunctions";
import { STATUS_CODES } from "../../constants/api.constants";
import { userActions } from "../../store/actions";

export const fetchWebflowUser = createAsyncThunk<void, string>(
  "user/fetchWebflowUser",
  async (code: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await getWebflowUserDetails({ code });
      if (response?.status === STATUS_CODES.OK && response.data) {
        const { firstName, lastName, email } = response.data;

        dispatch(
          userActions.updateUser({
            name: `${firstName} ${lastName}`,
            alternateEmail: email,
          })
        );
        return;
      }
      return rejectWithValue("Failed to get user details from Webflow");
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
