export const mailFeatureGroups = {
  platforms_and_apps: "Platform & Apps",
  products_included: "Powerful Business Suite ✨",
  power_features: "Advanced Mail Features",
  mailbox_and_limits: "Mailbox & Limits",
  privacy_and_security: "Privacy & Security",
  others: "Others",
} as const;

export const mailFeaturesTextMap: Record<
  string,
  { text: string; tooltip?: string }
> = {
  storage: {
    text: "Mailbox Storage",
    tooltip: "Storage capacity per mailbox",
  },
  custom_domain: {
    text: "Custom Domain Email",
    tooltip: "A domain you already own",
  },
  neo_domain: {
    text: "domain",
    tooltip: "Unique branded name that identifies your website",
  },
  neo_site: {
    text: "AI-powered site builder",
    tooltip:
      "Single page website template with your branding, social, contact and more",
  },
  web_mail: {
    text: "Rich Webmail",
    tooltip: "Neo on desktop web",
  },
  mobile_apps: {
    text: "Android & iOS Apps",
    tooltip: "Neo mobile apps to access your email from anywhere",
  },
  calendar: {
    text: "Inbuilt Calendar",
  },
  imap_pop: {
    text: "Third-party mail app (POP/IMAP)",
    tooltip: "Check your Neo email on other mail apps",
  },
  appointment_booking: {
    text: "Appointment Booking",
    tooltip:
      "Create a unique meeting link and enable clients, customers, and team members to find time on your calendar and seamlessly schedule meetings with you.",
  },
  signature_builder: {
    text: "Signature Designer",
    tooltip: "Create your signature with logo, links, and HTML code",
  },
  email_designer: {
    text: "Email Designer",
    tooltip: "Create beautiful emails easily with ready-to-use templates",
  },
  email_marketing: {
    text: "Campaign Mode",
    tooltip:
      "Send good-looking personalised emails to multiple recipients at once, and keep the recipient list to yourself. You can also track who opened your email.",
  },
  priority_inbox: {
    text: "Priority Inbox",
    tooltip: "See only emails from senders you mark as Priority",
  },
  read_receipts: {
    text: "Read Receipts",
    tooltip: "Know when someone opens an email you sent",
  },
  titan_ai: {
    text: "AI Email Writer",
    tooltip:
      "Get ready-to-use email drafts in seconds for any requirement, powered by Neo AI. All your cold emails, pitches, negotiations, and responses to enquiries are now taken care of!",
  },
  email_templates: {
    text: "Email Templates",
  },
  schedule_send: {
    text: "Send Later",
    tooltip: "Schedule your emails to be sent later",
  },
  undo_send: {
    text: "Undo Send",
    tooltip: "Undo sending your email to correct an error or add a file",
  },
  email_reminders: {
    text: "Follow-up Reminders",
    tooltip: "Get a reminder to follow-up on important emails",
  },
  block_senders: {
    text: "Block Senders",
    tooltip: "Block senders so their emails don't appear in your mailbox",
  },
  allow_senders: {
    text: "Allow Senders",
    tooltip: "Whitelist senders so they are never blocked",
  },
  custom_html: {
    text: "HTML in Composer",
    tooltip: "Use custom HTML code to create more visually engaging emails",
  },
  turbo_search: {
    text: "Turbo Search",
  },
  multi_device_support: {
    text: "Multi-account Support",
    tooltip: "Sign in to multiple Neo email accounts",
  },
  gmail_sync: {
    text: "Add Gmail account",
    tooltip: "Add a Gmail account to get new emails",
  },
  catch_all: {
    text: "Catch-all account",
    tooltip:
      "Mailbox to get emails sent to invalid email addresses in your domain",
  },
  email_alias: {
    text: "Email aliases",
    tooltip:
      "Alias of your email address to segregate emails like sales or support. It simply forwards the received email ",
  },
  send_as: {
    text: "Send email from Alias",
    tooltip:
      "Send emails using your alias identity to keep your primary email address hidden",
  },
  multiple_external_forwarder: {
    text: "Multiple external forwarders",
    tooltip: "Forward incoming emails to non-Neo email address",
  },
  mail_send: {
    text: "Mail sending Limits",
  },
  advance_antivirus: {
    text: "Anti-virus protection inbound / outbound",
    tooltip:
      "Enhanced protection of sent and received emails with viruses, malware, phishing attacks, and more checks",
  },
  advanced_anti_spam: {
    text: "Advanced Anti-spam",
    tooltip: "Measures to keep unwanted spam out of your inbox",
  },
  two_factor_auth: {
    text: "Two-Factor Authentication",
    tooltip:
      "Additional layer of security for your email account using Google Authenticator and others",
  },
  custom_dkim: {
    text: "Custom DKIM",
    tooltip:
      "Improve email sent delivery by creating a digital signature for your domain that informs your recipient that you are a legitimate and verified sender",
  },
  unlimited_mail_filters: {
    text: "Email Rules",
    tooltip:
      "Organize incoming emails automatically by creating rules to move them to folders and more",
  },
  rules_forwarding: {
    text: "Conditional Forwarding of Emails",
  },
  vacation_responder: {
    text: "Auto-reply",
    tooltip:
      "While away, automatically notify people that you won't be able to get back to them right away",
  },
  contact_groups: {
    text: "Contact Groups",
    tooltip:
      "Create a group of your frequently emailed recipients to reach out to them simultaneously",
  },
  calendar_sharing: {
    text: "Shareable Calendar",
    tooltip: "Share your Calendar with your team and external contacts",
  },
  import_email_contacts: {
    text: "One-click import of existing emails and contacts",
    tooltip: "Import your old emails and contacts to Neo",
  },
  mail_filter: {
    text: "Email Filter",
  },
  grammar_spell_check: {
    text: "Grammar and Spell Check",
    tooltip: "Catch spelling and grammar mistakes in real-time",
  },
  advanced_tracking: {
    text: "Advanced Tracking",
    tooltip:
      "Know when your emails are opened, links are clicked and files are downloaded",
  },
  email_labels: {
    text: "Email Labels",
  },
};
