import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { getCountOfTotalMailboxAdded } from "../../../helpers/emailHelpers";
import { countOfEachTypeMailbox } from "../../../helpers/utils";
import { userActions } from "../../../store/actions";
import usePromo from "../../../hooks/mail/usePromo";
import {
  logBillingPageEvent,
  logMailboxEvent,
} from "../../../telemetry/medusaEventsFunctions";
import {
  ANALYTICS_EVENTS,
  BILLING_CYCLE_DISPLAY_LABELS,
  EVENT_MAILBOX_TYPE,
  SOURCE_OF_MAILBOX_ADDITION,
  TRIAL_DURATIONS_IN_DAYS,
} from "../../../helpers/constants";
import { TBillingCycle } from "../../../types/billing-cycle";
import {
  isMailDomainCustom,
  selectMailAdditionalMailbox,
  selectMailAdminMailbox,
  selectMailDomainAttrs,
  selectMailDomainPricing,
  selectMailPlan,
  selectMailPlanDetails,
  selectMailPurchaseDomain,
  setMailAdditionalMailboxes,
  setMailPlan,
} from "../../../store/slices/mail.slice";
import { getNextDiscountedBillingCycle } from "../../../helpers/billing-cycle.helper";
import SummaryHeader from "../shared/SummaryHeader";
import BillingCycleDropdown from "../shared/BillingCycleDropdown";
import PromoSummary from "../shared/PromoSummary";
import DomainSummary from "../shared/DomainSummary";
import PostTrialAmountSummary from "../shared/PostTrialAmountSummary";
import OnePageSiteSummary from "../shared/OnePageSiteSummary";
import AmountPayableSummary from "../shared/AmountPayableSummary";
import PromoError from "../../Promo/PromoError";
import SummaryFooter from "../shared/SummaryFooter";
import { PLAN_TYPES } from "../../../constants/mail.constant";
import CancelOrPauseNote from "../shared/CancelOrPauseNote";
import { logMedusaEvent } from "../../../telemetry/medusaHelper";
import { calculateCharge } from "../../../helpers/mail/calculate-charge.helper";
import { TPlan } from "../../../types/plan.type";
import MailboxesSummary from "./MailboxesSummary";
import styles from "./style.module.scss";


const { ORDER_SUMMARY_REVIEWED, MAILBOX_DELETED } = ANALYTICS_EVENTS;
const { SUGGESTION_MAILBOX } = SOURCE_OF_MAILBOX_ADDITION;

type Props = {
  isVisible: boolean;
  onContinue: () => void;
};

export default function MailOrderSummary({ onContinue }: Props) {
  const dispatch = useDispatch();
  const domainPricing = useSelector(selectMailDomainPricing);
  const isCustomDomain = useSelector(isMailDomainCustom);
  const { duration, supportedBillingCycles } = useSelector(selectMailPlan);
  const plan = useSelector(selectMailPlanDetails) as TPlan;
  const { isCoSiteTrial } = useSelector(selectMailDomainAttrs);
  const domain = useSelector(selectMailPurchaseDomain);
  const additionalMailboxes = useSelector(selectMailAdditionalMailbox);
  const countOfMailboxAdded = getCountOfTotalMailboxAdded(additionalMailboxes);
  const [billingCycleUpdated, setBillingCycleUpdated] = useState(false);
  const { teamMailboxCount, suggestionMailboxCount, customMailboxCount } =
    countOfEachTypeMailbox(additionalMailboxes);
  const { updateUser } = bindActionCreators(userActions, useDispatch());
  const { emailAccountDiscount, promoCodeDetails } = usePromo();
  const adminMailbox = useSelector(selectMailAdminMailbox);

  const {
    totalChargeableAmount,
    totalChargeableAmountBeforeDiscount,
    amountSaved,
    payableNow,
    mailboxChargeBeforeDiscount,
    mailboxDiscount,
    perMailBoxCharge,
    domainCharges,
  } = useMemo(() => {
    return calculateCharge({
      plan,
      countOfMailboxes: countOfMailboxAdded,
      isCustomDomain,
      isCoSiteTrial,
      domainPricing,
      billingCycle: duration,
      emailDiscount: emailAccountDiscount,
    });
  }, [
    plan,
    countOfMailboxAdded,
    isCustomDomain,
    isCoSiteTrial,
    domainPricing,
    duration,
    emailAccountDiscount,
  ]);

  const handleDeleteMailbox = (mailboxToDelete: any, source_hook?: string) => {
    const updatedMailboxList = additionalMailboxes.filter(
      (item) => item.email !== mailboxToDelete.email
    );

    dispatch(setMailAdditionalMailboxes([...updatedMailboxList]));

    logMailboxEvent(
      MAILBOX_DELETED,
      {
        email: mailboxToDelete.email,
        mailbox_type:
          mailboxToDelete.source === SUGGESTION_MAILBOX
            ? EVENT_MAILBOX_TYPE.SYSTEM_SUGGESTED
            : EVENT_MAILBOX_TYPE.CUSTOM,
        source_hook: source_hook || "Order summary page",
      },
      true
    );
  };

  const handleSubmit = () => {
    onContinue();
    // TODO: hack to update directly in user store
    updateUser({
      payment_amount: payableNow,
      promo_discount_amount: mailboxDiscount,
      payment_for: "Mail",
      total_mail_price: totalChargeableAmount,
      total_site_price: 0,
      total_neo_domain_price: domainCharges,
      billing_cycle_updated: billingCycleUpdated,
    });
    logBillingPageEvent(ORDER_SUMMARY_REVIEWED, {
      billing_cycle_updated: billingCycleUpdated,
      payment_amount: payableNow,
      promo_discount_amount: mailboxDiscount,
      total_mailbox_count: countOfMailboxAdded,
      generic_mailbox_count: suggestionMailboxCount + customMailboxCount,
      name_mailbox_count: teamMailboxCount,
      suggested_mailbox_count: suggestionMailboxCount,
    });
  };

  const handleSelectChange = (newDuration: TBillingCycle) => {
    setBillingCycleUpdated(true);
    dispatch(setMailPlan({ duration: newDuration }));
    logMedusaEvent(ANALYTICS_EVENTS.PLAN_BILLING_CYCLE_UPDATED, [], {
      billing_cycle_selected: BILLING_CYCLE_DISPLAY_LABELS[newDuration],
    })
  };

  const getNextSavingBillingCycle = () => {
    if (plan.neoPlanType === PLAN_TYPES.LITE) return null;
    return getNextDiscountedBillingCycle(supportedBillingCycles, duration);
  };

  const onBillingCycleDropdownOpen = () => {
    logMedusaEvent(ANALYTICS_EVENTS.BILLING_CYCLE_DROPDOWN_CLICKED, [], {});
  }

  const options = supportedBillingCycles.map((cycle: TBillingCycle) => ({
    value: cycle,
    pricePerMonth: plan.pricing[cycle]?.firstBillingCyclePrice || 0,
    discountPercentage: plan.pricing[cycle]?.discountPercentage || 0,
  }));

  const defaultOption =
    options.find((option: any) => option.value === duration) || options[0];

  return (
    <div>
      <SummaryHeader
        getNextSavingBillingCycle={getNextSavingBillingCycle}
        BillingCycleDropdown={() => (
          <BillingCycleDropdown
            options={options}
            defaultOption={defaultOption}
            onChange={(e) => e && handleSelectChange(e.value)}
            priceOf="/mo"
            onDropDownOpen={onBillingCycleDropdownOpen}
          />
        )}
      />

      <MailboxesSummary
        totalPayable={mailboxChargeBeforeDiscount}
        perMailboxCharge={perMailBoxCharge}
        plan={plan}
        domain={domain}
        isCoSiteTrial={isCoSiteTrial}
        isCustomDomain={isCustomDomain}
        additionalMailboxes={additionalMailboxes}
        adminMailbox={adminMailbox}
        handleDeleteMailbox={handleDeleteMailbox}
        duration={duration}
      />
      <PromoSummary
        discountAmount={mailboxDiscount}
        promoCodeDetails={promoCodeDetails}
        emailAccountDiscount={emailAccountDiscount}
      />
      {!isCustomDomain && (
        <DomainSummary
          duration={duration}
          domain={domain}
          domainPricing={domainPricing}
          isCoSiteTrial={isCoSiteTrial}
          domainCharges={domainCharges}
        />
      )}
      <OnePageSiteSummary isCustomDomain={isCustomDomain} domain={domain} />
      {isCustomDomain || isCoSiteTrial ? (
        <PostTrialAmountSummary
          finalAmount={totalChargeableAmount}
          discountAmount={mailboxDiscount}
          finalAmountWithoutDiscount={totalChargeableAmountBeforeDiscount}
          trialDuration={
            isCustomDomain
              ? TRIAL_DURATIONS_IN_DAYS.CUSTOM_DOMAIN
              : TRIAL_DURATIONS_IN_DAYS.CO_SITE
          }
        />
      ) : null}
      <AmountPayableSummary payableNow={payableNow} amountSaved={amountSaved} />
      <PromoError className={styles.promoError} />
      <SummaryFooter handleSubmit={handleSubmit} />
      <CancelOrPauseNote />
    </div>
  );
}
