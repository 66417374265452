import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { bindActionCreators } from "redux";
import { cleanUrl, getUrlParams } from "../../helpers/utils";
import { PAGE_PATHS } from "../../helpers/constants";
import AppLoaderIcon from "../../assets/loader-blue.svg";
import { updateUTMParamsInCookie } from "../../helpers/utm.helper";
import { fetchWebflowUser } from "../../store/slices/webflow.slice";
import { AppDispatch } from "../../store/store";
import { appActions } from "../../store/actions";
import styles from "./WebflowIntegration.module.scss";

const WebflowIntegration = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigateTo = useNavigate();
  const { updateAppParams } = bindActionCreators(appActions, dispatch);

  const [showManualRedirection, setShowManualRedirection] = useState(false);

  const cleanURLAndRedirectToGetStarted = () => {
    const updatedSearchParams = cleanUrl(["code", "error"]);
    updateAppParams({ search_params: updatedSearchParams });
    navigateTo({ pathname: PAGE_PATHS.GET_STARTED });
  };

  const updateUTMParams = () => {
    updateUTMParamsInCookie({
      utm_source: "webflow",
      utm_campaign: "marketplace",
    });
  };

  useEffect(() => {
    updateUTMParams();

    // Grab any query params
    const { code } = getUrlParams();

    // Show manual redirection link after 5s
    const timerId = setTimeout(() => {
      setShowManualRedirection(true);
    }, 5000);

    if (code) {
      (async () => {
        try {
          await dispatch(fetchWebflowUser(code)); // Fetch Webflow user details
        } catch (error: any) {
          console.error("Error fetching Webflow user details:", error);
        } finally {
          // Always redirect once API call finishes (success or error)
          cleanURLAndRedirectToGetStarted();
        }
      })();
    } else {
      // No code param, just redirect
      cleanURLAndRedirectToGetStarted();
    }

    // Cleanup on unmount
    return () => clearTimeout(timerId);
  }, [dispatch, navigateTo]);

  return (
    <div className={styles.contentWrapper}>
      <img className="loader" src={AppLoaderIcon} alt="app-loader" />
      <div className={styles.heading}>You will be redirected shortly...</div>
      {showManualRedirection && (
        <div className={styles.subheading}>
          Not redirected?{" "}
          <span onClick={cleanURLAndRedirectToGetStarted}>Click here</span>
        </div>
      )}
    </div>
  );
};

export default WebflowIntegration;
