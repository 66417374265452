import React from "react";
import Popup from "../Common/Popup/Popup";
import CountryNotSupported from "../CountryNotSupported";
import Button from "../Common/Button/Button";
import styles from "./RestrictedRegionModal.module.scss";

type Props = {
  open: boolean;
  ipCountry: string;
  onClose: () => void;
  billingCountry: string;
};

const RestrictedRegionModal = ({
  open,
  onClose,
  ipCountry,
  billingCountry,
}: Props) => {
  const subtext = "Unable to process new orders from your country.";

  if (!open) return null;

  return (
    <Popup>
      <div className={styles.restrictedRegionModal}>
        <CountryNotSupported
          subtext={subtext}
          ipCountry={ipCountry}
          billingCountry={billingCountry}
        />
        <Button type={"primary"} onClick={onClose}>
          Got it
        </Button>
      </div>
    </Popup>
  );
};

export default RestrictedRegionModal;
