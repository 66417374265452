import { createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { getUserIPDetails } from "../../services/apiFunctions";
import { STATUS_CODES } from "../../constants/api.constants";
import { userActions } from "../../store/actions";
import { RootState } from "../../types/store.type";

/**
 * Thunk action to fetch the user's IP details from the `/internal/ip-to-country` endpoint.
 *
 * @returns {Promise<void>} A promise that resolves when the user's IP details are successfully fetched and updated in the store.
 */
export const fetchUserIPDetails = createAsyncThunk<void, void>(
  "user/fetchUserIPDetails",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await getUserIPDetails();
      if (response?.status === STATUS_CODES.OK) {
        const { countryName, isoCode, isOFACBlacklisted, isRestrictedRegion } =
          response.data;
        dispatch(
          userActions.updateUser({
            ipDetails: {
              countryName,
              countryCode: isoCode,
              isOFACBlacklisted,
              isRestrictedRegion,
            },
          })
        );
        return;
      }
      return rejectWithValue("Failed to get user IP details");
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const selectUserState = (state: RootState) => state.user;

export const IPDetailsSelector = createSelector(
  selectUserState,
  (user) => user.ipDetails
);

export const isOFACBlacklistedOrRestrictedRegionSelector = createSelector(
  IPDetailsSelector,
  (ipDetails) => ipDetails.isOFACBlacklisted || ipDetails.isRestrictedRegion
);
