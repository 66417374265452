import { TPlan, TGroupedFeature, TFeature } from "../types/plan.type";
import { getRoundedDecimal } from "./utils";

/**
 * Returns the cheapest renewal price for a domain based on the specified criteria.
 * @returns {number|null} The cheapest permailbox renewal price for the domain, or null if no plan is found.
 */

export const getCheapestRenewalPriceForDomain = (plansToUse: any[]) => {
  // Find the plan with the least zIndex value
  const leastZIndexPlan = plansToUse.reduce((acc, plan) => {
    return acc === null || plan.zIndex < acc.zIndex ? plan : acc;
  }, null);

  if (!leastZIndexPlan) return null;

  // Return the least pricing of the plan, or null if no such plan is found
  const availableBillingCycles = Object.keys(leastZIndexPlan.pricing || {});
  return availableBillingCycles.reduce((acc, billingCycle) => {
    const firstBillingCyclePrice =
      leastZIndexPlan.pricing[billingCycle]?.firstBillingCyclePrice;
    return acc === null
      ? firstBillingCyclePrice
      : Math.min(acc, firstBillingCyclePrice);
  }, null);
};

/**
 * @param planPricing
 * @returns
 */
export const addDiscountPercentageToPlans = (planPricing: {
  [key: string]: {
    price: number;
    firstBillingCyclePrice: number;
    discountPercentage?: number;
  };
}): any => {
  Object.keys(planPricing).forEach((plan) => {
    const { price, firstBillingCyclePrice } = planPricing[plan];
    const discountPercentage = ((price - firstBillingCyclePrice) / price) * 100;

    planPricing[plan].discountPercentage = getRoundedDecimal(
      discountPercentage,
      0
    ) as number;
  });

  return planPricing;
};

/**
 * Returns the grouped features for the specified plans.
 * @param {TPlan[]} planList The list of plans to compare.
 * @param {object} options The options to use when comparing the plans.
 * @param {function} options.getFeatureValueForPlan The function to use to get the feature value for a plan.
 * @param {function} options.getFeatTitle The function to use to get the title of a feature.
 * @returns {object} An object containing the grouped features.
 */
export const getPlanFeaturesComparison = (
  plans: TPlan[],
  {
    getFeatureValueForPlan,
    getFeatTitle,
  }: {
    getFeatureValueForPlan: (
      feature: TFeature,
      plan: TPlan
    ) => TGroupedFeature["plans"][number] | null;
    getFeatTitle: (feature: TFeature) => string;
  }
): {
  groupedFeatures: { categoryName: string; features: TGroupedFeature[] }[];
} => {
  const planList = plans
    .filter(Boolean)
    .sort((plan1, plan2) => plan1.zIndex - plan2.zIndex); // Clone the plan list and sort it by zIndex

  const featureGroupsMap = new Map<string, TGroupedFeature[]>();

  planList.forEach((plan, index) => {
    plan.features.forEach((feature) => {
      const featureGroup = feature.featureGroup || "";
      const displayOrder = feature.displayOrder;

      if (!featureGroupsMap.has(featureGroup)) {
        featureGroupsMap.set(featureGroup, []);
      }

      const featureList = featureGroupsMap.get(featureGroup)!;
      let featureItem = featureList.find((f) => f.type === feature.type);

      if (!featureItem) {
        const title = getFeatTitle(feature);

        featureItem = {
          type: feature.type,
          plans: [],
          displayOrder,
          metaInfo: feature.metaInfo,
          title,
        };
        featureList.push(featureItem);
      }

      featureItem.plans[index] =
        getFeatureValueForPlan(feature, plan) ??
        (featureItem.plans[index] || true);
    });

    // plan.features.sort((a, b) => a.displayOrder - b.displayOrder);
  });

  featureGroupsMap.forEach((features) => {
    features.sort((a, b) => a.displayOrder - b.displayOrder);
  });

  const featureGroupsArray = Array.from(
    featureGroupsMap,
    ([featureGroupName, features]) => ({
      featureGroupName,
      features,
      minDisplayOrder: Math.min(...features.map((f) => f.displayOrder)),
    })
  );

  featureGroupsArray.sort((a, b) => a.minDisplayOrder - b.minDisplayOrder);

  const groupedFeatures = featureGroupsArray.map((group) => ({
    categoryName: group.featureGroupName,
    features: group.features,
  }));

  return { groupedFeatures };
};

export const addPricingOfPaidPlanIntoTrialPlan = (plans: TPlan[]): TPlan[] => {
  const pricingMap = new Map();

  plans.forEach((plan) => {
    if (plan.isPaid) {
      pricingMap.set(plan.id, JSON.parse(JSON.stringify(plan.pricing)));
    }
  });

  return plans.map((plan) => {
    if (plan.isTrial) {
      const basePlanPricing = pricingMap.get(plan.paidPlanId);
      if (basePlanPricing) {
        return {
          ...plan,
          pricing: JSON.parse(JSON.stringify(basePlanPricing)),
        };
      }
    }
    return { ...plan };
  });
};

export const isFreePlan = (plan: TPlan) => {
  return !plan.isPaid && !plan.isTrial;
}

export const isTrialPlan = (plan: TPlan) => {
  return !plan.isPaid && plan.isTrial;
}

export const isPaidPlan = (plan: TPlan) => {
  return plan.isPaid && !plan.isTrial;
}

export const filterOutFreePlans = (plans: TPlan[]): TPlan[] => {
  return plans.filter((plan) => !isFreePlan(plan));
};

export const formatPlanDisplayName = (
  displayName?: TPlan["displayName"],
  removeTrialSuffix = true
) => {
  if (!displayName) return displayName;
  let _displayName = displayName;
  _displayName = _displayName?.replace("Business ", ""); // Not required mostly. just keeping here incase
  if (removeTrialSuffix) _displayName = _displayName.replace(" Trial", ""); // Remove trial from display name. Keeping this for some time. Otherwise it would be server controlled.
  return _displayName;
};
