import React, { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  ANALYTICS_EVENTS,
  BILLING_CYCLE_DISPLAY_LABELS,
  BILLING_CYCLE_KEYS,
  HEADER_TYPES,
  PAGE_PATHS,
  TRIAL_DURATIONS_IN_DAYS,
} from "../../../helpers/constants";
import { PLAN_TYPES } from "../../../constants/mail.constant";
import { appSelector, userSelector } from "../../../store/selectors";
import Header from "../../Header";
import NeedMoreMailbox from "../../NeedMoreMailbox";
import { TPlan } from "../../../types/plan.type";
import { LimitedTimeOfferBanner } from "../../LimitedTimeOfferBanner";
import {
  mailFeatureGroups,
  mailFeaturesTextMap,
} from "../../../constants/feature.constant";
import BillingInfo from "../shared/billingInfo";
import Promo from "../../Promo";
import PlanButton from "../shared/PlanButton";
import PlanDiscountTag from "../shared/PlanDiscountTag";
import BillingCycleToggle from "../shared/BillingCycleToggle";
import PlansTable from "../shared/plansTable";
import { getSupportedBillingCycleForPlan } from "../../../helpers/mail/billing-cycle.helper";
import { TBillingCycle } from "../../../types/billing-cycle";
import { logMailPlanSelectedEvent } from "../../../telemetry/medusaEventsFunctions";
import { TrialConfig } from "../shared/types";
import { useAvailableBillingCycles } from "../../../hooks/mail/useAvailableBillingCycles";
import commonStyles from "../commonStyle.module.scss";
import {
  isMailDomainCustom,
  selectMailDomainAttrs,
  selectMailDomainPricing,
  selectMailPlan,
  selectMailPurchaseDomain,
  setMailPlan,
} from "../../../store/slices/mail.slice";
import { LitePlanNotAvailableButton } from "./LitePlanNotAvailableButton";
import styles from "./style.module.scss";
import MailFeatureCard from "./FeatureCard";
import { usePlans } from "./usePlans";
import { logMedusaEvent } from "../../../telemetry/medusaHelper";

type TPlanTableComponents = React.ComponentProps<
  typeof PlansTable
>["components"];

const { PLAN_SELECTED } = ANALYTICS_EVENTS;

const getPlanTag = (plan: TPlan) => {
  if (plan.neoPlanType === PLAN_TYPES.MAX) return "Best Value";
  if (plan.neoPlanType === PLAN_TYPES.STANDARD) return "Popular";
  return "";
};

const constructPricingForSupportedBillingCycles = (
  pricing: TPlan["pricing"],
  supportedBillingCycles: TBillingCycle[]
) => {
  return supportedBillingCycles.reduce((acc, billingCycle) => {
    const { price, firstBillingCyclePrice, discountPercentage } =
      pricing[billingCycle] || {};
    acc[billingCycle] = {
      original: price,
      discounted: firstBillingCyclePrice,
      discountPercentage,
    };
    return acc;
  }, {} as Record<TBillingCycle, { original?: number; discounted?: number; discountPercentage?: number }>);
};

const Plans: FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const { currency, haveLitePlanSupport } = user;
  const domain = useSelector(selectMailPurchaseDomain);
  const { duration: planDuration } = useSelector(selectMailPlan);
  const { isCoSiteTrial } = useSelector(selectMailDomainAttrs);
  const domainPricing = useSelector(selectMailDomainPricing);
  const { search_params } = useSelector(appSelector);
  const isCustomDomain = useSelector(isMailDomainCustom);
  const { cycles } = useAvailableBillingCycles();
  const navigateTo = useNavigate();
  const { visiblePlans, allFeatures, groupedFeatures } = usePlans({
    isCustomDomain,
    domain,
    duration: planDuration,
    featuresTextMap: mailFeaturesTextMap,
    domainPricing,
    isCoSiteTrial,
  });

  const changeBillingCycle = useCallback(
    (duration: TBillingCycle) => {
      dispatch(setMailPlan({ duration }));
      logMedusaEvent(ANALYTICS_EVENTS.PLAN_BILLING_TAB_SWITCHED, [], {
        switched_to: BILLING_CYCLE_DISPLAY_LABELS[duration]
      })
    },
    [dispatch]
  );

  const onSelectPlan = useCallback(
    (_plan: TPlan) => {
      const { id } = _plan;
      const supportedBillingCycles = getSupportedBillingCycleForPlan(
        _plan,
        haveLitePlanSupport
      );
      dispatch(setMailPlan({ id, supportedBillingCycles }));
      logMailPlanSelectedEvent(PLAN_SELECTED);
      navigateTo({
        pathname: PAGE_PATHS.BILLING,
        search: search_params,
      });
    },
    [dispatch, navigateTo, search_params, haveLitePlanSupport]
  );

  const components: TPlanTableComponents = useMemo(() => {
    return {
      BillingInfo: (props: any) => (
        <BillingInfo billingFor="mailbox/mo" {...props} />
      ),
      Promo,
      PlanButton: ({ plan }: { plan: TPlan }) => {
        const planType = plan?.neoPlanType;

        if (
          planType === PLAN_TYPES.LITE &&
          planDuration === BILLING_CYCLE_KEYS.QUARTERLY
        ) {
          return <LitePlanNotAvailableButton />;
        }
        return <PlanButton plan={plan} />;
      },
      PlanDiscountTag: ({ plan }: { plan: TPlan }) => {
        const flatPriceForever = plan.neoPlanType === PLAN_TYPES.LITE;

        return (
          <PlanDiscountTag
            plan={plan}
            flatPriceForever={flatPriceForever}
            className={classNames({
              visibilityHidden:
                flatPriceForever &&
                planDuration === BILLING_CYCLE_KEYS.QUARTERLY,
            })}
          />
        );
      },
      EmptyPlanItem: () => (
        <>
          <NeedMoreMailbox className={styles.needMoreMailbox} />
          {haveLitePlanSupport ? (
            <div className={classNames(styles.cancelOrPauseHighlighted)}>
              <div className={styles.cancelOrPauseText}>
                Try risk free. Cancel or pause anytime.
              </div>
              <div className={styles.cancelOrPauseArrow} />
            </div>
          ) : null}
        </>
      ),
      FeatureCard: MailFeatureCard,
    };
  }, [planDuration]);

  const trialConfig = useMemo<TrialConfig>(() => {
    if (isCoSiteTrial) {
      return {
        isTrial: true,
        trialDurationInDays: TRIAL_DURATIONS_IN_DAYS.CO_SITE,
      };
    }
    if (isCustomDomain) {
      return {
        isTrial: true,
        trialDurationInDays: TRIAL_DURATIONS_IN_DAYS.CUSTOM_DOMAIN,
      };
    }
    return { isTrial: false };
  }, [isCoSiteTrial, isCustomDomain]);

  return (
    <>
      <Header type={HEADER_TYPES.PLANS} className={commonStyles.header} />
      <div>
        <div className={commonStyles.firstHeaderTileWrapper}>
          <NeedMoreMailbox
            className={classNames(styles.needMoreMailbox, styles.mobileOnly)}
          />
          <BillingCycleToggle
            changeBillingCycle={changeBillingCycle}
            cycles={cycles}
            backupCycle={BILLING_CYCLE_KEYS.YEARLY}
            billingCycle={planDuration}
          />
          {haveLitePlanSupport ? (
            <div
              className={classNames(
                styles.cancelOrPauseAnytime,
                styles.mobileOnly
              )}
            >
              Try risk free. Cancel or pause anytime.
            </div>
          ) : null}
        </div>
        <div className={commonStyles.plansWrapper}>
          <LimitedTimeOfferBanner
            offerBillingCycle={BILLING_CYCLE_KEYS.QUARTERLY}
            duration={planDuration}
            cycles={cycles}
          />
          <PlansTable
            domain={domain}
            allFeatures={allFeatures}
            visiblePlans={visiblePlans}
            featuresTextMap={mailFeaturesTextMap}
            groupedFeatures={groupedFeatures}
            currency={currency}
            duration={planDuration}
            components={components}
            trialConfig={trialConfig}
            onSelectPlan={onSelectPlan}
            getPlanTag={getPlanTag}
            featureGroupTextMap={mailFeatureGroups}
          />
        </div>
      </div>
    </>
  );
};

export default Plans;
