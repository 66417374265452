import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useEffect, useMemo, useState } from "react";
import {
  createMedusaPayload,
  getLastExitIntentShownAtFromCookie,
  getVisitorEmailFromCookie,
  isExitIntentDisabled,
  setLastExitIntentShownAtFromCookie,
  setVisitorEmailInCookie,
} from "../../helpers/exitIntent.helper";
import {
  EXIT_INTENT_TYPES,
  PITCH_TYPES,
} from "../../constants/exitIntent.constants";
import { ANALYTICS_EVENTS, PAGE_PATHS } from "../../helpers/constants";
import { Values } from "../../types/common";
import useIsLoggedIn from "../../hooks/useIsLoggedIn";
import { useMouseExitIntent } from "../../hooks/dom/exit-intent/useMouseExitIntent";
import { useBackButtonExitIntent } from "../../hooks/dom/exit-intent/useBackButtonExitIntent";
import { TMedusaPayload } from "../../types/medusa";
import { logDomainEvent } from "../../telemetry/medusaEventsFunctions";
import { appActions } from "../../store/actions";
import { appSelector } from "../../store/selectors";
import {
  appendQueryParamsToSearch,
  removeTrailingSlashes,
} from "../../utils/urlUtils";
import { addIdentifierForHattrs } from "../../telemetry/medusaService";
import { triggerEmailTo } from "../../helpers/enyo.helper";
import { updateUTMParamsInCookie } from "../../helpers/utm.helper";
import usePromo from "../../hooks/mail/usePromo";
import { getDiscountPercentage } from "../../helpers/discount.helper";
import { getUrlParams } from "../../helpers/utils";
import EmailPitchModal from "./EmailPitchModal";
import DomainPitchModal from "./DomainPitchModal";

const config = {
  [PAGE_PATHS.GET_STARTED]: {
    pitch: PITCH_TYPES.EMAIL,
  },
  [PAGE_PATHS.HAVE_DOMAIN]: {
    pitch: PITCH_TYPES.EMAIL,
  },
  [PAGE_PATHS.GET_DOMAIN]: {
    pitch: PITCH_TYPES.DOMAIN,
  },
};

const RADIX_PROMO_UTM_SOURCE = "radix";

const initialVisitorEmail = getVisitorEmailFromCookie();
const initialLastExitIntentTime = getLastExitIntentShownAtFromCookie();

const ExitIntent = () => {
  const [exitIntent, setExitIntent] = useState<Values<
    typeof EXIT_INTENT_TYPES
  > | null>(null);
  const [visitorEmail, setVisitorEmail] = useState<string | null>(
    initialVisitorEmail
  );
  const [lastExitIntentTime, setLastExitIntentTime] = useState<EpochTimeStamp>(
    initialLastExitIntentTime
  );
  const loggedIn = useIsLoggedIn();
  const location = useLocation();
  const { updateAppParams } = bindActionCreators(appActions, useDispatch());
  const { search_params } = useSelector(appSelector);
  const { pitch } = useMemo(
    () => config[removeTrailingSlashes(location.pathname)] || {},
    [location]
  );

  const isRadixPromoFlow = useMemo(() => {
    const { utm_source } = getUrlParams();
    return utm_source === RADIX_PROMO_UTM_SOURCE;
  }, []);

  const disabled =
    !!exitIntent ||
    loggedIn ||
    !pitch ||
    isRadixPromoFlow ||
    isExitIntentDisabled(visitorEmail, lastExitIntentTime);
  const mouseExited = useMouseExitIntent({ disabled });
  const backButtonExit = useBackButtonExitIntent({ disabled });
  const {
    isLoading,
    isInfluencerPromoCode,
    promoCodeDetails,
    emailAccountDiscount,
  } = usePromo({ fetchIfNotPresent: true });
  const shouldUseInfluencerCode =
    !isLoading &&
    isInfluencerPromoCode &&
    emailAccountDiscount &&
    promoCodeDetails?.id;
  const discountPercentage = emailAccountDiscount
    ? getDiscountPercentage(emailAccountDiscount)
    : 10;
  const promoCodeId = shouldUseInfluencerCode
    ? promoCodeDetails?.id
    : process.env.REACT_APP_PROMO_CODE_ID;

  useEffect(() => {
    if (!loggedIn || !pitch) return;
    setVisitorEmail(getVisitorEmailFromCookie());
    setLastExitIntentTime(getLastExitIntentShownAtFromCookie());
  }, [loggedIn, pitch, location.pathname]);

  // Handle mouse or back button exit intent
  useEffect(() => {
    if (!(mouseExited || backButtonExit)) {
      return;
    }
    const currentTime = Date.now();
    setExitIntent(
      mouseExited
        ? EXIT_INTENT_TYPES.MOUSE_EXIT
        : EXIT_INTENT_TYPES.BACK_BUTTON_EXIT
    );
    updateLastExitIntentTime(currentTime);
  }, [mouseExited, backButtonExit]);

  const updateLastExitIntentTime = (time: EpochTimeStamp) => {
    setLastExitIntentTime(time);
    setLastExitIntentShownAtFromCookie(time);
  };

  const updateVisitorEmail = (email: string) => {
    setVisitorEmail(email);
    setVisitorEmailInCookie(email);
  };

  useEffect(() => {
    if (!exitIntent) return;
    onEvent(ANALYTICS_EVENTS.EXIT_POPUP_VIEWED);
  }, [exitIntent]);

  const onEvent = (eventName: string, extraPayload: TMedusaPayload = {}) => {
    if (!pitch || !exitIntent) return;
    const payload = {
      ...createMedusaPayload({
        exitIntent,
        pitch,
        pageName: location.pathname,
        discountPercentage,
      }),
      ...extraPayload,
    };
    logDomainEvent(eventName, payload);
  };

  const hideExitIntent = () => {
    setExitIntent(null);
  };

  const addPromoCodeWithUTMParamsInUrl = () => {
    if (shouldUseInfluencerCode) return;
    const utmParams = {
      utm_source: "neo-mail",
      utm_campaign: "visitor-exit",
      utm_content:
        pitch === PITCH_TYPES.EMAIL
          ? `discount-${discountPercentage}-percent`
          : "free-domain",
    };
    updateSearchParamsAndUtmParams(
      {
        ...(pitch === PITCH_TYPES.EMAIL
          ? { promo_code_id: process.env.REACT_APP_PROMO_CODE_ID }
          : {}),
      },
      utmParams
    );
  };

  const updateSearchParamsAndUtmParams = (
    searchParams: Record<string, any>,
    utmParams: Record<string, any>
  ) => {
    updateAppParams({
      search_params: appendQueryParamsToSearch(search_params, {
        ...searchParams,
        ...utmParams,
      }),
    });
    // Have to update query param in redux otherwise while changing route it will be lost.because while changing route it always take the initial query params stored in redux.
    // TODO: Need to find a better way to handle this
    updateUTMParamsInCookie(utmParams); // Update utm params in cookie as we don't have any store to save this and after updating in search params it doesnt take latest change to be passed in medusa. Have to find a better way to handle this
  };

  const _triggerEmailTo = (email: string) => {
    triggerEmailTo(
      email,
      pitch === PITCH_TYPES.DOMAIN
        ? "exit_intent_free_domain"
        : "exit_intent_with_discount",
      {
        email,
        ...(shouldUseInfluencerCode
          ? {
              promo_code_id: `${promoCodeId}`,
              discount_percentage: `${discountPercentage}`,
              display_code: promoCodeDetails?.displayCode,
              influencer: true,
            }
          : {}),
      }
    );
  };

  const submitEmail = (email: string) => {
    updateVisitorEmail(email);
    addPromoCodeWithUTMParamsInUrl();
    _triggerEmailTo(email);
    addIdentifierForHattrs(email);
  };

  const onContinueClick = () => {
    hideExitIntent();
    addPromoCodeWithUTMParamsInUrl();
  };

  if (!pitch || !exitIntent) return null;

  if (pitch === PITCH_TYPES.EMAIL) {
    return (
      <EmailPitchModal
        isOpen={!!exitIntent}
        onClose={hideExitIntent}
        onSubmit={submitEmail}
        onEvent={onEvent}
        discountPercentage={discountPercentage}
      />
    );
  } else if (pitch === PITCH_TYPES.DOMAIN) {
    return (
      <DomainPitchModal
        isOpen={!!exitIntent}
        onClose={hideExitIntent}
        onSubmit={submitEmail}
        onEvent={onEvent}
        onContinueClick={onContinueClick}
      />
    );
  }
  return null;
};

export default ExitIntent;
