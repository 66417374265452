import React from "react";
import { TPlan } from "../../../types/plan.type";
import { TBillingCycle } from "../../../types/billing-cycle";
import {
  BILLING_CYCLE_DURATIONS,
  BILLING_CYCLE_PERIOD_VERBOSE_UNITS,
} from "../../../helpers/constants";
import Price from "../../Common/Price";
import { getRoundedDecimal } from "../../../helpers/utils";
import styles from "./styles.module.scss";
import Tooltip from "../../Common/Tooltip/Tooltip";

type Props = {
  mailPlan: TPlan;
  duration: TBillingCycle;
};

export default function Footer({ mailPlan, duration }: Props) {
  const pricingForCurrentBillingCycle = mailPlan.pricing[duration];
  const title = pricingForCurrentBillingCycle?.discountPercentage ? (
    <>
      then{" "}
      <Price
        value={getRoundedDecimal(
          pricingForCurrentBillingCycle.firstBillingCyclePrice
        )}
      />
      /mailbox/mo for first {BILLING_CYCLE_PERIOD_VERBOSE_UNITS[duration]}
    </>
  ) : (
    <>
      then{" "}
      <Price value={getRoundedDecimal(pricingForCurrentBillingCycle?.price)} />
      /mailbox/mo
    </>
  );

  const tooltip = pricingForCurrentBillingCycle?.discountPercentage ? (
    <Tooltip
      id="siteAddMailBoxFooter"
      tooltipIconClassName={styles.footerTooltipIcon}
      tooltipText={
        <>
          After the first {BILLING_CYCLE_PERIOD_VERBOSE_UNITS[duration]}, your
          email subscription will renew at{" "}
          <strong>
            <Price
              value={getRoundedDecimal(pricingForCurrentBillingCycle.price)}
            />
            /mailbox/mo
          </strong>
          , billed {BILLING_CYCLE_DURATIONS[duration]?.toLowerCase()}.
        </>
      }
    />
  ) : null;

  return (
    <div className={styles.footer}>
      <div>
        <b>15 day free trial</b> {title} {tooltip}
      </div>
      <div>Cancel or pause anytime</div>
    </div>
  );
}
