import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import {
  ANALYTICS_EVENTS,
  DOMAIN_ERROR_CODE,
  DOMAIN_TYPE,
  GA_EVENTS,
  NEO_OFFERING_EVENT_VALUE,
  OFFERING,
  sourceHookMapping,
  WINDOW_NAME,
} from "../../helpers/constants";
import { logGoogleAnalyticsEvent } from "../../helpers/gaEventHelper";
import {
  domainNameLength,
  isDomainValid,
  redirectToNeoAdminPanel,
  removeProtocolFromDomain,
} from "../../helpers/utils";
import {
  checkDomainRegistration,
  checkMailOrderAvailability,
} from "../../services/apiFunctions";
import { userSelector } from "../../store/selectors";
import {
  TDomainUnavailableReasons,
  domainUnavailableReasons,
} from "../../constants/domain.constant";
import Button from "../Common/Button/Button";
import Input from "../Common/Input/Input";
import Header from "../Header";
import PromoError from "../Promo/PromoError";
import { domainRecommendation } from "../../helpers/domainRecommendationHelpers";
import DomainRecommendation from "./DomainRecommendation";
import styles from "./styles.module.scss";
import { TDomainAvailabilityPayload, TRedirectState } from "./Common/types";
import { TLogEventProps } from "../../types/global";
import { DomainState } from "../../store/slices/domain.slice";

const {
  CUSTOMER_ACCOUNT_LINKED,
  DOMAIN_AVAILABILITY_CHECKED,
  DOMAIN_SELECTED,
  HAS_DOMAIN_CHECKED,
} = ANALYTICS_EVENTS;

const GO_TO_NAP = ["DOMAIN_ALREADY_EXISTS", "DOMAIN_BELONGS_TO_CUSTOMER"];
const UNREGISTERED_DOMAIN = "Unregistered domain";
const GET_DOMAIN_FROM_NEO = "Get a domain from Neo";
const SOURCE_HOOK_GET_DOMAIN_CLICK = {
  HAVE_A_DOMAIN_PAGE_SUBCOPY: "Have a domain page subcopy",
  SHARED_SUBDOMAIN_ERROR: "Shared subdomain error",
  UNREGISTERED_DOMAIN_ERROR: "Unregistered domain error",
};
const DOMAIN_NAME_NOT_VALID = "Domain name is not valid";

const {
  HAVE_A_DOMAIN_PAGE_SUBCOPY,
  SHARED_SUBDOMAIN_ERROR,
  UNREGISTERED_DOMAIN_ERROR,
} = SOURCE_HOOK_GET_DOMAIN_CLICK;

const { USER_CUSTOM_DOMAIN } = NEO_OFFERING_EVENT_VALUE;

interface Props {
  title: string;
  subTitle?: string;
  customDomain: DomainState["customDomain"];
  offering: DomainState["offering"];
  domainOwnershipConfirmation: (domain: string) => React.ReactNode;
  navigateToGetDomain: (state?: TRedirectState) => void;
  onDomainAvailable: () => void;
  EmailBenefits?: React.ReactNode;
  setCustomDomain: (domainForPurchase: string) => void;
  setCoSiteDomain: (domainForPurchase: string) => void;
  onContinueOfDomainAvailability: (payload: TDomainAvailabilityPayload) => void;
  continueCTALabel: React.ReactNode;
  logEvent: (props: TLogEventProps) => void;
  continueToNextIfDomainAvailable?: boolean;
}

const HaveDomain: React.FC<Props> = (props) => {
  const {
    title,
    subTitle,
    customDomain,
    offering,
    domainOwnershipConfirmation,
    navigateToGetDomain,
    onDomainAvailable,
    EmailBenefits,
    setCustomDomain,
    setCoSiteDomain,
    onContinueOfDomainAvailability,
    logEvent,
    continueCTALabel,
    continueToNextIfDomainAvailable,
  } = props;
  const user = useSelector(userSelector);
  const { customer_id, token } = user;

  const [inputValue, setInputValue] = useState<string>("");
  const [error, setError] = useState<React.ReactNode | null>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [isAvailable, setIsAvailable] = useState<boolean>(true);
  const [reason, setReason] = useState<string | null>(null);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    if (customDomain && isDomainValid(customDomain)) {
      setInputValue(customDomain);
      if(continueToNextIfDomainAvailable) handleSearch(customDomain);
    }
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const valueWithoutSpace = value.replaceAll(" ", "").toLowerCase();
    setInputValue(valueWithoutSpace);
    setError(null);
    setIsAvailable(true);
    if (!valueWithoutSpace) {
      setIsChecked(false);
    }
  };

  const domainNameNotValid = (domainName: string): React.ReactNode => (
    <p className={styles.error}>
      Enter a valid domain name like {domainName || "acme"}.<b>com</b> or{" "}
      {domainName || "acme"}.<b>net</b>
    </p>
  );

  const handleSearch = (inputValue: string) => {
    if (inputValue) {
      const sanitizedDomainValue = removeProtocolFromDomain(inputValue);
      if (isDomainValid(sanitizedDomainValue)) {
        checkAvailability(sanitizedDomainValue);
      } else {
        const recommendedDomain = domainRecommendation(sanitizedDomainValue);
        setError(domainNameNotValid(recommendedDomain));
        logDomainAvailabilityCheckedMedusa(
          inputValue,
          false,
          DOMAIN_NAME_NOT_VALID
        );
      }
    } else {
      setError("Please enter domain name");
    }
  };

  const logDomainAvailabilityCheckedMedusa = (
    domainName: string,
    isDomainAvailable: boolean,
    unAvailabilityReason: string,
    nameservers?: string
  ) => {
    let payload: Record<string, any> = {
      domain_name: domainName,
      domain_availability: isDomainAvailable
        ? "Available"
        : domainUnavailableReasons[
            unAvailabilityReason as keyof TDomainUnavailableReasons
          ]?.event_availability_message,
      error: isDomainAvailable
        ? undefined
        : domainUnavailableReasons[
            unAvailabilityReason as keyof TDomainUnavailableReasons
          ]?.event_error_message || unAvailabilityReason,
      domain_ownership:
        offering === OFFERING.COSITE
          ? DOMAIN_TYPE.DONT_HAVE_DOMAIN
          : DOMAIN_TYPE.ALREADY_HAVE_DOMAIN,
      domain_name_length: domainNameLength(domainName),
      source_hook: sourceHookMapping["purchaseFlow"],
    };

    if (nameservers !== "") {
      payload = {
        ...payload,
        domain_nameserver: nameservers,
      };
    }
    logEvent({
      eventName: DOMAIN_AVAILABILITY_CHECKED,
      data: payload,
    });
  };

  const { FLOCK_ADMIN } = WINDOW_NAME;

  const checkMailAvailability = (domainName: string, nameServers: string) => {
    checkMailOrderAvailability({
      domainName,
      userId: customer_id,
    })
      .then((resp) => {
        const response = resp.data;
        if (response.statusCode !== 200) {
          const { code } = response;
          const { INVALID_DOMAIN, SHORT_DOMAIN_NAME, DISALLOWED_DOMAIN } =
            DOMAIN_ERROR_CODE;
          switch (code) {
            case INVALID_DOMAIN:
              setIsAvailable(false);
              setError(response.description);
              return;

            case SHORT_DOMAIN_NAME:
              setIsAvailable(false);
              setError(
                "This domain is not supported right now. Please write to us at hello@neo.space"
              );
              return;

            case DISALLOWED_DOMAIN:
              const { disallowedDomain } = response;
              const domainForPurchase = domainName.split(
                `.${disallowedDomain}`
              )[0];
              setIsAvailable(false);
              setError(
                <>
                  {`Subdomains like .${disallowedDomain} do not allow setting up email in it. Try another domain or `}
                  <span
                    className={styles.neoAdminLink}
                    onClick={() =>
                      handleGetDomain(SHARED_SUBDOMAIN_ERROR, domainForPurchase)
                    }
                  >
                    {GET_DOMAIN_FROM_NEO}
                  </span>
                  <div className={styles.arrow} />
                </>
              );
              logDomainAvailabilityCheckedMedusa(
                domainName,
                false,
                code,
                nameServers
              );
              return;

            default:
              break;
          }
          console.error(
            "Error fetching domain availability information",
            response
          );
          return;
        }
        const { isDomainAvailable, unAvailabilityReason = "" } = response;

        logGoogleAnalyticsEvent(GA_EVENTS.DOMAIN_AVAILABILITY_CHECKED);
        logDomainAvailabilityCheckedMedusa(
          domainName,
          isDomainAvailable,
          unAvailabilityReason,
          nameServers
        );

        if (isDomainAvailable) {
          setIsAvailable(true);
          onContinueOfDomainAvailability({
            customDomain: domainName,
            offering: OFFERING.CUSTOM_DOMAIN,
          });
          logEvent({
            eventName: DOMAIN_SELECTED,
            data: {
              domain_name: domainName,
              neo_offering: USER_CUSTOM_DOMAIN,
              source_hook: "Domain availability check page",
            },
          });
          if (!!token) {
            logEvent({
              eventName: CUSTOMER_ACCOUNT_LINKED,
              data: {
                user_type: "Customer",
                customer_account_linked_via: "Already signed in",
                dummy_entity_Id: true,
              },
              flag: true,
            });
          }
          onDomainAvailable();
        } else {
          setIsAvailable(false);
          setReason(unAvailabilityReason);
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const checkAvailability = (domainName: string) => {
    setProcessing(true);
    checkDomainRegistration({ domainName }).then((response) => {
      if (response.status !== 200) {
        setProcessing(false);
        setError("Something went wrong, Please try again");
        console.error("Check domain registration error", response);
        return;
      }

      const { registered, nameServers } = response.data;
      let nameserverString = "";

      if (nameServers) {
        nameserverString = nameServers.join(", ");
      }

      if (registered) {
        checkMailAvailability(domainName, nameserverString);
      } else {
        setProcessing(false);
        setIsAvailable(false);
        setError(
          <>
            {UI_ERROR_MESSAGE.DOMAIN_OWNERSHIP_VERIFICATION_ERROR}
            <div className={styles.error}>
              Don't own a domain name yet?{" "}
              <span
                className={styles.neoAdminLink}
                onClick={() => {
                  handleGetDomain(UNREGISTERED_DOMAIN_ERROR, domainName);
                }}
              >
                {GET_DOMAIN_FROM_NEO}
              </span>
              <div className={styles.arrow} />
            </div>
          </>
        );
        logDomainAvailabilityCheckedMedusa(
          domainName,
          false,
          UNREGISTERED_DOMAIN,
          nameserverString
        );
      }
    });
  };

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setIsChecked(checked);
  };

  const handleGetDomain = (sourceHook: string, domainForPurchase?: string) => {
    logEvent({
      eventName: HAS_DOMAIN_CHECKED,
      data: {
        domain_ownership: DOMAIN_TYPE.DONT_HAVE_DOMAIN,
        neo_offering: "",
        source_hook: sourceHook,
      },
    });

    if (domainForPurchase) {
      setCustomDomain(domainForPurchase);
    }
    navigateToGetDomain();
  };

  const handleRedirectToNeoAdminPanel = () => {
    window.open(redirectToNeoAdminPanel(), FLOCK_ADMIN);
  };

  /** UI Error message for DOMAIN_ALREADY_EXISTS or DOMAIN_BELONGS_TO_CUSTOMER */
  const UI_ERROR_MESSAGE: Record<string, string> = {
    DOMAIN_ALREADY_EXISTS: `If you own ${inputValue} and want to add another mailbox in Neo, go to `,
    DOMAIN_BELONGS_TO_CUSTOMER: `You already own ${inputValue} and if you want to add another mailbox in Neo, go to `,
    DOMAIN_OWNERSHIP_VERIFICATION_ERROR: `Seems like this domain is not registered yet. If it was registered recently, try again in 4-5 hours.`,
  };

  const hasError = error || !isAvailable;

  return (
    <>
      <PromoError className={styles.promoError} />
      <Header>{title}</Header>
      <div className={styles.alreadyHaveDomain}>
        <p>
          {subTitle && (
            <>
              <span>{subTitle}</span>
              <br />
            </>
          )}
          Don’t have a domain name yet?{" "}
          <span
            className={styles.getDomain}
            onClick={() => handleGetDomain(HAVE_A_DOMAIN_PAGE_SUBCOPY)}
          >
            Get a domain
          </span>
        </p>
        <Input
          name={"yourName"}
          placeholder="Enter the domain name, like example.com"
          type={"text"}
          onChange={handleInputChange}
          hasError={Boolean(hasError)}
          errorMessage={
            error ||
            domainUnavailableReasons[reason as keyof TDomainUnavailableReasons]
              ?.error_message ||
            reason
          }
          value={inputValue}
          autoCapitalize={"none"}
        />
        {!isAvailable && reason && GO_TO_NAP.includes(reason) && (
          <p className={styles.redirectToNap}>
            {UI_ERROR_MESSAGE[reason]}
            <span
              className={styles.neoAdminLink}
              onClick={() => handleRedirectToNeoAdminPanel()}
            >
              Neo Admin Panel
            </span>
          </p>
        )}

        <div>
          {inputValue && !hasError && (
            <label className={styles.checkboxButton}>
              <input
                type="checkbox"
                className={styles.checkboxButtonInput}
                checked={isChecked}
                onChange={handleCheckbox}
              />
              <span className={styles.checkboxButtonControl}></span>
              <span>{domainOwnershipConfirmation(inputValue)}</span>
            </label>
          )}
          {hasError && (
            <DomainRecommendation
              domain={removeProtocolFromDomain(inputValue)}
              navigateToGetDomain={navigateToGetDomain}
              setCoSiteDomain={setCoSiteDomain}
              logEvent={logEvent}
            />
          )}
        </div>
        <Button
          type={"primary"}
          onClick={() => isChecked && handleSearch(inputValue)}
          className={classNames({
            [styles.disabled]: !isChecked || !!hasError,
            [styles.proceedBtn]: true,
          })}
          isProcessing={processing}
          width={"100%"}
        >
          {continueCTALabel}
        </Button>
        {EmailBenefits && <div>{EmailBenefits}</div>}
      </div>
    </>
  );
};

export default HaveDomain;
