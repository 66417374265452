import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../types/store.type";

const selectUserState = (state: RootState) => state.user;

export const selectUserDetails = createSelector(selectUserState, (user) => {
  const {
    account_id,
    alternateEmail,
    billingInformation,
    controlPanelAutoLoginToken,
    customer_id,
    domain,
    experiments,
    firstOrder,
    hasActiveOrders,
    isBillingInfoSaved,
    name,
    order_id,
    payment_amount,
    plan,
    source_hook,
    token,
    tokenExpiry,
    webmailAutoLoginToken,
    customMailboxInput,
    showTeamMailboxPostDomainPurchase,
    entriResponse,
    persona,
    showMoneyBackGuarantee,
    promo_discount_amount,
    currency,
    haveLitePlanSupport,
    payment_for,
    total_mail_price,
    total_site_price,
    total_neo_domain_price,
    billing_cycle_updated,
    bundleId,
    ipDetails,
  } = user;
  return {
    account_id,
    alternateEmail,
    billingInformation,
    controlPanelAutoLoginToken,
    customer_id,
    domain,
    experiments,
    firstOrder,
    hasActiveOrders,
    isBillingInfoSaved,
    name,
    order_id,
    payment_amount,
    plan,
    source_hook,
    token,
    tokenExpiry,
    webmailAutoLoginToken,
    customMailboxInput,
    showTeamMailboxPostDomainPurchase,
    entriResponse,
    persona,
    showMoneyBackGuarantee,
    promo_discount_amount,
    currency,
    haveLitePlanSupport,
    payment_for,
    total_mail_price,
    total_site_price,
    total_neo_domain_price,
    billing_cycle_updated,
    bundleId,
    ipDetails,
  };
});
