import React from "react";
import { TBillingCycle } from "../../../../types/billing-cycle";
import Price from "../../../Common/Price";
import { NUMBER_OF_MONTHS } from "../../../../helpers/constants";
import { getRoundedDecimal } from "../../../../helpers/utils";

type Props = {
  perMailBoxPrice: number;
  duration: TBillingCycle;
  countOfMailboxAdded: number;
};

export default function MailBoxPriceBreakup({
  perMailBoxPrice,
  duration,
  countOfMailboxAdded,
}: Props) {
  const noOfMonths =
    NUMBER_OF_MONTHS[duration as keyof typeof NUMBER_OF_MONTHS];
  return (
    <div>
      <Price value={getRoundedDecimal(perMailBoxPrice, 2, "string")} /> x{" "}
      {noOfMonths} {noOfMonths > 1 ? "months" : "month"} x {countOfMailboxAdded}{" "}
      {countOfMailboxAdded > 1 ? "mailboxes" : "mailbox"}
    </div>
  );
}
