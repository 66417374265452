import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import BackIcon from "../../../assets/back.svg";
import { appSelector } from "../../../store/selectors";
import { removeTrailingSlashes } from "../../../utils/urlUtils";
import { ANALYTICS_EVENTS, PAGE_PATHS } from "../../../helpers/constants";
import { logMedusaEvent } from "../../../telemetry/medusaHelper";
import styles from "./GoBack.module.scss";

const onGoBack = () => {
  const currentPath = removeTrailingSlashes(window.location.pathname);
  switch (currentPath) {
    case PAGE_PATHS.BILLING:
      logMedusaEvent(ANALYTICS_EVENTS.ORDER_SUMMARY_BACK_CLICKED, [], {});
      break;
    default:
      break;
  }
};

function GoBack({ pagePath }) {
  const navigateTo = useNavigate();

  const { search_params } = useSelector(appSelector);

  const handleGoBack = () => {
    if (pagePath) {
      navigateTo({
        pathname: pagePath,
        search: search_params,
      });
    } else {
      navigateTo(-1);
    }
    onGoBack();
  };

  return (
    <div className={styles.backBtn} onClick={() => handleGoBack()}>
      <img src={BackIcon} alt={"Back Icon"} />
      Back
    </div>
  );
}

export default GoBack;
