import { createSelector } from "@reduxjs/toolkit";

const selectErrorCountsState = (state) => state.errorCounts;

export const selectErrorCounts = createSelector(
  selectErrorCountsState,
  (errorCounts) => {
    const { emailCheckFailCount, passwordCheckFailCount } = errorCounts;
    return {
      emailCheckFailCount,
      passwordCheckFailCount,
    };
  }
);
