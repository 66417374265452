import React from "react";
import { useSelector } from "react-redux";
import { ANALYTICS_EVENTS } from "../../helpers/constants";
import { getCountOfTotalMailboxAdded } from "../../helpers/emailHelpers";
import { useRedirection } from "../../hooks/useRedirection";
import { initPayment, processPayment } from "../../services/apiFunctions";
import { userSelector } from "../../store/selectors";
import { logBillingPageEvent } from "../../telemetry/medusaEventsFunctions";
import CardElementsWrapper from "../CardElementsWrapper/CardElementsWrapper";
import { useBulkMailboxPurchase } from "./useBulkMailboxPurchase";
import {
  selectMailAdditionalMailbox,
  selectMailPurchaseDomain,
} from "../../store/slices/mail.slice";

const { PAYMENT_COMPLETED } = ANALYTICS_EVENTS;

const CoSiteDomainPayment = ({
  setCountOfMailboxPurchaseFailed,
  setMailboxPurchaseSuccess,
}) => {
  const {
    token,
    plan: { duration },
    billingInformation: { country, taxId },
    order_id,
  } = useSelector(userSelector);
  // TODO: for site
  const additionalMailboxes = useSelector(selectMailAdditionalMailbox);
  // TODO: for site
  const domain = useSelector(selectMailPurchaseDomain);
  const countOfMailboxAdded = getCountOfTotalMailboxAdded(additionalMailboxes);

  const { handleCheckoutRedirection } = useRedirection();
  const { handleBulkMailboxPurchase } = useBulkMailboxPurchase({
    setCountOfMailboxPurchaseFailed,
    setMailboxPurchaseSuccess,
  });

  const onPaymentSuccess = ({ paymentData }) => {
    logBillingPageEvent(PAYMENT_COMPLETED, {
      country,
      tax_id: taxId,
      order_id,
      user_type: "Order",
      // linkToEntity: true,
      payment_for: "Additional mailboxes",
      payment_card_type: paymentData.paymentCardType,
      payment_transaction_id: paymentData.transactionId,
    });
    // create email in bulk
    handleBulkMailboxPurchase();
  };

  const cardPaymentProps = {
    initPaymentAPI: initPayment,
    initPaymentPayload: {
      token,
      billingCycle: duration,
      domainName: domain,
      purchase: {
        accounts: {
          noOfAccounts: countOfMailboxAdded - 1, // excluding admin as this in checkout modal and order already created for admin
        },
      },
    },
    completePurchaseAPI: processPayment,
    completePurchasePayload: { domainName: domain, token },
    onPaymentSuccess,
    secondaryButtonText: "Add later",
    handleSecondaryBtn: handleCheckoutRedirection,
    fromCheckoutModal: true,
  };

  return <CardElementsWrapper {...cardPaymentProps} />;
};

export default CoSiteDomainPayment;
