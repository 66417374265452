import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Select from "react-select";
import { bindActionCreators } from "redux";
import { ANALYTICS_EVENTS, PAGE_PATHS } from "../../helpers/constants";
import {
  coSiteDomainPersonaQuestions,
  customDomainPersonaQuestions,
} from "../../helpers/personaQuestions";
import { isMobile } from "../../helpers/utils";
import { logEntityChange } from "../../services/apiFunctions";
import { userActions } from "../../store/actions";
import { appSelector, userSelector } from "../../store/selectors";
import { logPersonaPageEvent } from "../../telemetry/medusaEventsFunctions";
import Button from "../Common/Button/Button";
import Input from "../Common/Input/Input";
import styles from "./Persona.module.scss";
import { selectBoxStyling } from "./SelectBoxStyle";
import {
  isMailDomainCustom,
  selectMailPurchaseDomain,
} from "../../store/slices/mail.slice";

const INPUT_TYPE_NUMBER = "number";
const { CUSTOMER_PERSONA_SUBMITTED, NEO_PERSONA_SUBMITTED } = ANALYTICS_EVENTS;

function Persona() {
  const user = useSelector(userSelector);
  const {
    persona,
    billingInformation: { country },
    webmailAutoLoginToken,
    bundleId,
  } = user;
  // TODO: for site
  const domain = useSelector(selectMailPurchaseDomain);
  const { search_params } = useSelector(appSelector);
  const { updateUser } = bindActionCreators(userActions, useDispatch());
  const navigateTo = useNavigate();

  // TODO: for site
  const customDomain = useSelector(isMailDomainCustom);

  // requires memo, else options list will shuffle on any change
  const Questions = useMemo(() => {
    return customDomain
      ? customDomainPersonaQuestions(domain)
      : coSiteDomainPersonaQuestions(domain);
  }, [domain, customDomain]);

  const [personaDetails, setPersonaDetails] = useState(initialState(persona));
  const { q1, q2, q3, q4 } = personaDetails || {};

  const formErrorValues = {
    q1: false,
    q2: false,
    q3: false,
    q4: false,
    q5: false,
    q6: false,
    q7: false,
  };
  const [formErrors, setFormErrors] = useState(formErrorValues);

  const handleSelectChange = (selectedOption, index) => {
    let selectedValue,
      updatedReason = false;

    if (Array.isArray(selectedOption)) {
      // For multiple selection dropdown
      selectedValue = selectedOption.map(({ label }) => label);

      // if Others option is not selected in multiple selection dropdown then make reason false
      if (!selectedValue.includes("Others")) {
        updatedReason = true;
      }
    } else {
      // For single selection dropdown
      selectedValue = selectedOption.label;
    }
    setPersonaDetails({
      ...personaDetails,
      [`q${index + 1}`]: {
        answer: selectedValue,
        reason: updatedReason ? false : personaDetails[`q${index + 1}`].reason,
      },
    });
    setFormErrors({
      ...formErrors,
      [`q${index + 1}`]: false,
    });
  };

  const handleInputChange = (event, isReasonField) => {
    const { name: questionNumber, value } = event.target;
    if (isReasonField) {
      setPersonaDetails({
        ...personaDetails,
        [questionNumber]: {
          ...personaDetails[questionNumber],
          reason: value || false,
        },
      });
    } else {
      setPersonaDetails({
        ...personaDetails,
        [questionNumber]: {
          ...personaDetails[questionNumber],
          answer: value || "",
          reason: false,
        },
      });
    }
    setFormErrors({
      ...formErrors,
      [questionNumber]: false,
    });
  };

  const isErrorExists = (errors = formErrors) => {
    return Object.keys(errors).some((key) => errors[key]);
  };

  const validateAllInputs = () => {
    const { q1, q2, q3, q4, q5, q6, q7 } = personaDetails || {};

    const errors = { ...formErrors };

    errors.q1 = q1.answer === "";
    errors.q2 = q2.answer === "";
    errors.q3 = q3.answer === "";

    if (!customDomain) {
      errors.q4 = q4.answer === "" || q4.answer?.length === 0;
      errors.q5 = q5.answer === "" || q5.answer?.length === 0;
      errors.q6 = q6.answer === "" || q6.answer?.length === 0;
      errors.q7 = q7.answer === "" || q7.answer?.length === 0;
    }

    setFormErrors(errors);

    return !isErrorExists(errors);
  };

  const handleEntityChangeOnServer = () => {
    const payload = {
      type: "bundle",
      id: bundleId.toString(),
      attrs: {
        business_industry: q1.reason
          ? `${q1.answer} - ${q1.reason}`
          : q1.answer,
        employee_count: q2 && parseInt(q2.answer),
        role_in_business: q3.reason ? `${q3.answer} - ${q3.reason}` : q3.answer,
        signup_reason: q4.reason
          ? `${q4.answer.toString()} - ${q4.reason}`
          : q4.answer.toString(),
      },
    };
    return logEntityChange(
      payload,
      {},
      { "X-Session-Token": webmailAutoLoginToken }
    );
  };

  const handleSubmit = () => {
    const isFormValid = validateAllInputs();
    if (isFormValid) {
      updateUser({ ...user, persona: { ...personaDetails } });
      logPersonaPageEvent(CUSTOMER_PERSONA_SUBMITTED, { country });
      handleEntityChangeOnServer()
        .then((res) => {
          if (res.status !== 200) {
            console.error(
              `There was an error sending the entity change request. Error Code: ${res.status}.`
            );
          }
        })
        .finally(() => {
          logPersonaPageEvent(NEO_PERSONA_SUBMITTED, {
            country,
            user_type: "Order",
            entityId: bundleId,
            entityType: "bundle",
          });
          navigateTo({
            pathname: PAGE_PATHS.ALL_DONE,
            search: search_params,
          });
        });
    }
  };

  return (
    <div className={styles.personaWrapper}>
      <div className={styles.aboutDomain}>Tell us a li’l about {domain}</div>
      {Questions?.map((personaQuestionsDetail, index) => {
        const {
          question,
          options,
          type,
          placeholder,
          isMultiSelect = false,
        } = personaQuestionsDetail;
        const isRadioField = type === "radio";
        const qIndex = `q${index + 1}`;

        return (
          <div key={index} className={styles.inputWrapper}>
            <label className={styles.label}>{question}</label>
            {options ? (
              isRadioField ? (
                <div className={styles.radioList}>
                  {options.map((o) => (
                    <label>
                      <input
                        type="radio"
                        name={qIndex}
                        value={o.value}
                        onChange={handleInputChange}
                        checked={personaDetails[qIndex].answer === o.value}
                      />
                      <span>{o.label}</span>
                    </label>
                  ))}
                  {personaDetails?.[qIndex]?.answer.includes("Others") && (
                    <Input
                      name={qIndex}
                      placeholder="Please specify"
                      onChange={(e) => {
                        handleInputChange(e, true);
                      }}
                      defaultValue={personaDetails[qIndex].reason || ""}
                    />
                  )}
                  {formErrors[qIndex] && (
                    <div className={styles.error}>This field is required</div>
                  )}
                </div>
              ) : (
                <div className={styles.dropdown}>
                  {isMultiSelect ? (
                    <>
                      <Select
                        options={options}
                        onChange={(e) => handleSelectChange(e, index)}
                        menuShouldScrollIntoView
                        styles={selectBoxStyling}
                        className={styles.selectInput}
                        isMulti={isMultiSelect}
                        menuPlacement={isMobile() ? "top" : "auto"}
                        value={
                          personaDetails?.[qIndex]?.answer
                            ? personaDetails?.[qIndex]?.answer?.map((item) => {
                                return { value: item, label: item };
                              })
                            : null
                        }
                        isSearchable={false}
                        isClearable={false}
                      />
                      {personaDetails?.[qIndex]?.answer.includes("Others") && (
                        <Input
                          name={qIndex}
                          placeholder="Please specify"
                          onChange={(e) => {
                            handleInputChange(e, true);
                          }}
                          defaultValue={personaDetails[qIndex].reason || ""}
                        />
                      )}
                    </>
                  ) : (
                    <Select
                      options={options}
                      onChange={(e) => handleSelectChange(e, index)}
                      menuShouldScrollIntoView
                      styles={selectBoxStyling}
                      className={styles.selectInput}
                      isMulti={isMultiSelect}
                      menuPlacement={isMobile() ? "top" : "auto"}
                      value={
                        personaDetails?.[qIndex]?.answer
                          ? {
                              value: personaDetails[qIndex].answer,
                              label: personaDetails[qIndex].answer,
                            }
                          : null
                      }
                      isSearchable={false}
                      isClearable={false}
                    />
                  )}
                  {formErrors[qIndex] && (
                    <div className={styles.error}>This field is required</div>
                  )}
                </div>
              )
            ) : (
              <Input
                type={type || "text"}
                name={qIndex}
                onChange={handleInputChange}
                defaultValue={personaDetails[qIndex].answer || ""}
                hasError={formErrors[qIndex]}
                errorMessage={"This field is required"}
                placeholder={placeholder}
                onKeyPress={(event) => {
                  if (type === INPUT_TYPE_NUMBER && !/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                minimumNumber={type === INPUT_TYPE_NUMBER ? "0" : undefined}
              />
            )}
          </div>
        );
      })}
      <Button
        type={"primary"}
        onClick={handleSubmit}
        className={styles.continueBtn}
        width={"200px"}
      >
        Continue
      </Button>
    </div>
  );
}

export default Persona;

const initialState = (persona) => {
  const initialPersonaValues = {
    q1: {
      answer: persona?.q1?.answer || "",
      reason: false,
    },
    q2: {
      answer: persona?.q2?.answer || "",
      reason: false,
    },
    q3: {
      answer: persona?.q3?.answer || "",
      reason: false,
    },
    /** question fourth will be only available for cosite domain,
     *  It's a multiselection dropdown that's why it is an array
     */
    q4: {
      answer: persona?.q4?.answer || [],
      reason: persona?.q4?.reason || false,
    },
    q5: {
      answer: persona?.q5?.answer || "",
      reason: persona?.q5?.reason || false,
    },
    q6: {
      answer: persona?.q6?.answer || "",
      reason: persona?.q6?.reason || false,
    },
    q7: {
      answer: persona?.q7?.answer || [],
      reason: persona?.q7?.reason || false,
    },
  };

  return initialPersonaValues;
};
