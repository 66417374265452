import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { StylesConfig } from "react-select";
import { bindActionCreators } from "redux";
import { ANALYTICS_EVENTS } from "../../../helpers/constants";
import { countriesList } from "../../../helpers/countryCodes";
import { showErrorToast } from "../../../helpers/toastHelper";
import {
  doesErrorExist,
  getCountryDetailsFromCountryCode,
  isCountryIndia,
  isMobile,
  isValidGstin,
} from "../../../helpers/utils";
import {
  getBillingAddress,
  setBillingAddress,
} from "../../../services/apiFunctions";
import { userSelector } from "../../../store/selectors";
import Button from "../../Common/Button/Button";
import Input from "../../Common/Input/Input";
import WhyTrialTooltip from "../../WhyTrialTooltip/WhyTrialTooltip";
import { userActions } from "../../../store/actions";
import { TLogEventProps } from "../../../types/global";
import RestrictedRegionModal from "../../RestrictedRegionModal";
import { isRestrictedCountry } from "../../../helpers/ip.helper";
import { IPDetailsSelector } from "../../../store/slices/ipDetails.slice";
import BillingInfoStyles from "./BillingInformation.module.scss";

const { CUSTOMER_BILLING_DETAILS_ENTERED } = ANALYTICS_EVENTS;

interface BillingInformationProps {
  isVisible: boolean;
  onContinue: () => void;
  showWhyTrialTooltip: boolean;
  logEvent: (_: TLogEventProps) => void;
}

interface BillingDetails {
  yourName: string;
  companyName: string;
  billingAddress: string;
  zipCode: string;
  country: string;
  city: string;
  state: string;
  taxId?: string;
}

interface FormErrors {
  yourName: boolean;
  companyName: boolean;
  billingAddress: boolean;
  city: boolean;
  state: boolean;
  zipCode: boolean;
  country: boolean;
  taxId?: boolean;
}

// TODO: Optimize this component to avoid re-rendering
const BillingInformation: React.FC<BillingInformationProps> = ({
  isVisible,
  onContinue,
  showWhyTrialTooltip,
  logEvent,
}) => {
  const user = useSelector(userSelector);
  const { countryName } = useSelector(IPDetailsSelector);
  const {
    billingInformation: {
      yourName,
      companyName,
      billingAddress,
      zipCode,
      country,
      city,
      state,
      taxId,
    },
  } = user;

  const initialBillingInfoValues: BillingDetails = {
    yourName: yourName || "",
    companyName: companyName || "",
    billingAddress: billingAddress || "",
    zipCode: zipCode || "",
    country: country || "",
    city: city || "",
    state: state || "",
    taxId: taxId || "",
  };

  const [billingDetails, setBillingDetails] = useState<BillingDetails>(
    initialBillingInfoValues
  );
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [fetchingBillingInfo, setFetchingBillingInfo] =
    useState<boolean>(false);
  const [showRestrictedRegionModal, setShowRestrictedRegionModal] =
    useState<boolean>(false);

  const billingErrorObj: FormErrors = {
    yourName: false,
    companyName: false,
    billingAddress: false,
    city: false,
    state: false,
    zipCode: false,
    country: false,
    taxId: false,
  };
  const [formErrors, setFormErrors] = useState<FormErrors>(billingErrorObj);

  const { updateUser } = bindActionCreators(userActions, useDispatch());

  useEffect(() => {
    const payload = {
      token: user.token,
    };
    setFetchingBillingInfo(true);
    getBillingAddress(payload)
      .then((response) => {
        const res = response.data;

        if (response.status !== 200) {
          console.error("Error fetching billing address information", res);
          showErrorToast(
            "There was a problem fetching your billing address information. Please try again after sometime"
          );
          return;
        }

        if (
          !(res.billingAddress && Object.keys(res.billingAddress).length === 0)
        ) {
          const {
            name: yourName,
            companyAddress: billingAddress,
            city,
            state,
            companyName,
            zipCode,
            country,
            taxId,
          } = res.billingAddress;

          const { sanitizedCountryName } =
            getCountryDetailsFromCountryCode(country);

          updateUser({
            ...user,
            billingInformation: {
              city,
              yourName,
              billingAddress,
              state,
              zipCode,
              country: sanitizedCountryName,
              companyName,
              taxId,
            },
            isBillingInfoSaved: true,
          });

          setBillingDetails({
            ...billingDetails,
            city,
            yourName,
            billingAddress,
            state,
            zipCode,
            country: sanitizedCountryName,
            companyName,
            taxId,
          });

          if (isRestrictedCountry(country)) {
            setShowRestrictedRegionModal(true);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching billing address information", error);
        showErrorToast(
          "There was a problem fetching your billing address information. Please try again after sometime"
        );
      })
      .finally(() => setFetchingBillingInfo(false));
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormErrors({
      ...formErrors,
      [name]: false,
    });

    setBillingDetails({
      ...billingDetails,
      [name]: value,
    });
  };

  const handleCountrySelectionChange = (value: string) => {
    const name = "country";
    setFormErrors({
      ...formErrors,
      [name]: false,
    });
    setBillingDetails({
      ...billingDetails,
      [name]: value || "",
    });
  };

  const selectBoxStyling: StylesConfig<
    { value: string; label: string },
    false
  > = {
    control: () => ({
      padding: isMobile() ? "5px 10px" : "9.5px 10px",
      border: formErrors["country"] ? "1px solid #f00" : "1px solid #999",
      background: "#fff",
      borderRadius: "8px",
      outline: 0,
      cursor: "pointer",
      display: "flex",
      ":focus-within": {
        outline: 0,
        borderColor: "#0066FF",
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
    }),
    menu: (provided) => ({
      ...provided,
      minWidth: 300,
      maxWidth: "80%",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    option: (provided, { isSelected, isFocused }) => ({
      ...provided,
      color: "#333",
      cursor: "pointer",
      backgroundColor: isSelected || isFocused ? "lavender" : "transparent",
    }),
  };

  const validateInputs = (): boolean => {
    const {
      yourName,
      companyName,
      billingAddress,
      zipCode,
      country,
      city,
      state,
      taxId,
    } = billingDetails;
    const errors: FormErrors = { ...formErrors };

    errors["yourName"] = yourName === "";
    errors["billingAddress"] = billingAddress === "";
    errors["companyName"] = companyName === "";
    errors["zipCode"] = zipCode === "";
    errors["city"] = city === "";
    errors["state"] = state === "";
    errors["country"] = country === "";

    if (isCountryIndia(country) && taxId) {
      errors["taxId"] = !isValidGstin(taxId);
    }

    setFormErrors(errors);
    return !doesErrorExist(errors);
  };

  const handleSubmitBillingInfo = () => {
    setIsProcessing(true);
    const { alternateEmail, token } = user;
    const {
      yourName,
      companyName,
      billingAddress,
      zipCode,
      country,
      city,
      state,
      taxId,
    } = billingDetails;

    const isFormValid = validateInputs();

    if (!isFormValid) {
      setIsProcessing(false);
      return;
    }

    const { code } = countriesList.find((e) => e.value === country) || {
      code: "",
    };

    if (isRestrictedCountry(code)) {
      setShowRestrictedRegionModal(true);
      setIsProcessing(false);
      return;
    }

    logEvent({
      eventName: CUSTOMER_BILLING_DETAILS_ENTERED,
      data: {
        country,
        tax_id: taxId,
      },
    });

    const payload = {
      token,
      billingInfo: {
        email: alternateEmail,
        name: yourName,
        companyName,
        billingAddress,
        zipCode: zipCode,
        country: code,
        city,
        state,
      },
    };

    setBillingAddress(payload)
      .then((response) => {
        const res = response.data;

        if (response.status !== 200) {
          console.log(res.error);
          showErrorToast(
            "Something went wrong while saving your billing information. Please try again after some time."
          );
          setIsProcessing(false);
          return;
        }

        setIsProcessing(false);
        updateUser({
          ...user,
          billingInformation: {
            ...billingDetails,
          },
          isBillingInfoSaved: true,
        });

        onContinue();
      })
      .catch((error) => {
        console.error("Error saving billing information", error);
        setIsProcessing(false);
        showErrorToast(
          "Something went wrong while saving your billing information. Please try again after some time."
        );
      });
  };

  if (fetchingBillingInfo) {
    return <div className="spinner" />;
  }

  return (
    <>
      <div className={classNames(BillingInfoStyles.billInfo, {})}>
        {showWhyTrialTooltip && (
          <WhyTrialTooltip
            tooltipLocation={"billingInfo"}
            placement={"bottom"}
          />
        )}
        <div className={BillingInfoStyles.flexWrapper}>
          <Input
            label="Your name"
            name={"yourName"}
            placeholder="Enter your name"
            type={"text"}
            value={billingDetails.yourName}
            hasError={formErrors["yourName"]}
            onChange={(e) => handleInputChange(e)}
          />
          <Input
            label="Company name"
            name={"companyName"}
            placeholder="Enter company name"
            type={"text"}
            value={billingDetails.companyName}
            hasError={formErrors["companyName"]}
            onChange={(e) => handleInputChange(e)}
          />
        </div>
        <Input
          label="Billing address"
          name={"billingAddress"}
          placeholder="Street"
          type={"text"}
          value={billingDetails.billingAddress}
          hasError={formErrors["billingAddress"]}
          onChange={(e) => handleInputChange(e)}
        />
        <div className={BillingInfoStyles.flexWrapper}>
          <Input
            label="City"
            name={"city"}
            placeholder="Enter your city"
            type={"text"}
            value={billingDetails.city}
            hasError={formErrors["city"]}
            onChange={(e) => handleInputChange(e)}
          />
          <Input
            label="State"
            name={"state"}
            placeholder="Enter your state"
            type={"text"}
            value={billingDetails.state}
            hasError={formErrors["state"]}
            onChange={(e) => handleInputChange(e)}
          />
        </div>
        <div className={BillingInfoStyles.flexWrapper}>
          <Input
            label="Zip code"
            name={"zipCode"}
            placeholder="Enter Zip code"
            type={"text"}
            value={billingDetails.zipCode}
            hasError={formErrors["zipCode"]}
            onChange={(e) => handleInputChange(e)}
          />
          <div className={BillingInfoStyles.countryDropdown}>
            <label>Country</label>
            <Select
              name={"countryDropdown"}
              options={countriesList}
              placeholder={"Enter here"}
              onChange={(e) => handleCountrySelectionChange(e?.value || "")}
              value={{
                label: billingDetails.country,
                value: billingDetails.country,
              }}
              menuShouldScrollIntoView
              menuPlacement="top"
              styles={selectBoxStyling}
              tabIndex={isVisible ? 0 : -1}
            />
          </div>
        </div>
        {isCountryIndia(billingDetails.country) ? (
          <div className={BillingInfoStyles.flexWrapper}>
            <Input
              label={
                <>
                  GSTIN <span>(Optional)</span>
                </>
              }
              name={"taxId"}
              placeholder="Enter your GSTIN number"
              type={"text"}
              value={billingDetails.taxId}
              hasError={formErrors["taxId"]}
              onChange={(e) => handleInputChange(e)}
              errorMessage={
                formErrors["taxId"] ? "Invalid. Please enter valid GSTIN" : ""
              }
            />
          </div>
        ) : null}

        <Button
          type={"primary"}
          onClick={() => handleSubmitBillingInfo()}
          isProcessing={isProcessing}
        >
          Continue
        </Button>
      </div>
      <RestrictedRegionModal
        open={showRestrictedRegionModal}
        ipCountry={countryName}
        billingCountry={billingDetails.country}
        onClose={() => setShowRestrictedRegionModal(false)}
      />
    </>
  );
};

export default BillingInformation;
