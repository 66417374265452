import { createSelector } from "@reduxjs/toolkit";

const selectApp = (state) => state.app;

export const selectAppDetails = createSelector(selectApp, (app) => {
  const { page_variant, search_params } = app;
  return {
    page_variant,
    search_params,
  };
})
