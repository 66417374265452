export const countryCodes = [
  {
    value: "+93",
    label: "Afghanistan (+93)",
    code: "AF",
  },
  {
    value: "+355",
    label: "Albania (+355)",
    code: "AL",
  },
  {
    value: "+213",
    label: "Algeria (+213)",
    code: "DZ",
  },
  {
    value: "+1684",
    label: "American Samoa (+1684)",
    code: "AS",
  },
  {
    value: "+376",
    label: "Andorra (+376)",
    code: "AD",
  },
  {
    value: "+244",
    label: "Angola (+244)",
    code: "AO",
  },
  {
    value: "+1264",
    label: "Anguilla (+1264)",
    code: "AI",
  },
  {
    value: "+672",
    label: "Antarctica (+672)",
    code: "AQ",
  },
  {
    value: "+1268",
    label: "Antigua and Barbuda (+1268)",
    code: "AG",
  },
  {
    value: "+54",
    label: "Argentina (+54)",
    code: "AR",
  },
  {
    value: "+374",
    label: "Armenia (+374)",
    code: "AM",
  },
  {
    value: "+297",
    label: "Aruba (+297)",
    code: "AW",
  },
  {
    value: "+61",
    label: "Australia (+61)",
    code: "AU",
  },
  {
    value: "+43",
    label: "Austria (+43)",
    code: "AT",
  },
  {
    value: "+994",
    label: "Azerbaijan (+994)",
    code: "AZ",
  },
  {
    value: "+1242",
    label: "Bahamas (+1242)",
    code: "BS",
  },
  {
    value: "+973",
    label: "Bahrain (+973)",
    code: "BH",
  },
  {
    value: "+880",
    label: "Bangladesh (+880)",
    code: "BD",
  },
  {
    value: "+1246",
    label: "Barbados (+1246)",
    code: "BB",
  },
  {
    value: "+375",
    label: "Belarus (+375)",
    code: "BY",
  },
  {
    value: "+32",
    label: "Belgium (+32)",
    code: "BE",
  },
  {
    value: "+501",
    label: "Belize (+501)",
    code: "BZ",
  },
  {
    value: "+229",
    label: "Benin (+229)",
    code: "BJ",
  },
  {
    value: "+1441",
    label: "Bermuda (+1441)",
    code: "BM",
  },
  {
    value: "+975",
    label: "Bhutan (+975)",
    code: "BT",
  },
  {
    value: "+591",
    label: "Bolivia (+591)",
    code: "BO",
  },
  {
    value: "+387",
    label: "Bosnia and Herzegovina (+387)",
    code: "BA",
  },
  {
    value: "+267",
    label: "Botswana (+267)",
    code: "BW",
  },
  {
    value: "+55",
    label: "Brazil (+55)",
    code: "BR",
  },
  {
    value: "+246",
    label: "British Indian Ocean Territory (+246)",
    code: "IO",
  },
  {
    value: "+1284",
    label: "British Virgin Islands (+1284)",
    code: "VG",
  },
  {
    value: "+673",
    label: "Brunei (+673)",
    code: "BN",
  },
  {
    value: "+359",
    label: "Bulgaria (+359)",
    code: "BG",
  },
  {
    value: "+226",
    label: "Burkina Faso (+226)",
    code: "BF",
  },
  {
    value: "+257",
    label: "Burundi (+257)",
    code: "BI",
  },
  {
    value: "+855",
    label: "Cambodia (+855)",
    code: "KH",
  },
  {
    value: "+237",
    label: "Cameroon (+237)",
    code: "CM",
  },
  {
    value: "+1",
    label: "Canada (+1)",
    code: "CA",
  },
  {
    value: "+238",
    label: "Cape Verde (+238)",
    code: "CV",
  },
  {
    value: "+1345",
    label: "Cayman Islands (+1345)",
    code: "KY",
  },
  {
    value: "+236",
    label: "Central African Republic (+236)",
    code: "CF",
  },
  {
    value: "+235",
    label: "Chad (+235)",
    code: "TD",
  },
  {
    value: "+56",
    label: "Chile (+56)",
    code: "CL",
  },
  {
    value: "+86",
    label: "China (+86)",
    code: "CN",
  },
  {
    value: "+61",
    label: "Christmas Island (+61)",
    code: "CX",
  },
  {
    value: "+61",
    label: "Cocos Islands (+61)",
    code: "CC",
  },
  {
    value: "+57",
    label: "Colombia (+57)",
    code: "CO",
  },
  {
    value: "+269",
    label: "Comoros (+269)",
    code: "KM",
  },
  {
    value: "+682",
    label: "Cook Islands (+682)",
    code: "CK",
  },
  {
    value: "+506",
    label: "Costa Rica (+506)",
    code: "CR",
  },
  {
    value: "+385",
    label: "Croatia (+385)",
    code: "HR",
  },
  {
    value: "+53",
    label: "Cuba (+53)",
    code: "CU",
  },
  {
    value: "+599",
    label: "Curacao (+599)",
    code: "CB",
  },
  {
    value: "+357",
    label: "Cyprus (+357)",
    code: "CY",
  },
  {
    value: "+420",
    label: "Czech Republic (+420)",
    code: "CZ",
  },
  {
    value: "+243",
    label: "Democratic Republic of the Congo (+243)",
    code: "CD",
  },
  {
    value: "+45",
    label: "Denmark (+45)",
    code: "DK",
  },
  {
    value: "+253",
    label: "Djibouti (+253)",
    code: "DJ",
  },
  {
    value: "+1767",
    label: "Dominica (+1767)",
    code: "DM",
  },
  {
    value: "+1809, 1829, 1849",
    label: "Dominican Republic (+1809, 1829, 1849)",
    code: "DO",
  },
  {
    value: "+670",
    label: "East Timor (+670)",
    code: "TL",
  },
  {
    value: "+593",
    label: "Ecuador (+593)",
    code: "EC",
  },
  {
    value: "+20",
    label: "Egypt (+20)",
    code: "EG",
  },
  {
    value: "+503",
    label: "El Salvador (+503)",
    code: "SV",
  },
  {
    value: "+240",
    label: "Equatorial Guinea (+240)",
    code: "GQ",
  },
  {
    value: "+291",
    label: "Eritrea (+291)",
    code: "ER",
  },
  {
    value: "+372",
    label: "Estonia (+372)",
    code: "EE",
  },
  {
    value: "+251",
    label: "Ethiopia (+251)",
    code: "ET",
  },
  {
    value: "+500",
    label: "Falkland Islands (+500)",
    code: "FK",
  },
  {
    value: "+298",
    label: "Faroe Islands (+298)",
    code: "FO",
  },
  {
    value: "+679",
    label: "Fiji (+679)",
    code: "FJ",
  },
  {
    value: "+358",
    label: "Finland (+358)",
    code: "FI",
  },
  {
    value: "+33",
    label: "France (+33)",
    code: "FR",
  },
  {
    value: "+689",
    label: "French Polynesia (+689)",
    code: "PF",
  },
  {
    value: "+241",
    label: "Gabon (+241)",
    code: "GA",
  },
  {
    value: "+220",
    label: "Gambia (+220)",
    code: "GM",
  },
  {
    value: "+995",
    label: "Georgia (+995)",
    code: "GE",
  },
  {
    value: "+49",
    label: "Germany (+49)",
    code: "DE",
  },
  {
    value: "+233",
    label: "Ghana (+233)",
    code: "GH",
  },
  {
    value: "+350",
    label: "Gibraltar (+350)",
    code: "GI",
  },
  {
    value: "+30",
    label: "Greece (+30)",
    code: "GR",
  },
  {
    value: "+299",
    label: "Greenland (+299)",
    code: "GL",
  },
  {
    value: "+1473",
    label: "Grenada (+1473)",
    code: "GD",
  },
  {
    value: "+1671",
    label: "Guam (+1671)",
    code: "GU",
  },
  {
    value: "+502",
    label: "Guatemala (+502)",
    code: "GT",
  },
  {
    value: "+441481",
    label: "Guernsey (+441481)",
    code: "GG",
  },
  {
    value: "+224",
    label: "Guinea (+224)",
    code: "GN",
  },
  {
    value: "+245",
    label: "Guinea-Bissau (+245)",
    code: "GW",
  },
  {
    value: "+592",
    label: "Guyana (+592)",
    code: "GY",
  },
  {
    value: "+509",
    label: "Haiti (+509)",
    code: "HT",
  },
  {
    value: "+504",
    label: "Honduras (+504)",
    code: "HN",
  },
  {
    value: "+852",
    label: "Hong Kong (+852)",
    code: "HK",
  },
  {
    value: "+36",
    label: "Hungary (+36)",
    code: "HU",
  },
  {
    value: "+354",
    label: "Iceland (+354)",
    code: "IS",
  },
  {
    value: "+91",
    label: "India (+91)",
    code: "IN",
  },
  {
    value: "+62",
    label: "Indonesia (+62)",
    code: "ID",
  },
  {
    value: "+98",
    label: "Iran (+98)",
    code: "IR",
  },
  {
    value: "+964",
    label: "Iraq (+964)",
    code: "IQ",
  },
  {
    value: "+353",
    label: "Ireland (+353)",
    code: "IE",
  },
  {
    value: "+441624",
    label: "Isle of Man (+441624)",
    code: "IM",
  },
  {
    value: "+972",
    label: "Israel (+972)",
    code: "IL",
  },
  {
    value: "+39",
    label: "Italy (+39)",
    code: "IT",
  },
  {
    value: "+225",
    label: "Ivory Coast (+225)",
    code: "IV",
  },
  {
    value: "+1876",
    label: "Jamaica (+1876)",
    code: "JM",
  },
  {
    value: "+81",
    label: "Japan (+81)",
    code: "JP",
  },
  {
    value: "+441534",
    label: "Jersey (+441534)",
    code: "JE",
  },
  {
    value: "+962",
    label: "Jordan (+962)",
    code: "JO",
  },
  {
    value: "+7",
    label: "Kazakhstan (+7)",
    code: "KZ",
  },
  {
    value: "+254",
    label: "Kenya (+254)",
    code: "KE",
  },
  {
    value: "+686",
    label: "Kiribati (+686)",
    code: "KI",
  },
  {
    value: "+383",
    label: "Kosovo (+383)",
    code: "XK",
  },
  {
    value: "+965",
    label: "Kuwait (+965)",
    code: "KW",
  },
  {
    value: "+996",
    label: "Kyrgyzstan (+996)",
    code: "KG",
  },
  {
    value: "+856",
    label: "Laos (+856)",
    code: "LA",
  },
  {
    value: "+371",
    label: "Latvia (+371)",
    code: "LV",
  },
  {
    value: "+961",
    label: "Lebanon (+961)",
    code: "LB",
  },
  {
    value: "+266",
    label: "Lesotho (+266)",
    code: "LS",
  },
  {
    value: "+231",
    label: "Liberia (+231)",
    code: "LR",
  },
  {
    value: "+218",
    label: "Libya (+218)",
    code: "LY",
  },
  {
    value: "+423",
    label: "Liechtenstein (+423)",
    code: "LI",
  },
  {
    value: "+370",
    label: "Lithuania (+370)",
    code: "LT",
  },
  {
    value: "+352",
    label: "Luxembourg (+352)",
    code: "LU",
  },
  {
    value: "+853",
    label: "Macau (+853)",
    code: "MO",
  },
  {
    value: "+389",
    label: "Macedonia (+389)",
    code: "MK",
  },
  {
    value: "+261",
    label: "Madagascar (+261)",
    code: "MG",
  },
  {
    value: "+265",
    label: "Malawi (+265)",
    code: "MW",
  },
  {
    value: "+60",
    label: "Malaysia (+60)",
    code: "MY",
  },
  {
    value: "+960",
    label: "Maldives (+960)",
    code: "MV",
  },
  {
    value: "+223",
    label: "Mali (+223)",
    code: "ML",
  },
  {
    value: "+356",
    label: "Malta (+356)",
    code: "MT",
  },
  {
    value: "+692",
    label: "Marshall Islands (+692)",
    code: "MH",
  },
  {
    value: "+222",
    label: "Mauritania (+222)",
    code: "MR",
  },
  {
    value: "+230",
    label: "Mauritius (+230)",
    code: "MU",
  },
  {
    value: "+262",
    label: "Mayotte (+262)",
    code: "YT",
  },
  {
    value: "+52",
    label: "Mexico (+52)",
    code: "MX",
  },
  {
    value: "+691",
    label: "Micronesia (+691)",
    code: "FM",
  },
  {
    value: "+373",
    label: "Moldova (+373)",
    code: "MD",
  },
  {
    value: "+377",
    label: "Monaco (+377)",
    code: "MC",
  },
  {
    value: "+976",
    label: "Mongolia (+976)",
    code: "MN",
  },
  {
    value: "+382",
    label: "Montenegro (+382)",
    code: "ME",
  },
  {
    value: "+1664",
    label: "Montserrat (+1664)",
    code: "MS",
  },
  {
    value: "+212",
    label: "Morocco (+212)",
    code: "MA",
  },
  {
    value: "+258",
    label: "Mozambique (+258)",
    code: "MZ",
  },
  {
    value: "+95",
    label: "Myanmar (+95)",
    code: "MM",
  },
  {
    value: "+264",
    label: "Namibia (+264)",
    code: "NA",
  },
  {
    value: "+674",
    label: "Nauru (+674)",
    code: "NR",
  },
  {
    value: "+977",
    label: "Nepal (+977)",
    code: "NP",
  },
  {
    value: "+31",
    label: "Netherlands (+31)",
    code: "NL",
  },
  {
    value: "+599",
    label: "Netherlands Antilles (+599)",
    code: "AN",
  },
  {
    value: "+687",
    label: "New Caledonia (+687)",
    code: "NC",
  },
  {
    value: "+64",
    label: "New Zealand (+64)",
    code: "NZ",
  },
  {
    value: "+505",
    label: "Nicaragua (+505)",
    code: "NI",
  },
  {
    value: "+227",
    label: "Niger (+227)",
    code: "NE",
  },
  {
    value: "+234",
    label: "Nigeria (+234)",
    code: "NG",
  },
  {
    value: "+683",
    label: "Niue (+683)",
    code: "NU",
  },
  {
    value: "+850",
    label: "North Korea (+850)",
    code: "KO",
  },
  {
    value: "+1670",
    label: "Northern Mariana Islands (+1670)",
    code: "MP",
  },
  {
    value: "+47",
    label: "Norway (+47)",
    code: "NO",
  },
  {
    value: "+968",
    label: "Oman (+968)",
    code: "OM",
  },
  {
    value: "+92",
    label: "Pakistan (+92)",
    code: "PK",
  },
  {
    value: "+680",
    label: "Palau (+680)",
    code: "PW",
  },
  {
    value: "+970",
    label: "Palestine (+970)",
    code: "PS",
  },
  {
    value: "+507",
    label: "Panama (+507)",
    code: "PA",
  },
  {
    value: "+675",
    label: "Papua New Guinea (+675)",
    code: "PG",
  },
  {
    value: "+595",
    label: "Paraguay (+595)",
    code: "PY",
  },
  {
    value: "+51",
    label: "Peru (+51)",
    code: "PE",
  },
  {
    value: "+63",
    label: "Philippines (+63)",
    code: "PH",
  },
  {
    value: "+64",
    label: "Pitcairn (+64)",
    code: "PN",
  },
  {
    value: "+48",
    label: "Poland (+48)",
    code: "PL",
  },
  {
    value: "+351",
    label: "Portugal (+351)",
    code: "PT",
  },
  {
    value: "+1787, 1939",
    label: "Puerto Rico (+1787, 1939)",
    code: "PR",
  },
  {
    value: "+974",
    label: "Qatar (+974)",
    code: "QA",
  },
  {
    value: "+242",
    label: "Republic of the Congo (+242)",
    code: "CG",
  },
  {
    value: "+262",
    label: "Reunion (+262)",
    code: "RE",
  },
  {
    value: "+40",
    label: "Romania (+40)",
    code: "RO",
  },
  {
    value: "+7",
    label: "Russia (+7)",
    code: "RU",
  },
  {
    value: "+250",
    label: "Rwanda (+250)",
    code: "RW",
  },
  {
    value: "+590",
    label: "Saint Barthelemy (+590)",
    code: "BL",
  },
  {
    value: "+290",
    label: "Saint Helena (+290)",
    code: "SH",
  },
  {
    value: "+1869",
    label: "Saint Kitts and Nevis (+1869)",
    code: "KN",
  },
  {
    value: "+1758",
    label: "Saint Lucia (+1758)",
    code: "LC",
  },
  {
    value: "+590",
    label: "Saint Martin (+590)",
    code: "MF",
  },
  {
    value: "+508",
    label: "Saint Pierre and Miquelon (+508)",
    code: "PM",
  },
  {
    value: "+1784",
    label: "Saint Vincent and the Grenadines (+1784)",
    code: "VC",
  },
  {
    value: "+685",
    label: "Samoa (+685)",
    code: "WS",
  },
  {
    value: "+378",
    label: "San Marino (+378)",
    code: "SM",
  },
  {
    value: "+239",
    label: "Sao Tome and Principe (+239)",
    code: "ST",
  },
  {
    value: "+966",
    label: "Saudi Arabia (+966)",
    code: "SA",
  },
  {
    value: "+221",
    label: "Senegal (+221)",
    code: "SN",
  },
  {
    value: "+381",
    label: "Serbia (+381)",
    code: "RS",
  },
  {
    value: "+248",
    label: "Seychelles (+248)",
    code: "SC",
  },
  {
    value: "+232",
    label: "Sierra Leone (+232)",
    code: "SL",
  },
  {
    value: "+65",
    label: "Singapore (+65)",
    code: "SG",
  },
  {
    value: "+1721",
    label: "Sint Maarten (+1721)",
    code: "SW",
  },
  {
    value: "+421",
    label: "Slovakia (+421)",
    code: "SK",
  },
  {
    value: "+386",
    label: "Slovenia (+386)",
    code: "SI",
  },
  {
    value: "+677",
    label: "Solomon Islands (+677)",
    code: "SB",
  },
  {
    value: "+252",
    label: "Somalia (+252)",
    code: "SO",
  },
  {
    value: "+27",
    label: "South Africa (+27)",
    code: "ZA",
  },
  {
    value: "+82",
    label: "South Korea (+82)",
    code: "KO",
  },
  {
    value: "+211",
    label: "South Sudan (+211)",
    code: "SS",
  },
  {
    value: "+34",
    label: "Spain (+34)",
    code: "ES",
  },
  {
    value: "+94",
    label: "Sri Lanka (+94)",
    code: "LK",
  },
  {
    value: "+249",
    label: "Sudan (+249)",
    code: "SD",
  },
  {
    value: "+597",
    label: "Suriname (+597)",
    code: "SR",
  },
  {
    value: "+47",
    label: "Svalbard and Jan Mayen (+47)",
    code: "SJ",
  },
  {
    value: "+268",
    label: "Swaziland (+268)",
    code: "SZ",
  },
  {
    value: "+46",
    label: "Sweden (+46)",
    code: "SE",
  },
  {
    value: "+41",
    label: "Switzerland (+41)",
    code: "CH",
  },
  {
    value: "+963",
    label: "Syria (+963)",
    code: "SY",
  },
  {
    value: "+886",
    label: "Taiwan (+886)",
    code: "TW",
  },
  {
    value: "+992",
    label: "Tajikistan (+992)",
    code: "TJ",
  },
  {
    value: "+255",
    label: "Tanzania (+255)",
    code: "TZ",
  },
  {
    value: "+66",
    label: "Thailand (+66)",
    code: "TH",
  },
  {
    value: "+228",
    label: "Togo (+228)",
    code: "TG",
  },
  {
    value: "+690",
    label: "Tokelau (+690)",
    code: "TK",
  },
  {
    value: "+676",
    label: "Tonga (+676)",
    code: "TO",
  },
  {
    value: "+1868",
    label: "Trinidad and Tobago (+1868)",
    code: "TT",
  },
  {
    value: "+216",
    label: "Tunisia (+216)",
    code: "TN",
  },
  {
    value: "+90",
    label: "Turkey (+90)",
    code: "TR",
  },
  {
    value: "+993",
    label: "Turkmenistan (+993)",
    code: "TM",
  },
  {
    value: "+1649",
    label: "Turks and Caicos Islands (+1649)",
    code: "TC",
  },
  {
    value: "+688",
    label: "Tuvalu (+688)",
    code: "TV",
  },
  {
    value: "+1340",
    label: "U.S. Virgin Islands (+1340)",
    code: "VI",
  },
  {
    value: "+256",
    label: "Uganda (+256)",
    code: "UG",
  },
  {
    value: "+380",
    label: "Ukraine (+380)",
    code: "UA",
  },
  {
    value: "+971",
    label: "United Arab Emirates (+971)",
    code: "AE",
  },
  {
    value: "+44",
    label: "United Kingdom (+44)",
    code: "GB",
  },
  {
    value: "+1",
    label: "United States (+1)",
    code: "US",
  },
  {
    value: "+598",
    label: "Uruguay (+598)",
    code: "UY",
  },
  {
    value: "+998",
    label: "Uzbekistan (+998)",
    code: "UZ",
  },
  {
    value: "+678",
    label: "Vanuatu (+678)",
    code: "VU",
  },
  {
    value: "+379",
    label: "Vatican (+379)",
    code: "VA",
  },
  {
    value: "+58",
    label: "Venezuela (+58)",
    code: "VE",
  },
  {
    value: "+84",
    label: "Vietnam (+84)",
    code: "VN",
  },
  {
    value: "+681",
    label: "Wallis and Futuna (+681)",
    code: "WF",
  },
  {
    value: "+212",
    label: "Western Sahara (+212)",
    code: "EH",
  },
  {
    value: "+967",
    label: "Yemen (+967)",
    code: "YE",
  },
  {
    value: "+260",
    label: "Zambia (+260)",
    code: "ZM",
  },
  {
    value: "+263",
    label: "Zimbabwe (+263)",
    code: "ZW",
  },
];

export const countriesList = [
  {
    value: "Afghanistan",
    label: "Afghanistan",
    code: "AF",
  },
  {
    value: "Albania",
    label: "Albania",
    code: "AL",
  },
  {
    value: "Algeria",
    label: "Algeria",
    code: "DZ",
  },
  {
    value: "American Samoa",
    label: "American Samoa",
    code: "AS",
  },
  {
    value: "Andorra",
    label: "Andorra",
    code: "AD",
  },
  {
    value: "Angola",
    label: "Angola",
    code: "AO",
  },
  {
    value: "Anguilla",
    label: "Anguilla",
    code: "AI",
  },
  {
    value: "Antarctica",
    label: "Antarctica",
    code: "AQ",
  },
  {
    value: "Antigua and Barbuda",
    label: "Antigua and Barbuda",
    code: "AG",
  },
  {
    value: "Argentina",
    label: "Argentina",
    code: "AR",
  },
  {
    value: "Armenia",
    label: "Armenia",
    code: "AM",
  },
  {
    value: "Aruba",
    label: "Aruba",
    code: "AW",
  },
  {
    value: "Australia",
    label: "Australia",
    code: "AU",
  },
  {
    value: "Austria",
    label: "Austria",
    code: "AT",
  },
  {
    value: "Azerbaijan",
    label: "Azerbaijan",
    code: "AZ",
  },
  {
    value: "Bahamas",
    label: "Bahamas",
    code: "BS",
  },
  {
    value: "Bahrain",
    label: "Bahrain",
    code: "BH",
  },
  {
    value: "Bangladesh",
    label: "Bangladesh",
    code: "BD",
  },
  {
    value: "Barbados",
    label: "Barbados",
    code: "BB",
  },
  {
    value: "Belarus",
    label: "Belarus",
    code: "BY",
  },
  {
    value: "Belgium",
    label: "Belgium",
    code: "BE",
  },
  {
    value: "Belize",
    label: "Belize",
    code: "BZ",
  },
  {
    value: "Benin",
    label: "Benin",
    code: "BJ",
  },
  {
    value: "Bermuda",
    label: "Bermuda",
    code: "BM",
  },
  {
    value: "Bhutan",
    label: "Bhutan",
    code: "BT",
  },
  {
    value: "Bolivia",
    label: "Bolivia",
    code: "BO",
  },
  {
    value: "Bosnia and Herzegovina",
    label: "Bosnia and Herzegovina",
    code: "BA",
  },
  {
    value: "Botswana",
    label: "Botswana",
    code: "BW",
  },
  {
    value: "Brazil",
    label: "Brazil",
    code: "BR",
  },
  {
    value: "British Indian Ocean Territory",
    label: "British Indian Ocean Territory",
    code: "IO",
  },
  {
    value: "British Virgin Islands",
    label: "British Virgin Islands",
    code: "VG",
  },
  {
    value: "Brunei",
    label: "Brunei",
    code: "BN",
  },
  {
    value: "Bulgaria",
    label: "Bulgaria",
    code: "BG",
  },
  {
    value: "Burkina Faso",
    label: "Burkina Faso",
    code: "BF",
  },
  {
    value: "Burundi",
    label: "Burundi",
    code: "BI",
  },
  {
    value: "Cambodia",
    label: "Cambodia",
    code: "KH",
  },
  {
    value: "Cameroon",
    label: "Cameroon",
    code: "CM",
  },
  {
    value: "Canada",
    label: "Canada",
    code: "CA",
  },
  {
    value: "Cape Verde",
    label: "Cape Verde",
    code: "CV",
  },
  {
    value: "Cayman Islands",
    label: "Cayman Islands",
    code: "KY",
  },
  {
    value: "Central African Republic",
    label: "Central African Republic",
    code: "CF",
  },
  {
    value: "Chad",
    label: "Chad",
    code: "TD",
  },
  {
    value: "Chile",
    label: "Chile",
    code: "CL",
  },
  {
    value: "China",
    label: "China",
    code: "CN",
  },
  {
    value: "Christmas Island",
    label: "Christmas Island",
    code: "CX",
  },
  {
    value: "Cocos Islands",
    label: "Cocos Islands",
    code: "CC",
  },
  {
    value: "Colombia",
    label: "Colombia",
    code: "CO",
  },
  {
    value: "Comoros",
    label: "Comoros",
    code: "KM",
  },
  {
    value: "Cook Islands",
    label: "Cook Islands",
    code: "CK",
  },
  {
    value: "Costa Rica",
    label: "Costa Rica",
    code: "CR",
  },
  {
    value: "Croatia",
    label: "Croatia",
    code: "HR",
  },
  {
    value: "Cuba",
    label: "Cuba",
    code: "CU",
  },
  {
    value: "Curacao",
    label: "Curacao",
    code: "CB",
  },
  {
    value: "Cyprus",
    label: "Cyprus",
    code: "CY",
  },
  {
    value: "Czech Republic",
    label: "Czech Republic",
    code: "CZ",
  },
  {
    value: "Democratic Republic of the Congo",
    label: "Democratic Republic of the Congo",
    code: "CD",
  },
  {
    value: "Denmark",
    label: "Denmark",
    code: "DK",
  },
  {
    value: "Djibouti",
    label: "Djibouti",
    code: "DJ",
  },
  {
    value: "Dominica",
    label: "Dominica",
    code: "DM",
  },
  {
    value: "Dominican Republic 1829, 1849)",
    label: "Dominican Republic 1829, 1849)",
    code: "DO",
  },
  {
    value: "East Timor",
    label: "East Timor",
    code: "TL",
  },
  {
    value: "Ecuador",
    label: "Ecuador",
    code: "EC",
  },
  {
    value: "Egypt",
    label: "Egypt",
    code: "EG",
  },
  {
    value: "El Salvador",
    label: "El Salvador",
    code: "SV",
  },
  {
    value: "Equatorial Guinea",
    label: "Equatorial Guinea",
    code: "GQ",
  },
  {
    value: "Eritrea",
    label: "Eritrea",
    code: "ER",
  },
  {
    value: "Estonia",
    label: "Estonia",
    code: "EE",
  },
  {
    value: "Ethiopia",
    label: "Ethiopia",
    code: "ET",
  },
  {
    value: "Falkland Islands",
    label: "Falkland Islands",
    code: "FK",
  },
  {
    value: "Faroe Islands",
    label: "Faroe Islands",
    code: "FO",
  },
  {
    value: "Fiji",
    label: "Fiji",
    code: "FJ",
  },
  {
    value: "Finland",
    label: "Finland",
    code: "FI",
  },
  {
    value: "France",
    label: "France",
    code: "FR",
  },
  {
    value: "French Polynesia",
    label: "French Polynesia",
    code: "PF",
  },
  {
    value: "Gabon",
    label: "Gabon",
    code: "GA",
  },
  {
    value: "Gambia",
    label: "Gambia",
    code: "GM",
  },
  {
    value: "Georgia",
    label: "Georgia",
    code: "GE",
  },
  {
    value: "Germany",
    label: "Germany",
    code: "DE",
  },
  {
    value: "Ghana",
    label: "Ghana",
    code: "GH",
  },
  {
    value: "Gibraltar",
    label: "Gibraltar",
    code: "GI",
  },
  {
    value: "Greece",
    label: "Greece",
    code: "GR",
  },
  {
    value: "Greenland",
    label: "Greenland",
    code: "GL",
  },
  {
    value: "Grenada",
    label: "Grenada",
    code: "GD",
  },
  {
    value: "Guam",
    label: "Guam",
    code: "GU",
  },
  {
    value: "Guatemala",
    label: "Guatemala",
    code: "GT",
  },
  {
    value: "Guernsey",
    label: "Guernsey",
    code: "GG",
  },
  {
    value: "Guinea",
    label: "Guinea",
    code: "GN",
  },
  {
    value: "Guinea-Bissau",
    label: "Guinea-Bissau",
    code: "GW",
  },
  {
    value: "Guyana",
    label: "Guyana",
    code: "GY",
  },
  {
    value: "Haiti",
    label: "Haiti",
    code: "HT",
  },
  {
    value: "Honduras",
    label: "Honduras",
    code: "HN",
  },
  {
    value: "Hong Kong",
    label: "Hong Kong",
    code: "HK",
  },
  {
    value: "Hungary",
    label: "Hungary",
    code: "HU",
  },
  {
    value: "Iceland",
    label: "Iceland",
    code: "IS",
  },
  {
    value: "India",
    label: "India",
    code: "IN",
  },
  {
    value: "Indonesia",
    label: "Indonesia",
    code: "ID",
  },
  {
    value: "Iran",
    label: "Iran",
    code: "IR",
  },
  {
    value: "Iraq",
    label: "Iraq",
    code: "IQ",
  },
  {
    value: "Ireland",
    label: "Ireland",
    code: "IE",
  },
  {
    value: "Isle of Man",
    label: "Isle of Man",
    code: "IM",
  },
  {
    value: "Israel",
    label: "Israel",
    code: "IL",
  },
  {
    value: "Italy",
    label: "Italy",
    code: "IT",
  },
  {
    value: "Ivory Coast",
    label: "Ivory Coast",
    code: "IV",
  },
  {
    value: "Jamaica",
    label: "Jamaica",
    code: "JM",
  },
  {
    value: "Japan",
    label: "Japan",
    code: "JP",
  },
  {
    value: "Jersey",
    label: "Jersey",
    code: "JE",
  },
  {
    value: "Jordan",
    label: "Jordan",
    code: "JO",
  },
  {
    value: "Kazakhstan",
    label: "Kazakhstan",
    code: "KZ",
  },
  {
    value: "Kenya",
    label: "Kenya",
    code: "KE",
  },
  {
    value: "Kiribati",
    label: "Kiribati",
    code: "KI",
  },
  {
    value: "Kosovo",
    label: "Kosovo",
    code: "XK",
  },
  {
    value: "Kuwait",
    label: "Kuwait",
    code: "KW",
  },
  {
    value: "Kyrgyzstan",
    label: "Kyrgyzstan",
    code: "KG",
  },
  {
    value: "Laos",
    label: "Laos",
    code: "LA",
  },
  {
    value: "Latvia",
    label: "Latvia",
    code: "LV",
  },
  {
    value: "Lebanon",
    label: "Lebanon",
    code: "LB",
  },
  {
    value: "Lesotho",
    label: "Lesotho",
    code: "LS",
  },
  {
    value: "Liberia",
    label: "Liberia",
    code: "LR",
  },
  {
    value: "Libya",
    label: "Libya",
    code: "LY",
  },
  {
    value: "Liechtenstein",
    label: "Liechtenstein",
    code: "LI",
  },
  {
    value: "Lithuania",
    label: "Lithuania",
    code: "LT",
  },
  {
    value: "Luxembourg",
    label: "Luxembourg",
    code: "LU",
  },
  {
    value: "Macau",
    label: "Macau",
    code: "MO",
  },
  {
    value: "Macedonia",
    label: "Macedonia",
    code: "MK",
  },
  {
    value: "Madagascar",
    label: "Madagascar",
    code: "MG",
  },
  {
    value: "Malawi",
    label: "Malawi",
    code: "MW",
  },
  {
    value: "Malaysia",
    label: "Malaysia",
    code: "MY",
  },
  {
    value: "Maldives",
    label: "Maldives",
    code: "MV",
  },
  {
    value: "Mali",
    label: "Mali",
    code: "ML",
  },
  {
    value: "Malta",
    label: "Malta",
    code: "MT",
  },
  {
    value: "Marshall Islands",
    label: "Marshall Islands",
    code: "MH",
  },
  {
    value: "Mauritania",
    label: "Mauritania",
    code: "MR",
  },
  {
    value: "Mauritius",
    label: "Mauritius",
    code: "MU",
  },
  {
    value: "Mayotte",
    label: "Mayotte",
    code: "YT",
  },
  {
    value: "Mexico",
    label: "Mexico",
    code: "MX",
  },
  {
    value: "Micronesia",
    label: "Micronesia",
    code: "FM",
  },
  {
    value: "Moldova",
    label: "Moldova",
    code: "MD",
  },
  {
    value: "Monaco",
    label: "Monaco",
    code: "MC",
  },
  {
    value: "Mongolia",
    label: "Mongolia",
    code: "MN",
  },
  {
    value: "Montenegro",
    label: "Montenegro",
    code: "ME",
  },
  {
    value: "Montserrat",
    label: "Montserrat",
    code: "MS",
  },
  {
    value: "Morocco",
    label: "Morocco",
    code: "MA",
  },
  {
    value: "Mozambique",
    label: "Mozambique",
    code: "MZ",
  },
  {
    value: "Myanmar",
    label: "Myanmar",
    code: "MM",
  },
  {
    value: "Namibia",
    label: "Namibia",
    code: "NA",
  },
  {
    value: "Nauru",
    label: "Nauru",
    code: "NR",
  },
  {
    value: "Nepal",
    label: "Nepal",
    code: "NP",
  },
  {
    value: "Netherlands",
    label: "Netherlands",
    code: "NL",
  },
  {
    value: "Netherlands Antilles",
    label: "Netherlands Antilles",
    code: "AN",
  },
  {
    value: "New Caledonia",
    label: "New Caledonia",
    code: "NC",
  },
  {
    value: "New Zealand",
    label: "New Zealand",
    code: "NZ",
  },
  {
    value: "Nicaragua",
    label: "Nicaragua",
    code: "NI",
  },
  {
    value: "Niger",
    label: "Niger",
    code: "NE",
  },
  {
    value: "Nigeria",
    label: "Nigeria",
    code: "NG",
  },
  {
    value: "Niue",
    label: "Niue",
    code: "NU",
  },
  {
    value: "North Korea",
    label: "North Korea",
    code: "KP",
  },
  {
    value: "Northern Mariana Islands",
    label: "Northern Mariana Islands",
    code: "MP",
  },
  {
    value: "Norway",
    label: "Norway",
    code: "NO",
  },
  {
    value: "Oman",
    label: "Oman",
    code: "OM",
  },
  {
    value: "Pakistan",
    label: "Pakistan",
    code: "PK",
  },
  {
    value: "Palau",
    label: "Palau",
    code: "PW",
  },
  {
    value: "Palestine",
    label: "Palestine",
    code: "PS",
  },
  {
    value: "Panama",
    label: "Panama",
    code: "PA",
  },
  {
    value: "Papua New Guinea",
    label: "Papua New Guinea",
    code: "PG",
  },
  {
    value: "Paraguay",
    label: "Paraguay",
    code: "PY",
  },
  {
    value: "Peru",
    label: "Peru",
    code: "PE",
  },
  {
    value: "Philippines",
    label: "Philippines",
    code: "PH",
  },
  {
    value: "Pitcairn",
    label: "Pitcairn",
    code: "PN",
  },
  {
    value: "Poland",
    label: "Poland",
    code: "PL",
  },
  {
    value: "Portugal",
    label: "Portugal",
    code: "PT",
  },
  {
    value: "Puerto Rico 1939)",
    label: "Puerto Rico 1939)",
    code: "PR",
  },
  {
    value: "Qatar",
    label: "Qatar",
    code: "QA",
  },
  {
    value: "Republic of the Congo",
    label: "Republic of the Congo",
    code: "CG",
  },
  {
    value: "Reunion",
    label: "Reunion",
    code: "RE",
  },
  {
    value: "Romania",
    label: "Romania",
    code: "RO",
  },
  {
    value: "Russia",
    label: "Russia",
    code: "RU",
  },
  {
    value: "Rwanda",
    label: "Rwanda",
    code: "RW",
  },
  {
    value: "Saint Barthelemy",
    label: "Saint Barthelemy",
    code: "BL",
  },
  {
    value: "Saint Helena",
    label: "Saint Helena",
    code: "SH",
  },
  {
    value: "Saint Kitts and Nevis",
    label: "Saint Kitts and Nevis",
    code: "KN",
  },
  {
    value: "Saint Lucia",
    label: "Saint Lucia",
    code: "LC",
  },
  {
    value: "Saint Martin",
    label: "Saint Martin",
    code: "MF",
  },
  {
    value: "Saint Pierre and Miquelon",
    label: "Saint Pierre and Miquelon",
    code: "PM",
  },
  {
    value: "Saint Vincent and the Grenadines",
    label: "Saint Vincent and the Grenadines",
    code: "VC",
  },
  {
    value: "Samoa",
    label: "Samoa",
    code: "WS",
  },
  {
    value: "San Marino",
    label: "San Marino",
    code: "SM",
  },
  {
    value: "Sao Tome and Principe",
    label: "Sao Tome and Principe",
    code: "ST",
  },
  {
    value: "Saudi Arabia",
    label: "Saudi Arabia",
    code: "SA",
  },
  {
    value: "Senegal",
    label: "Senegal",
    code: "SN",
  },
  {
    value: "Serbia",
    label: "Serbia",
    code: "RS",
  },
  {
    value: "Seychelles",
    label: "Seychelles",
    code: "SC",
  },
  {
    value: "Sierra Leone",
    label: "Sierra Leone",
    code: "SL",
  },
  {
    value: "Singapore",
    label: "Singapore",
    code: "SG",
  },
  {
    value: "Sint Maarten",
    label: "Sint Maarten",
    code: "SW",
  },
  {
    value: "Slovakia",
    label: "Slovakia",
    code: "SK",
  },
  {
    value: "Slovenia",
    label: "Slovenia",
    code: "SI",
  },
  {
    value: "Solomon Islands",
    label: "Solomon Islands",
    code: "SB",
  },
  {
    value: "Somalia",
    label: "Somalia",
    code: "SO",
  },
  {
    value: "South Africa",
    label: "South Africa",
    code: "ZA",
  },
  {
    value: "South Korea",
    label: "South Korea",
    code: "KO",
  },
  {
    value: "South Sudan",
    label: "South Sudan",
    code: "SS",
  },
  {
    value: "Spain",
    label: "Spain",
    code: "ES",
  },
  {
    value: "Sri Lanka",
    label: "Sri Lanka",
    code: "LK",
  },
  {
    value: "Sudan",
    label: "Sudan",
    code: "SD",
  },
  {
    value: "Suriname",
    label: "Suriname",
    code: "SR",
  },
  {
    value: "Svalbard and Jan Mayen",
    label: "Svalbard and Jan Mayen",
    code: "SJ",
  },
  {
    value: "Swaziland",
    label: "Swaziland",
    code: "SZ",
  },
  {
    value: "Sweden",
    label: "Sweden",
    code: "SE",
  },
  {
    value: "Switzerland",
    label: "Switzerland",
    code: "CH",
  },
  {
    value: "Syria",
    label: "Syria",
    code: "SY",
  },
  {
    value: "Taiwan",
    label: "Taiwan",
    code: "TW",
  },
  {
    value: "Tajikistan",
    label: "Tajikistan",
    code: "TJ",
  },
  {
    value: "Tanzania",
    label: "Tanzania",
    code: "TZ",
  },
  {
    value: "Thailand",
    label: "Thailand",
    code: "TH",
  },
  {
    value: "Togo",
    label: "Togo",
    code: "TG",
  },
  {
    value: "Tokelau",
    label: "Tokelau",
    code: "TK",
  },
  {
    value: "Tonga",
    label: "Tonga",
    code: "TO",
  },
  {
    value: "Trinidad and Tobago",
    label: "Trinidad and Tobago",
    code: "TT",
  },
  {
    value: "Tunisia",
    label: "Tunisia",
    code: "TN",
  },
  {
    value: "Turkey",
    label: "Turkey",
    code: "TR",
  },
  {
    value: "Turkmenistan",
    label: "Turkmenistan",
    code: "TM",
  },
  {
    value: "Turks and Caicos Islands",
    label: "Turks and Caicos Islands",
    code: "TC",
  },
  {
    value: "Tuvalu",
    label: "Tuvalu",
    code: "TV",
  },
  {
    value: "U.S. Virgin Islands",
    label: "U.S. Virgin Islands",
    code: "VI",
  },
  {
    value: "Uganda",
    label: "Uganda",
    code: "UG",
  },
  {
    value: "Ukraine",
    label: "Ukraine",
    code: "UA",
  },
  {
    value: "United Arab Emirates",
    label: "United Arab Emirates",
    code: "AE",
  },
  {
    value: "United Kingdom",
    label: "United Kingdom",
    code: "GB",
  },
  {
    value: "United States",
    label: "United States",
    code: "US",
  },
  {
    value: "Uruguay",
    label: "Uruguay",
    code: "UY",
  },
  {
    value: "Uzbekistan",
    label: "Uzbekistan",
    code: "UZ",
  },
  {
    value: "Vanuatu",
    label: "Vanuatu",
    code: "VU",
  },
  {
    value: "Vatican",
    label: "Vatican",
    code: "VA",
  },
  {
    value: "Venezuela",
    label: "Venezuela",
    code: "VE",
  },
  {
    value: "Vietnam",
    label: "Vietnam",
    code: "VN",
  },
  {
    value: "Wallis and Futuna",
    label: "Wallis and Futuna",
    code: "WF",
  },
  {
    value: "Western Sahara",
    label: "Western Sahara",
    code: "EH",
  },
  {
    value: "Yemen",
    label: "Yemen",
    code: "YE",
  },
  {
    value: "Zambia",
    label: "Zambia",
    code: "ZM",
  },
  {
    value: "Zimbabwe",
    label: "Zimbabwe",
    code: "ZW",
  },
];
