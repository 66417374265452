import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { appSelector } from "../../../store/selectors";
import {
  ANALYTICS_EVENTS,
  OFFERING,
  PAGE_PATHS,
} from "../../../helpers/constants";
import GetDomain from "../index";
import WhyCoSiteDomain from "./WhyCoSiteDomain";
import { TLogEventProps } from "../../../types/global";
import {
  logCustomerEvent,
  logDomainEvent,
} from "../../../telemetry/medusaEventsFunctions";
import { TDomainOwnershipDetails, TDomainPricing } from "../Common/types";
import {
  resetMailMailboxState,
  selectMailDomain,
  selectMailDomainAttrs,
  selectMailDomainPricing,
  setMailDomain,
  setMailDomainPricing,
} from "../../../store/slices/mail.slice";
import useIsLoggedIn from "../../../hooks/useIsLoggedIn";

const {
  CUSTOMER_ACCOUNT_LINKED,
  DOMAIN_AVAILABILITY_CHECKED,
  DOMAIN_SELECTED,
} = ANALYTICS_EVENTS;

const MailGetDomain: React.FC = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { search_params } = useSelector(appSelector);
  const { coSiteDomain } = useSelector(selectMailDomain);
  const { isDomainChargeable } = useSelector(selectMailDomainAttrs);
  const domainPricing = useSelector(selectMailDomainPricing);
  const isLoggedIn = useIsLoggedIn();

  const title = "Let’s find a domain for you";
  const subTitle =
    "It all starts with a domain. You will need a domain in order to set up your email.";

  const onGetDomain = (): void => {
    if (isLoggedIn) {
      navigateTo({
        pathname: PAGE_PATHS.ADD_MAILBOX,
        search: search_params,
      });
      return;
    }
    navigateTo({
      pathname: PAGE_PATHS.SIGN_UP,
      search: search_params,
    });
  };

  const logEvent = ({ eventName, data, flag }: TLogEventProps) => {
    switch (eventName) {
      case CUSTOMER_ACCOUNT_LINKED:
        logDomainEvent(eventName, data, flag);
        break;

      case DOMAIN_AVAILABILITY_CHECKED:
      case DOMAIN_SELECTED:
        logCustomerEvent(eventName, data);
        break;

      default:
        console.log("Invalid event name");
    }
  };

  const updateDomainPricing = ({ domainPricing }: TDomainPricing) => {
    dispatch(setMailDomainPricing({ ...domainPricing }));
  };

  const initializeOffering = () => {
    dispatch(setMailDomain({ offering: OFFERING.COSITE }));
  };

  const onGetFreeDomain = (payload: TDomainOwnershipDetails) => {
    const { coSiteDomain, offering } = payload;
    dispatch(
      setMailDomain({
        coSiteDomain,
        offering,
      })
    );
    dispatch(resetMailMailboxState());
  };

  return (
    <GetDomain
      title={title}
      subTitle={subTitle}
      coSiteDomain={coSiteDomain}
      domainPricing={domainPricing}
      isDomainChargeable={isDomainChargeable}
      continueToNextIfDomainAvailable={location.state?.continueToNext}
      onGetDomain={onGetDomain}
      WhyCoSiteDomain={<WhyCoSiteDomain />}
      logEvent={logEvent}
      initializeOffering={initializeOffering}
      updateDomainPricing={updateDomainPricing}
      onGetFreeDomain={onGetFreeDomain}
      domainPricingFor="email"
    />
  );
};

export default MailGetDomain;
