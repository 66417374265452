import React from "react";
import { useDispatch, useSelector } from "react-redux";
import BillingCard from "../shared/BillingCard";
import {
  sendToFlock,
  sendToFlockApiFailure,
  sendToFlockSiteOfferingPurchase,
} from "../../../helpers/webhookHelper";
import {
  completePurchase,
  initiatePurchase,
  logEntityChange,
} from "../../../services/apiFunctions";
import { userSelector } from "../../../store/selectors";
import { getDomainStateBitSet } from "../../../helpers/bitSetHelper";
import {
  getConfig,
  getUtmParamsFromCookie,
  isMobile,
} from "../../../helpers/utils";
import {
  ANALYTICS_EVENTS,
  BILLING_CYCLE_KEYS,
  ENVIRONMENTS,
  GA_EVENTS,
  NEO_OFFERING_EVENT_VALUE,
  OFFERING,
} from "../../../helpers/constants";
import { TPlan } from "../../../types/plan.type";
import {
  isSiteDomainCustom,
  resetSiteDomainState,
  resetSiteMailboxState,
  resetSitePlan,
  selectSiteAdminMailbox,
  selectSiteDomain,
  selectSiteDomainAttrs,
  selectSiteNoOfAdditionalMailboxes,
  selectSitePlan,
  selectSitePlanDetails,
  selectSitePurchaseDomain,
} from "../../../store/slices/site.slice";
import { getTotalNoOfMailboxesAdded } from "../../../helpers/mailbox.helper";
import { ORDER_TYPES } from "../../../constants/products.constants";
import { selectMailPlanToUpSell } from "../../../store/slices/mail.slice";
import { openSiteAppAfterSuccessfulPayment } from "../../../helpers/site";
import {
  resetVisitorDataAction,
  transferSiteVisitorDataAction,
} from "../../../store/slices/visitor.slice";
import { AppDispatch } from "../../../store/store";
import { TLogEventProps } from "../../../types/global";
import { logSiteBillingPageEvent } from "../../../telemetry/site/medusaEventsFunctions";
import { getCommonSiteProductData } from "../../../telemetry/site/siteMedusaData";
import { selectNeoDomainPaidPlan, selectNeoDomainTrialPlan } from "../../../store/slices/neo-domain-plans";
import { logGoogleAnalyticsEvent } from "../../../helpers/gaEventHelper";
import CancelOrPauseNote from "../../OrderSummary/shared/CancelOrPauseNote";

const { USER_CUSTOM_DOMAIN, CO_DOT_SITE } = NEO_OFFERING_EVENT_VALUE;

interface Props {
  isVisible: boolean;
  onContinue: () => void;
}

// TODO: Optimize this component to avoid re-rendering
export default function SiteBillingCard({ ...props }: Props) {
  const { env } = getConfig();
  const environment =
    ENVIRONMENTS[env as keyof typeof ENVIRONMENTS] || "unknown";
  const {
    token,
    billingInformation: { companyName, taxId },
    customer_id,
    alternateEmail,
  } = useSelector(userSelector);
  const dispatch = useDispatch<AppDispatch>();
  const { offering } = useSelector(selectSiteDomain);
  const { duration } = useSelector(selectSitePlan);
  const plan = useSelector(selectSitePlanDetails) as TPlan;
  const adminMailbox = useSelector(selectSiteAdminMailbox);
  const domain = useSelector(selectSitePurchaseDomain);
  const neoDomainTrialPlan = useSelector(selectNeoDomainTrialPlan);
  const neoDomainPaidPlan = useSelector(selectNeoDomainPaidPlan);
  const noOfAdditionalMailboxes = useSelector(
    selectSiteNoOfAdditionalMailboxes
  );
  const isCustomDomain = useSelector(isSiteDomainCustom);
  const { isDomainChargeable, isCoSiteTrial } = useSelector(
    selectSiteDomainAttrs
  );
  const countOfMailboxAdded = getTotalNoOfMailboxesAdded(
    adminMailbox,
    noOfAdditionalMailboxes
  );
  const mailPlan = useSelector(selectMailPlanToUpSell);
  // const visitorEmail = useSelector(visitorEmailSelector);

  const isTrial = isCoSiteTrial || isCustomDomain;

  const mailOrderItem = countOfMailboxAdded
    ? {
        [ORDER_TYPES.MAIL_SUITE]: {
          planType: mailPlan.type,
          noOfAccounts: countOfMailboxAdded,
        },
      }
    : {};

  const firstEmailAccount =
    adminMailbox?.email && adminMailbox.password
      ? {
          email: adminMailbox.email,
          password: adminMailbox.password,
        }
      : null;

  //   const { updateUser } = bindActionCreators(userActions, dispatch);
  //   const { handleCheckoutRedirection } = useRedirection();
  //   const [countOfMailboxPurchaseFailed, setCountOfMailboxPurchaseFailed] =
  //     useState<number>(0);

  const onApiFailure = ({
    payload,
    response,
    source,
  }: {
    payload: Record<string, any> | null;
    response: any;
    source: string;
  }) => {
    // TODO: fix this
    sendToFlockApiFailure(
      isTrial,
      payload,
      response,
      source
    );
  };

  const handleEntityChangeOnServer = (
    token: string,
    {
      bundleId,
      siteOrderId,
      mailOrderId,
    }: { bundleId: number; siteOrderId: number; mailOrderId?: number }
  ): Promise<Response[]> => {
    const payload1 = {
      type: "site_order",
      id: siteOrderId.toString(),
      attrs: {
        signup_device: isMobile() ? "mobile" : "desktop",
        purchase_source: "Neo website",
        neo_offering:
          offering === OFFERING.COSITE ? CO_DOT_SITE : USER_CUSTOM_DOMAIN,
      },
    };

    const payload3 = {
      type: "bundle",
      id: bundleId.toString(),
      attrs: {
        ...getUtmParamsFromCookie(),
        neo_offering:
          offering === OFFERING.COSITE ? CO_DOT_SITE : USER_CUSTOM_DOMAIN,
        company_name: companyName,
        signup_device: isMobile() ? "mobile" : "desktop",
        product_source: "Site",
      },
    };

    const requests: Promise<Response>[] = [
      logEntityChange(payload1, {}, { "X-CP-Token": token }),
      logEntityChange(payload3, {}, { "X-CP-Token": token }),
    ];

    // Conditionally add the flock_order logEntityChange if mailOrderId is available
    if (mailOrderId) {
      const payload2 = {
        type: "flock_order",
        id: mailOrderId.toString(),
        attrs: {
          neo_offering:
            offering === OFFERING.COSITE ? CO_DOT_SITE : USER_CUSTOM_DOMAIN,
          purchase_source: "Neo website",
          signup_device: isMobile() ? "mobile" : "desktop",
        },
      };

      requests.push(logEntityChange(payload2, {}, { "X-CP-Token": token }));
    }

    return Promise.all(requests);
  };

  const onPaymentSuccess = async ({
    purchaseResponse,
    entriResponse,
    paymentData,
  }: any) => {
    const {
      firstAccountId,
      bundleId,
      orderDetails,
      controlPanelAutoLoginToken,
      webmailAutoLoginToken,
    } = purchaseResponse;
    const siteOrder = orderDetails?.find(
      (item: any) => item.type === ORDER_TYPES.SITE
    );
    const mailOrder = orderDetails?.find(
      (item: any) => item.type === ORDER_TYPES.MAIL_SUITE
    );
    const domainOrder = orderDetails?.find(
      (item: any) => item.type === ORDER_TYPES.NEO_DOMAIN
    );
    let params: Record<string, any> = {
      bid: bundleId,
      domain,
      showSuccess: true,
      soid: siteOrder?.id,
      browser: true,
      // visitorEmail,
    };
    if (mailOrder && firstAccountId) {
      params = {
        ...params,
        wt: webmailAutoLoginToken,
        account_id: firstAccountId,
        isCustomerAccount: false,
        tokenType: "webmail",
        mailOrderId: mailOrder.id,
        product_source: "Site",
      };
    } else {
      params = {
        ...params,
        isCustomerAccount: true,
        customer_id,
        ct: token,
        tokenType: "controlpanel",
        currentAccountEmail: alternateEmail,
        product_source: "Site",
      };
    }
    logGoogleAnalyticsEvent(GA_EVENTS.SITE_ORDER_CREATED);
    if (environment === "production") {
      try {
        // Await the API call to ensure it completes before proceeding
        await sendToFlockSiteOfferingPurchase(bundleId);
      } catch (error) {
        console.error("Error in sendToFlockSiteOfferingPurchase:", error);
      }
    }
    await transferVisitorData(bundleId, siteOrder.id);

    try {
      const responses = await handleEntityChangeOnServer(token, {
        bundleId: bundleId,
        siteOrderId: siteOrder?.id,
        mailOrderId: mailOrder?.id,
      });

      const [res1, res3, res2] = responses;

      if (res1.status !== 200) {
        console.error(
          `There was an error sending the first entity change request (site_order). Error Code: ${res1.status}.`
        );
      }
      if (res3.status !== 200) {
        console.error(
          `There was an error sending the third entity change request (bundle). Error Code: ${res3.status}.`
        );
      }

      // Only handle res2 (flock_order) if it's available
      if (res2 && res2.status !== 200) {
        console.error(
          `There was an error sending the second entity change request (flock_order). Error Code: ${res2.status}.`
        );
      }

      await logSiteBillingPageEvent(ANALYTICS_EVENTS.PAYMENT_COMPLETED, {
        order_id: siteOrder?.id,
        user_type: "siteOrder",
        tax_id: taxId,
        // linkToEntity: true,
        ...getCommonSiteProductData(),
        payment_for: "Site",
      });

      await logSiteBillingPageEvent(ANALYTICS_EVENTS.CUSTOMER_BUNDLE_CREATED, {
        bundle_id: bundleId,
        account_id: firstAccountId,
        order_count: mailOrder ? 2 : 1,
        mail_order_id: mailOrder?.id,
        tax_id: taxId,
        ...(domainOrder ? { neo_domain_order_id: domainOrder.id } : {}),
        ...(siteOrder ? { site_order_id: siteOrder.id } : {}),
        ...getCommonSiteProductData(),
      });
    } catch (error) {
      console.error("Error handling entity changes or logging events:", error);
    } finally {
      resetPurchase();
      openSiteAppAfterSuccessfulPayment("onboard", params);
    }
  };

  const resetPurchase = () => {
    dispatch(resetSiteDomainState());
    dispatch(resetSiteMailboxState());
    dispatch(resetSitePlan());
    dispatch(resetVisitorDataAction());
  };

  const transferVisitorData = async (bid: number, soid: number) => {
    try {
      await dispatch(
        transferSiteVisitorDataAction({
          ve: alternateEmail,
          bid,
          soid,
        })
      );
    } catch (e) {
      console.error("failed to save visitor data", e);
    }
  };

  const onFailure = (reason: string) => {
    // TODO: fix this
    sendToFlock(isTrial, reason);
  };

  const logEvent = ({ eventName, data = {} }: TLogEventProps) => {
    logSiteBillingPageEvent(eventName, {
      ...data,
      ...getCommonSiteProductData(),
    });
  };
  
  return (
    <>
      <BillingCard
        {...props}
        isTrial={isTrial}
        domain={domain}
        onPaymentSuccess={onPaymentSuccess}
        initPaymentAPI={initiatePurchase}
        completePurchaseAPI={completePurchase}
        onApiFailure={onApiFailure}
        onFailure={onFailure}
        canCheckEntriFlow={false}
        logEvent={logEvent}
        completePurchasePayload={{
          token,
          ...(firstEmailAccount && { firstEmailAccount }),
          ...(isMobile() && {
            source: "mobile_order",
          }),
          productSource: "Site",
        }}
        initPaymentPayload={{
          token,
          billingCycle: duration,
          domainToBeCreated: domain,
          orderItems: {
            [ORDER_TYPES.SITE]: {
              planType: plan.type,
            },
            ...(!isCustomDomain && {
              [ORDER_TYPES.NEO_DOMAIN]: { planType: isTrial ? neoDomainTrialPlan.type : neoDomainPaidPlan.type },
            }),
            ...mailOrderItem,
          },
        }}
        loadingSubTitle={"Completing payment and creating site..."}
        cancelOrPauseNote={<CancelOrPauseNote />}
      />
      {/* {!!countOfMailboxPurchaseFailed && (
        <MailboxPurchaseFailed
          countOfMailboxPurchaseFailed={countOfMailboxPurchaseFailed}
          countOfMailboxAdded={countOfMailboxAdded}
        />
      )} */}
      {/* {orderPurchaseSuccess && <OrderSuccessful />} */}
    </>
  );
}
