import { union } from "lodash";
import {
  ANALYTICS_EVENTS,
  MAILBOX_PAGE_SOURCE_HOOK,
} from "../helpers/constants";
import { getOrderIdFromStore } from "./getOrderId";
import { ANALYTICS_ATTRIBUTES, logMedusaEvent } from "./medusaHelper";

const {
  ACCOUNT_ID,
  BILLING_CYCLE,
  BUSINESS_INDUSTRY,
  CURRENT_EMAIL_APP,
  CUSTOMER_EMAIL,
  CUSTOMER_ID,
  DOMAIN,
  DOMAIN_OWNERSHIP,
  DOMAIN_PRICE_MONTHLY,
  DOMAIN_PLAN,
  EMAIL,
  EMPLOYEE_COUNT,
  FIRST_NAME,
  FIRST_ORDER,
  FREE_TRIAL_DAYS,
  GENERIC_MAILBOX_COUNT,
  IMPORT_EMAILS_CONTACTS,
  LAST_NAME,
  NAME_MAILBOX_COUNT,
  NEO_OFFERING,
  MAIL_PLAN,
  ORDER_ID,
  PAYMENT_AMOUNT,
  PROMO_DISCOUNT_AMOUNT,
  PAYMENT_FOR,
  MAIL_PRICE_MONTHLY,
  MAIL_FIRST_BILLING_DISCOUNT,
  ROLE_IN_BUSINESS,
  SIGNUP_REASON,
  SIGNUP_SOURCE,
  SUGGESTED_MAILBOX_COUNT,
  TOTAL_MAILBOX_COUNT,
  USER_TYPE,
  PROMO_CODE,
  PROMO_CODE_ID,
  PROMO_DISCOUNT,
  PROMO_VALIDITY_DAYS,
  PROMO_CODE_ERROR,
  SOURCE_HOOK,
  ACTIVE_EXPERIMENTS,
  EXPERIMENTS,
  CURRENCY,
  BILLING_CYCLE_UPDATED,
  DOMAIN_FIRST_BILLING_DISCOUNT,
  TOTAL_MAIL_PRICE,
  TOTAL_SITE_PRICE,
} = ANALYTICS_ATTRIBUTES;

const { WEBSITE_PAGE_VIEWED, DOMAIN_AVAILABILITY_CHECKED } = ANALYTICS_EVENTS;

// Helper function to log events
export const logEventWithKeys = (eventName, extraKeys, payload) => {
  if (!eventName) {
    throw new Error("Event name must be provided");
  }
  return logMedusaEvent(eventName, extraKeys, payload);
};

export const logPurchaseCompletedEvent = (eventName, payload = {}) => {
  const extraKeys = [
    ACCOUNT_ID,
    BILLING_CYCLE,
    BUSINESS_INDUSTRY,
    CURRENT_EMAIL_APP,
    DOMAIN_PRICE_MONTHLY,
    DOMAIN_PLAN,
    EMAIL,
    EMPLOYEE_COUNT,
    FIRST_ORDER,
    FREE_TRIAL_DAYS,
    GENERIC_MAILBOX_COUNT,
    IMPORT_EMAILS_CONTACTS,
    NAME_MAILBOX_COUNT,
    ORDER_ID,
    MAIL_PRICE_MONTHLY,
    MAIL_FIRST_BILLING_DISCOUNT,
    ROLE_IN_BUSINESS,
    SIGNUP_REASON,
    SUGGESTED_MAILBOX_COUNT,
    TOTAL_MAILBOX_COUNT,
    DOMAIN_FIRST_BILLING_DISCOUNT,
  ];

  return logEventWithKeys(eventName, extraKeys, payload);
};

export const logFeatureCardEvent = (eventName, payload = {}) => {
  const extraKeys = [
    CUSTOMER_ID,
    FIRST_ORDER,
    EMAIL,
    CUSTOMER_EMAIL,
    DOMAIN,
    FIRST_NAME,
    LAST_NAME,
    NEO_OFFERING,
    USER_TYPE,
    MAIL_PLAN,
    BILLING_CYCLE,
    FREE_TRIAL_DAYS,
    SUGGESTED_MAILBOX_COUNT,
    GENERIC_MAILBOX_COUNT,
    TOTAL_MAILBOX_COUNT,
    CURRENCY,
    EXPERIMENTS,
    ACTIVE_EXPERIMENTS,
  ];

  return logEventWithKeys(eventName, extraKeys, payload);
};

export const logPersonaPageEvent = (eventName, payload = {}) => {
  const extraKeys = [
    ACCOUNT_ID,
    BILLING_CYCLE,
    BUSINESS_INDUSTRY,
    CURRENT_EMAIL_APP,
    DOMAIN_PRICE_MONTHLY,
    DOMAIN_PLAN,
    EMAIL,
    EMPLOYEE_COUNT,
    FIRST_ORDER,
    FREE_TRIAL_DAYS,
    GENERIC_MAILBOX_COUNT,
    IMPORT_EMAILS_CONTACTS,
    NAME_MAILBOX_COUNT,
    ORDER_ID,
    MAIL_PRICE_MONTHLY,
    MAIL_FIRST_BILLING_DISCOUNT,
    ROLE_IN_BUSINESS,
    SIGNUP_REASON,
    SIGNUP_SOURCE,
    SUGGESTED_MAILBOX_COUNT,
    TOTAL_MAILBOX_COUNT,
    DOMAIN_FIRST_BILLING_DISCOUNT,
  ];

  return logEventWithKeys(eventName, extraKeys, payload);
};

export const logSitePreviewEvent = (eventName, payload = {}) => {
  const extraKeys = [
    DOMAIN_PRICE_MONTHLY,
    DOMAIN_PLAN,
    EMAIL,
    FIRST_ORDER,
    GENERIC_MAILBOX_COUNT,
    NAME_MAILBOX_COUNT,
    SUGGESTED_MAILBOX_COUNT,
    TOTAL_MAILBOX_COUNT,
  ];

  return logEventWithKeys(eventName, extraKeys, payload);
};

export const logBillingPageEvent = (eventName, payload = {}) => {
  const extraKeys = [
    BILLING_CYCLE,
    DOMAIN_PRICE_MONTHLY,
    DOMAIN_PLAN,
    DOMAIN_FIRST_BILLING_DISCOUNT,
    EMAIL,
    FIRST_ORDER,
    FREE_TRIAL_DAYS,
    GENERIC_MAILBOX_COUNT,
    NAME_MAILBOX_COUNT,
    MAIL_PLAN,
    PAYMENT_AMOUNT,
    PROMO_DISCOUNT_AMOUNT,
    PAYMENT_FOR,
    MAIL_PRICE_MONTHLY,
    MAIL_FIRST_BILLING_DISCOUNT,
    SUGGESTED_MAILBOX_COUNT,
    TOTAL_MAILBOX_COUNT,
    PROMO_CODE,
    PROMO_CODE_ID,
    PROMO_DISCOUNT,
    PROMO_VALIDITY_DAYS,
    PROMO_CODE_ERROR,
    BILLING_CYCLE_UPDATED,
    TOTAL_MAIL_PRICE,
    TOTAL_SITE_PRICE,
  ];

  return logEventWithKeys(eventName, extraKeys, payload);
};

export const logPageViewedEvent = (payload = {}) => {
  return logEventWithKeys(WEBSITE_PAGE_VIEWED, [], payload);
};

export const logDomainEvent = (
  eventName,
  payload = {},
  addDomainPricingAttrs = true
) => {
  const defaultKeys = [DOMAIN_OWNERSHIP, SOURCE_HOOK];
  if (eventName === DOMAIN_AVAILABILITY_CHECKED) {
    defaultKeys.push(
      PROMO_CODE,
      PROMO_CODE_ID,
      PROMO_DISCOUNT,
      PROMO_VALIDITY_DAYS
    );
  }

  const pricingKeys = [DOMAIN_PRICE_MONTHLY, DOMAIN_PLAN];

  const keysToLog = addDomainPricingAttrs
    ? union(defaultKeys, pricingKeys)
    : defaultKeys;

  return logEventWithKeys(eventName, keysToLog, payload);
};

export const logGetStartedViewedEvent = (eventName, payload = {}) => {
  const defaultKeys = [DOMAIN_OWNERSHIP];
  return logEventWithKeys(eventName, defaultKeys, payload);
};

export const logCustomerEvent = (
  eventName,
  payload = {},
  logAllAttributes = false
) => {
  if (!eventName) {
    console.error("No event name passed. Customer event logging failed.");
    return;
  }

  const defaultKeys = [DOMAIN_PRICE_MONTHLY, DOMAIN_PLAN];

  const extraKeys = [CUSTOMER_ID, FIRST_NAME, LAST_NAME];

  const keysToLog = logAllAttributes
    ? union(defaultKeys, extraKeys)
    : defaultKeys;

  return logEventWithKeys(eventName, keysToLog, payload);
};

export const logMailboxEvent = (
  eventName,
  payload = {},
  isPostPlanSelection = false
) => {
  if (!eventName) {
    console.error("No event name passed. Mailbox event not logged");
    return;
  }

  const postPlanKeys = [
    BILLING_CYCLE,
    EMAIL,
    FREE_TRIAL_DAYS,
    MAIL_PLAN,
    PAYMENT_FOR,
    MAIL_PRICE_MONTHLY,
    MAIL_FIRST_BILLING_DISCOUNT,
  ];

  const defaultKeys = [DOMAIN_PRICE_MONTHLY, DOMAIN_PLAN, FIRST_ORDER];

  const keys = isPostPlanSelection
    ? union(defaultKeys, postPlanKeys)
    : defaultKeys;

  return logEventWithKeys(eventName, keys, {
    ...payload,
    ...(payload.source_hook !== MAILBOX_PAGE_SOURCE_HOOK.ADMIN_MAILBOX_PAGE &&
      getOrderIdFromStore()), // pass order_id if Team mailbox page shown post domain purchase and current page is not admin mailbox for mailbox added and mailbox add failed event
  });
};

export const logMailPlanSelectedEvent = (eventName, payload = {}) => {
  const extraKeys = [
    BILLING_CYCLE,
    DOMAIN_PRICE_MONTHLY,
    DOMAIN_PLAN,
    EMAIL,
    FIRST_ORDER,
    FREE_TRIAL_DAYS,
    GENERIC_MAILBOX_COUNT,
    NAME_MAILBOX_COUNT,
    MAIL_PLAN,
    MAIL_PRICE_MONTHLY,
    MAIL_FIRST_BILLING_DISCOUNT,
    SUGGESTED_MAILBOX_COUNT,
    TOTAL_MAILBOX_COUNT,
  ];
  return logEventWithKeys(eventName, extraKeys, payload);
};

export const logPlanPageEvent = (eventName, payload = {}) => {
  const extraKeys = [
    BILLING_CYCLE,
    DOMAIN_PRICE_MONTHLY,
    DOMAIN_PLAN,
    EMAIL,
    FIRST_ORDER,
    FREE_TRIAL_DAYS,
    GENERIC_MAILBOX_COUNT,
    NAME_MAILBOX_COUNT,
    MAIL_PLAN,
    SUGGESTED_MAILBOX_COUNT,
    TOTAL_MAILBOX_COUNT,
  ];
  return logEventWithKeys(eventName, extraKeys, payload);
};

export const logPromoFailedEvent = (eventName, payload = {}) => {
  const extraKeys = [PROMO_CODE, PROMO_CODE_ID, PROMO_CODE_ERROR, SOURCE_HOOK];
  return logEventWithKeys(eventName, extraKeys, payload);
};

export const logSignInButtonClickEvent = (eventName, payload = {}) => {
  return logEventWithKeys(eventName, [], payload);
};
