import { isEmpty } from "lodash";
import { CUSTOM_DOMAIN_TRIAL_DURATION_IN_DAYS } from "../3rdPartyIntegrations/Entri/utils/const";
import {
  CJ_COOKIE_NAME,
  COOKIE_KEYS,
  ENVIRONMENTS,
  LANDINGPAGE_URL,
  MAXIMUM_PASSWORD_LENGTH,
  MINIMUM_PASSWORD_LENGTH,
  NEO_E5_DOMAINS,
  NEO_PARTNER_ID,
  SOURCE_OF_MAILBOX_ADDITION,
  TIMESTAMPS,
  URLS,
  validDomainRegex,
  specialCharsRegex,
  emailRegex,
  gstinRegex,
} from "./constants";
import { countryCodes } from "./countryCodes";
import { getCookie } from "./cookie.helper";

const { DEVICE_ID, HOTJAR_ID, UTM_PARAMS } = COOKIE_KEYS;
const { TEAM_MAILBOX, SUGGESTION_MAILBOX, CUSTOM_MAILBOX } =
  SOURCE_OF_MAILBOX_ADDITION;

export const getUrlParams = () => {
  const params = new URLSearchParams(window.location.search);
  let paramsObj;

  if (isEmpty(Object.fromEntries(params))) {
    paramsObj = {};
  } else {
    paramsObj = Object.fromEntries(params);
  }
  return paramsObj;
};

export const getUserParamsFromURL = () => {
  const {
    clearDomain,
    name,
    auth_token,
    token_expiry,
    alternateEmail,
    hasActiveOrders,
    customer_id,
    source_hook,
    isCustomerAccount,
  } = getUrlParams();
  return {
    clearDomain,
    name,
    auth_token,
    token_expiry,
    alternateEmail,
    hasActiveOrders,
    customer_id,
    source_hook,
    isCustomerAccount,
  };
};

export const getUTMParamsFromURL = () => {
  const { utm_term, utm_medium, utm_campaign, utm_source, utm_content } =
    getUrlParams();

  return {
    utm_term,
    utm_medium,
    utm_campaign,
    utm_source,
    utm_content,
  };
};

export const getAppParamsFromURL = () => {
  const { page_variant } = getUrlParams();
  const search_params = window.location.search;

  return {
    page_variant,
    search_params,
  };
};

export const getTokenFromURL = () => {
  return getUrlParams().token;
};

export const getPromoCodeIdFromURL = () => {
  return getUrlParams().promo_code_id;
};

export const getOS = () => {
  // the order matters here... we have to check for mobile before we check for
  // desktop OS else mobile devices will return as Linux and MacOS for Android and iOS respectively
  if (navigator.appVersion.indexOf("iPhone") !== -1) {
    return "iOS";
  }
  if (navigator.appVersion.indexOf("Android") !== -1) {
    return "Android";
  }
  if (navigator.appVersion.indexOf("Win") !== -1) {
    return "Windows";
  }
  if (navigator.appVersion.indexOf("Mac") !== -1) {
    return "MacOS";
  }
  if (navigator.appVersion.indexOf("X11") !== -1) {
    return "UNIX";
  }
  if (navigator.appVersion.indexOf("Linux") !== -1) {
    return "Linux";
  }

  return "unknown";
};

export const getBrowser = () => {
  if (navigator.userAgent.indexOf("Chrome") !== -1) {
    return "chrome";
  } else if (navigator.userAgent.indexOf("MSIE") !== -1) {
    return "IE";
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return "firefox";
  } else {
    return "safari";
  }
};

export const isMobile = () => {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
};

export const getConfig = () => {
  const hostname = window.location.hostname;

  if (
    hostname.indexOf("localhost") > -1 ||
    hostname.indexOf("staging") > -1 ||
    hostname.indexOf("qa") > -1
  ) {
    return { env: "STAGING" };
  } else if (hostname.indexOf("preprod") > -1) {
    return { env: "PREPROD" };
  } else {
    return { env: "PROD" };
  }
};

export const getMailClientUrl = (neoEmail, webmailAutoLoginToken) => {
  const { MAIL_CLIENT_BASE_URL } = URLS;
  const { env } = getConfig();

  if (!neoEmail && !webmailAutoLoginToken) return MAIL_CLIENT_BASE_URL[env];

  return `${MAIL_CLIENT_BASE_URL[env]}/?slt=${webmailAutoLoginToken}&email_account=${neoEmail}`;
};

export const getWebmailUrl = () => {
  const { WEBMAIL_URL } = URLS;
  const { env } = getConfig();

  return `${WEBMAIL_URL[env]}`;
};

export const getAdminPanelUrl = () => {
  const { ADMIN_PANEL_BASE_URL } = URLS;
  const { env } = getConfig();

  return `${ADMIN_PANEL_BASE_URL[env]}`;
};

export const getNeoSiteUrl = () => {
  const { NEO_SITE_URL } = URLS;
  const { env } = getConfig();

  return `${NEO_SITE_URL[env]}`;
};

export const getCountryDetailsFromCountryCode = (countryCode) => {
  const countryObj = countryCodes.find(
    (country) => country.value === countryCode || country.code === countryCode
  );

  return {
    sanitizedCountryName:
      countryObj?.label.split("(")[0].trim() || "Not Available",
    countryName: countryObj?.label || "Not available",
    countryAlphaCode: countryObj?.code || "Not available",
  };
};

export const getSpiltFirstAndLastName = (fullName) => {
  if (!fullName) return false;

  let firstName = "",
    lastName = "";
  const hasSpace = fullName.indexOf(" ") > -1;

  if (hasSpace) {
    firstName = fullName.substring(0, fullName.indexOf(" "));
    lastName = fullName.substring(fullName.indexOf(" ") + 1);
  } else {
    firstName = fullName;
  }
  return { firstName, lastName };
};

export const getAppVersion = () => {
  const { NODE_ENV, REACT_APP_VERSION } = process.env;
  let appVersion;

  switch (NODE_ENV) {
    case "development":
      appVersion = "Dev Mode";
      break;

    case "production":
      appVersion = REACT_APP_VERSION && `v 2.0.0 build ${REACT_APP_VERSION}`;
      break;

    default:
      appVersion = `${NODE_ENV} Build #${REACT_APP_VERSION}`;
  }

  return appVersion;
};

export const getTrialEndDate = (trialDurationInDays) => {
  /**
   * @function getTrialEndDate
   *
   * @desc Calculates the end date of the trial based on the number of trial days provided.
   * Will default to 15 days if 'trialDurationInDays' is not provided
   *
   * @param {Number} trialDurationInDays: Number of days of trial that will be provided
   *
   * @return {EpochTimeStamp}: Returns the datetime in epoch millis
   *
   **/

  const numberOfDays =
    trialDurationInDays || CUSTOM_DOMAIN_TRIAL_DURATION_IN_DAYS;

  const todayDate = new Date();

  return todayDate.setDate(todayDate.getDate() + numberOfDays);
};

export const getFormattedTrialEndDate = (trialDurationInDays) => {
  const trialEndDate = new Date(getTrialEndDate(trialDurationInDays));

  const options = { year: "numeric", month: "short", day: "numeric" };
  return trialEndDate.toLocaleDateString("en-US", options);
};

export const getThreeHoursHenceTime = () => {
  return Date.now() + TIMESTAMPS.THREE_HOURS;
};

export const doesErrorExist = (errObj) => {
  return Object.keys(errObj).some((key) => errObj[key]);
};

export const getQueryParamsFromObject = (paramsObj = {}) => {
  return new URLSearchParams(paramsObj).toString();
};

export const redirectToNeoAdminPanel = (token, customerEmail, params) => {
  const { ADMIN_PANEL_BASE_URL } = URLS;
  const { env } = getConfig();
  const queryString = getQueryParamsFromObject(params);
  let path = "/";

  if (token) {
    path = `/partner/autoLogin?partnerId=${NEO_PARTNER_ID[env]}&jwt=${token}&isFromNeo=true`;

    // Append customerEmail to the URL if it is provided
    if (customerEmail) {
      path += `&customerEmail=${encodeURIComponent(customerEmail)}`;
    }

    // Append query parameters to the URL if any are provided
    if (queryString) {
      path += `&${queryString}`;
    }
  }

  return `${ADMIN_PANEL_BASE_URL[env]}${path}`;
};

// export const getPlanId = (planName, isCustomDomain) => {
//   // replace space with underscore in plan name to match the key
//   const updatePlanName = planName.toUpperCase().replace(/ /g, "_");
//
//   const { env } = getConfig();
//
//   if (isCustomDomain) {
//     return ALL_PLANS[`${updatePlanName}_TRIAL`].ID[env];
//   } else {
//     return ALL_PLANS[`${updatePlanName}`].ID[env];
//   }
// };

export const isEmailValid = (email) => {
  return emailRegex.test(email);
};

export const isValidGstin = (input) => {
  return gstinRegex.test(input);
}

export const isCountryIndia = (input) => input?.toLowerCase() === "india"

export const getResetPasswordURL = () => {
  const { env } = getConfig();
  const { ADMIN_PANEL_BASE_URL } = URLS;
  return `${ADMIN_PANEL_BASE_URL[env]}/reset-password`;
};

export const isInputLeadingSpace = (event) => {
  if (/^\s/.test(event.target.value)) {
    event.target.value = "";
    return true;
  }
};

export const getSignUpAppUrl = (path, queryParams = "") => {
  if (isDevBuild()) {
    const branchName = window.location.pathname.split("/")[1];
    return `${window.location.origin}/${branchName}/${queryParams}`;
  } else {
    return `${window.location.origin}${path}${queryParams}`;
  }
};

export const getCoSiteDomainWithTld = (domainName, coSiteTld) => {
  return domainName?.split(".")[0] + `.${coSiteTld}`;
};

export const getCoSiteTld = () => {
  const { env } = getConfig();
  const environment = env.toLowerCase();
  let domainName;

  switch (environment) {
    case ENVIRONMENTS.STAGING:
      domainName = NEO_E5_DOMAINS.STAGING;
      break;

    case ENVIRONMENTS.PREPROD:
      domainName = NEO_E5_DOMAINS.PREPROD;
      break;

    default:
      domainName = NEO_E5_DOMAINS.PROD;
  }

  return domainName;
};

export const generateNewDeviceId = () => {
  if (typeof crypto.randomUUID === "function") {
    return crypto.randomUUID();
  } else {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }
};

export const getCopyToClipboard = (text) => {
  return navigator.clipboard.writeText(text);
};

export const isDomainValid = (value) => {
  return validDomainRegex.test(value);
};

export const cleanUrl = (keysToDeleteFromParams = []) => {
  const params = new URLSearchParams(window.location.search);
  const { origin, pathname } = window.location;
  keysToDeleteFromParams.forEach((item) => {
    params.delete(item);
  });
  const queryParams = params.toString() ? `?${params.toString()}` : "";

  window.history.replaceState("", "", `${origin}${pathname}${queryParams}`);

  return queryParams;
};

/**
 * Filter all falsy values ( "", 0, false, null, undefined ) from given object
 */
export const filterObject = (object) => {
  return Object.entries(object).reduce(
    (accumulator, [key, value]) =>
      value ? ((accumulator[key] = value), accumulator) : accumulator,
    {}
  );
};

export const domainNameLength = (domainName) => {
  return domainName?.split(".")[0].length;
};

export const getDeviceIdFromCookie = () => {
  return getCookie(DEVICE_ID);
};

export const getHotjarIdFromCookie = () => {
  return getCookie(HOTJAR_ID);
};

export const getUtmParamsFromCookie = () => {
  return JSON.parse(getCookie(UTM_PARAMS));
};

// remove https://, http:// and www. from a string
export const removeProtocolFromDomain = (domainName) => {
  return domainName.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0];
};

/**
 * Minimum 8 and maximum 100 digit (Length)
 * At least one special character ( Refer special characters list at https://titanwiki.atlassian.net/wiki/spaces/TP/pages/418218045/Strong+Password+Validation )
 * Alphanumeric
 * spaces not allowed
 */
export const isPasswordValid = (password) => {
  if (!password) return null;

  const alphanumericRegex = new RegExp(/(?=.*[A-Za-z])(?=.*\d)/);
  const spaceRegex = new RegExp("\\s");

  return (
    alphanumericRegex.test(password) &&
    specialCharsRegex.test(password) &&
    !spaceRegex.test(password) &&
    password.length >= MINIMUM_PASSWORD_LENGTH &&
    password.length <= MAXIMUM_PASSWORD_LENGTH
  );
};

export const isDevBuild = () => {
  const hostname = window.location.hostname;
  return hostname.indexOf("qa") > -1;
};

/**
 * teamMailboxCount - defines mailbox count added from add-team-mailboxes page
 * suggestionMailboxCount - defines suggestion mailbox count added from add-generic-mailboxes page
 * customMailboxCount - defines custom mailbox count added from input box of add-generic-mailboxes page
 */
export const countOfEachTypeMailbox = (mailboxOrderList) => {
  let teamMailboxCount = 0,
    suggestionMailboxCount = 0,
    customMailboxCount = 0;
  mailboxOrderList?.forEach((item) => {
    if (item.source === TEAM_MAILBOX) {
      teamMailboxCount++;
    }
    if (item.source === SUGGESTION_MAILBOX) {
      suggestionMailboxCount++;
    }
    if (item.source === CUSTOM_MAILBOX) {
      customMailboxCount++;
    }
  });

  return { teamMailboxCount, suggestionMailboxCount, customMailboxCount };
};

export const getCJCookieValue = () => {
  return getCookie(CJ_COOKIE_NAME) || "";
};

export const getNeoHomeUrl = (params = {}, includeQueryParams = true) => {
  const { env } = getConfig();
  /**
   * Filter all falsy values ( "", 0, false, null, undefined ) from params
   */
  const finalObj = filterObject(params);
  const queryParams = getQueryParamsFromObject(finalObj);
  const finalParams = window.location.search
    ? `${window.location.search}&${queryParams}`
    : `?${queryParams}`;

  return `${LANDINGPAGE_URL[env]}${
    includeQueryParams ? finalParams || "" : ""
  }`;
};

export const redirectToNeoHome = (params, includeQueryParams) => {
  window.location.href = getNeoHomeUrl(params, includeQueryParams);
};

export const getRoundedDecimal = (
  value,
  decimalSpaces = 2,
  returnAs = "number"
) => {
  const fixedValue = Number(value).toFixed(decimalSpaces);
  return returnAs === "number" ? Number(fixedValue) : fixedValue;
};

export const convertStringToBoolean = (str) => {
  return str.toLowerCase() === "true";
};

export const hasSpecialChars = (str) => {
  return specialCharsRegex.test(str);
};

/**
 * function to shuffle list elements
 * @param {Array<any>} list
 * @returns shuffled list
 */
export const shuffleList = (list) => {
  for (let i = list.length - 1; i > 0; i--) {
    // Generate a random index from 0 to i
    const j = Math.floor(Math.random() * (i + 1));

    // Swap list[i] with the element at the random index
    [list[i], list[j]] = [list[j], list[i]];
  }

  return list;
};

export const isValidAlphanumericWithSpecialChars = (input) => {
  const alphanumericAndSpecialCharRegex = /^[\p{L}\p{N}\s\p{P}\p{S}]*$/u;
  return alphanumericAndSpecialCharRegex.test(input);
};

export const getComputedValue = (value, multiplier = 1) => {
  if (!value) {
    return null;
  }
  return getRoundedDecimal(value * multiplier);
};
