import { TBusinessProfile } from "../../types/site-offering.type";
import { getOrigin, getQueryParamsFromUrl, openUrlInCurrentTab } from "../../utils/urlUtils";
import { getSiteBaseUrl } from ".";

const timeout = 10 * 1000;

export const generateSiteOptionAvailable = (
  businessPath: string,
  alternateEmail: string,
  emailPath: string
): boolean => {
  return !!((businessPath && alternateEmail) || emailPath);
};

const createIframe = (src: string) => {
  const iframe = document.createElement("iframe");
  iframe.src = src;
  iframe.style.display = "none";
  return iframe;
};

export const openSiteInIframeAndPassData = async (
  businessProfile: TBusinessProfile,
  email?: string
) => {
  return new Promise((resolve, reject) => {
    const init = () => {
      const iframe = createIframe(`${getSiteBaseUrl()}/share-data.html`);
      if (!iframe) {
        _reject("Failed to create iframe");
      }

      window.addEventListener("message", confirmationListener);
      const timerId = setTimeout(() => {
        _reject("Failed to send data in given time");
      }, timeout);

      iframe.onload = () => {
        const payload = {
          type: "localStorage",
          payload: {
            visitorData: {
              ...(email && { email }),
              businessProfile,
            },
          },
        };
        iframe.contentWindow?.postMessage(payload, getOrigin(getSiteBaseUrl()));
      };

      document.body.appendChild(iframe);

      return () => {
        window.removeEventListener("message", confirmationListener);
        if (timerId) {
          clearTimeout(timerId);
        }
        if (iframe) document.body.removeChild(iframe);
      };
    };

    const cleanup = init();

    const _resolve = () => {
      resolve(true);
      cleanup?.();
    };

    const _reject = (error: any) => {
      console.error('failed to send bn bd via iframe because site rejected it', error);
      reject(error);
      cleanup?.();
    };

    function confirmationListener(event: MessageEvent) {
      if (event.origin !== getOrigin(getSiteBaseUrl())) {
        return;
      }

      const response = event.data;
      if (response.type === "error") {
        _reject(response);
        return;
      }
      if (response.type === "success") {
        _resolve();
      }
    }
  });
};

export const startSiteGeneration = async (
  businessProfile: TBusinessProfile,
  userInfo: { email?: string; customerId?: string; userType: string }
) => {
  try {
    await openSiteInIframeAndPassData(businessProfile, userInfo.email);
    // If failed to send data via iframe, open the site with passed data in query params
  } catch (error) {
    console.error('failed to send bn bd via iframe',error);
  }

  // Extract current query parameters using from the URL
  const currentQueryParams = getQueryParamsFromUrl(window.location.href);
  
  openUrlInCurrentTab(`${getSiteBaseUrl()}/noAuth/onboarding`, {
    ...currentQueryParams,
    ...(userInfo.email && { email: userInfo.email }),
    ...(userInfo.customerId && { customerId: userInfo.customerId }),
    userType: userInfo.userType,
    source: "signup",
    browser: true,
    bn: businessProfile.name,
    bd: businessProfile.description,
  });
};
