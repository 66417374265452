import { QueryParam } from "../types/global";
import { store } from "../store/store";
import { appActions } from "../store/actions";
import {
  addQueryParamsInUrl,
} from "../utils/urlUtils";

export const addQueryParamsInCurrentUrl = (
  params: Record<string, QueryParam>
) => {
  const updatedUrl = addQueryParamsInUrl(window.location.href, params);
  window.history.replaceState("", "", updatedUrl);
  store.dispatch(
    appActions.updateAppParams({ search_params: new URL(updatedUrl).search })
  );
};
