import { SITE_PLAN_FEATURE_MAP } from "../../constants/site.constant";
import {
  TFeature,
  TFeatureTextMap,
  TPlan,
  TPlanFromAPIResponse,
} from "../../types/plan.type";
import {
  addDiscountPercentageToPlans,
  addPricingOfPaidPlanIntoTrialPlan,
  // filterOutFreePlans,
  formatPlanDisplayName,
  getPlanFeaturesComparison,
} from "../plans.helper";
import { toGB } from "../../utils/size.utils";
import { TRIAL_DURATIONS_IN_DAYS } from "../constants";

export const formatAndSortSitePlans = (
  plans: TPlanFromAPIResponse
): TPlan[] => {
  const formattedAndSortedPlans = plans
    .map((plan: TPlanFromAPIResponse) => ({
      ...plan,
      name: formatPlanDisplayName(plan.displayName),
      pricing: addDiscountPercentageToPlans(plan.planPricing),
    }))
    .sort(
      (a: TPlanFromAPIResponse, b: TPlanFromAPIResponse) => a.zIndex - b.zIndex
    );

  return (
    addPricingOfPaidPlanIntoTrialPlan(formattedAndSortedPlans)
  );
};

const _sanitizeNeoFeatures = (
  planList: TPlan[],
  isCustomDomain: boolean
): TPlan[] => {
  return planList.map((plan) => {
    const features = plan.features.filter((feature) => {
      return isCustomDomain
        ? feature.type !== SITE_PLAN_FEATURE_MAP.NEO_DOMAIN_PAID
        : feature.type !== SITE_PLAN_FEATURE_MAP.CUSTOM_DOMAIN;
    });
    return {
      ...plan,
      features,
    };
  });
};

const _getFeatureValueForPlan = (feature: TFeature) => {
  const { attrs, type } = feature;
  switch (type) {
    case SITE_PLAN_FEATURE_MAP.STORAGE:
      return attrs.quota_in_mb
        ? { val: `${toGB(attrs.quota_in_mb as unknown as number)} GB` }
        : null;
    case SITE_PLAN_FEATURE_MAP.AI_SITE:
    case SITE_PLAN_FEATURE_MAP.AI_TEXT:
      return attrs.monthlyLimit
        ? { val: `${attrs.monthlyLimit} times` }
        : { val: "Unlimited - Latest AI Model" };
    case SITE_PLAN_FEATURE_MAP.TEMPLATES:
      return { val: "All" };
    case SITE_PLAN_FEATURE_MAP.FONTS:
      return attrs.premium
        ? { val: "Premium Fonts" }
        : { val: "Standard Fonts" };
    case SITE_PLAN_FEATURE_MAP.COLORS:
      return attrs.curated ? { val: "Curated for Template" } : { val: "Yes" };
    case SITE_PLAN_FEATURE_MAP.STOCK_IMAGES:
      return attrs.limit ? { val: `${attrs.limit}` } : { val: "Unlimited" };
    case SITE_PLAN_FEATURE_MAP.GALLERY:
      return attrs.limit
        ? { val: `${attrs.limit} images` }
        : { val: "Unlimited" };
    case SITE_PLAN_FEATURE_MAP.PRODUCTS:
      return attrs.limit
        ? { val: `${attrs.limit} products` }
        : { val: "Unlimited" };
    case SITE_PLAN_FEATURE_MAP.SERVICES:
      return attrs.limit
        ? { val: `${attrs.limit} services` }
        : { val: "Unlimited" };
    // case SITE_PLAN_FEATURE_MAP.VIDEO:
    //   return { val: "Yes" };
    case SITE_PLAN_FEATURE_MAP.TESTIMONIALS:
      return attrs.limit ? { val: `${attrs.limit}` } : { val: "Unlimited" };
    // case SITE_PLAN_FEATURE_MAP.ANALYTICS:
    //   return { val: "Yes" };
    case SITE_PLAN_FEATURE_MAP.CONTACT_FORM:
      return attrs.limit ? { val: `${attrs.limit}` } : { val: "Unlimited" };
    case SITE_PLAN_FEATURE_MAP.CUSTOM_DOMAIN:
      return attrs ? { val: `${attrs.trialInDays || TRIAL_DURATIONS_IN_DAYS.CUSTOM_DOMAIN} days free` } : null;
    case SITE_PLAN_FEATURE_MAP.SUPPORT:
      return attrs.priority
        ? { val: `Priority Chat support 24x7` }
        : { val: "Support 24x7" };

    default:
      return null;
  }
};

const getFeatTitle = (
  feature: TFeature,
  featuresTextMap: TFeatureTextMap,
  { domain }: { domain?: string } = {}
) => {
  if (domain && feature.type === SITE_PLAN_FEATURE_MAP.NEO_DOMAIN_PAID) {
    return `Included domain <strong>${domain} </strong>`;
  }
  return featuresTextMap?.[feature.type]?.text;
};

export const getSitePlanFeaturesComparison = (
  plans: TPlan[],
  {
    isCustomDomain,
    domain,
    featuresTextMap,
    getFeatureValueForPlan,
  }: {
    isCustomDomain: boolean;
    domain: string;
    featuresTextMap: TFeatureTextMap;
    getFeatureValueForPlan: Parameters<
      typeof getPlanFeaturesComparison
    >[1]["getFeatureValueForPlan"];
  }
) => {
  const planList = _sanitizeNeoFeatures(plans, isCustomDomain);

  return getPlanFeaturesComparison(planList, {
    getFeatTitle: (feature) =>
      getFeatTitle(feature, featuresTextMap, {
        domain,
      }),
    getFeatureValueForPlan: (feature, plan) => {
      return (
        getFeatureValueForPlan(feature, plan) ||
        _getFeatureValueForPlan(feature)
      );
    },
  });
};
